import produce from 'immer';
import { authService } from '../../services';
import { toast } from 'react-toastify';
import { api, authentication, invoices, notifications, products } from '../../api/api';
import { LOGIN_PAGE_FORMS } from '../../utils/constants';

export const createAuthSlice = (set, get) => ({
  auth: {
    currentLoginForm: LOGIN_PAGE_FORMS.LOGIN,
    signUpData: {},

    isLoading: false,

    isEmailVerified: false,
    isPhoneVerified: false,

    showAccountReactivationModal: false,
    toggleShowAccountReactivationModal: () => {
      set(
        produce((state) => {
          state.auth.showAccountReactivationModal = !state.auth.showAccountReactivationModal;
        })
      );
    },
    recoveryAccount: (email) => {
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .recoveryAccount(email)
        .then(() => {
          toast.info('Um email foi enviado para recuperar sua conta');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },
    confirmRecoveryCode: (data, navigate) => {
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .confirmRecoveryCode(data)
        .then(() => {
          toast.success('Conta recuperada com sucesso');
          navigate('/');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },

    setCurrentLoginForm: (loginForm) => {
      set(
        produce((state) => {
          state.auth.currentLoginForm = loginForm;
        })
      );
    },
    confirmOtp: (code, navigate) => {
      const isEmailVerified = get().auth.isEmailVerified;
      const data = isEmailVerified
        ? { phone: get().auth.signUpData.phone, code }
        : { email: get().auth.signUpData.email, code };
      const signUp = get().auth.signUp;
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .confirmOtp(data, isEmailVerified)
        .then((data) => {
          toast.info(data);
          set(
            produce((state) => {
              if (isEmailVerified) {
                state.auth.isPhoneVerified = true;
              } else {
                state.auth.isEmailVerified = true;
              }
            })
          );
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
          if (isEmailVerified && get().auth.isPhoneVerified) {
            signUp(navigate);
            set(
              produce((state) => {
                state.auth.isEmailVerified = false;
                state.auth.isPhoneVerified = false;
              })
            );
          }
          if (get().auth.isEmailVerified && !get().auth.isPhoneVerified) get().auth.getOtp();
        });
    },
    getOtp: async (navigate) => {
      const isEmailVerified = get().auth.isEmailVerified;
      const data = isEmailVerified
        ? { phone: get().auth.signUpData.phone }
        : { email: get().auth.signUpData.email };
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .getOtp(data, isEmailVerified)
        .then((data) => {
          toast.info(data.message);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
          if (window.location.href.includes('confirmacao_email')) {
            return;
          } else {
            navigate('/confirmacao_email');
          }
        });
    },
    updateSignUpData: (payload) => {
      set(
        produce((state) => {
          state.auth.signUpData = { ...state.auth.signUpData, ...payload };
        })
      );
    },
    updateSignUpDataWithToast: (payload) => {
      get().auth.updateSignUpData(payload);
      toast.success('Dados alterados com sucesso');
    },
    forgotPassword: (email) => {
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .forgotPassword(email)
        .then(() => {
          set(
            produce((state) => {
              state.auth.currentLoginForm = LOGIN_PAGE_FORMS.LOGIN;
            })
          );
          toast.info('Te enviamos um email com instruções para gerar uma nova senha');
        })
        .catch((error) => {
          console.error(error);
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },
    resetPassword: ({ email, resetToken, newPassword }, navigate) => {
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );
      authService
        .resetPassword({ email, resetToken, newPassword })
        .then(() => {
          navigate('/');
          toast.success('Senha alterada com sucesso');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },
    signUp: async (navigate) => {
      const signUpData = get().auth.signUpData;

      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );

      authService
        .signUp(signUpData)
        .then(() => {
          navigate('/');
          toast.success('Cadastrado com sucesso');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },
    signIn: async (signInData, navigate) => {
      set(
        produce((state) => {
          state.auth.isLoading = true;
        })
      );

      authService
        .signIn(signInData)
        .then((token) => {
          localStorage.setItem('token', token);
          api.defaults.headers.Authorization = `Bearer ${token}`;
          authentication.defaults.headers.Authorization = `Bearer ${token}`;
          invoices.defaults.headers.Authorization = `Bearer ${token}`;
          notifications.defaults.headers.Authorization = `Bearer ${token}`;
          products.defaults.headers.Authorization = `Bearer ${token}`;
          navigate('/dashboard');
        })
        .catch((error) => {
          if (error.message === 'E-016') {
            get().auth.toggleShowAccountReactivationModal();
          } else {
            console.error(error.message);
          }
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isLoading = false;
            })
          );
        });
    },
    deleteAccount: (reasons, navigate) => {
      set(
        produce((state) => {
          state.auth.isDeletingAccount = true;
        })
      );
      authService
        .deleteAccount(reasons)
        .then((data) => {
          toast.success(data.message);
          localStorage.removeItem('token');
          api.defaults.headers.Authorization = null;
          navigate('/');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.auth.isDeletingAccount = false;
            })
          );
        });
    }
  }
});
