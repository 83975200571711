import React, { useEffect } from 'react';
import Modal from '../FormComponents/Modal/Modal';
import FilledButton from '../FormComponents/Buttons/FilledButton';
import { Colors } from '../../utils/colors';
import { useStore } from '../../store/useStore';
import { toast } from 'react-toastify';

const VerifyAccount = () => {
  const company = useStore((state) => state.company.company);
  const toggleShowVerifyAccountModal = useStore(
    (state) => state.company.toggleShowVerifyAccountModal
  );
  const getCompanyResume = useStore((state) => state.company.getCompanyResume);
  const [verification, setVerification] = React.useState({
    loading: false,
    toggle: false,
    verificationCode: ''
  });

  const confirmOtp = () => {
    setVerification({ ...verification, loading: true });
    fetch('https://app.piminder.com/notification/v1/api/notification/mail/otp/confirm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: company.email,
        code: verification.verificationCode
      })
    })
      .then((response) => {
        setVerification({ ...verification, loading: false });
        if (response.status === 204) {
          getCompanyResume();
          toast.success('Conta verificada com sucesso!');
          toggleShowVerifyAccountModal();
        } else {
          toast.error('Conta não verificada!');
          console.error(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    setVerification({ ...verification, loading: true });
    fetch('https://app.piminder.com/notification/v1/api/notification/mail/otp/get', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: company.email
      })
    })
      .then(() => {
        setVerification({ ...verification, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [verification.toggle]);
  return (
    <Modal
      title="Verifique sua conta"
      onClose={toggleShowVerifyAccountModal}
      show
      noDefaultHeight
      noDefaultWidth>
      <div className="overflow-hidden min-h-[260px]">
        {verification.loading ? (
          <div className="flex justify-center items-center h-[260px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="animate-spin size-10 stroke-orange">
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          </div>
        ) : (
          <div className="overflow-y-auto p-5">
            <p className="text-base">
              Enviamos um código para o seu endereço de e-mail. Verifique sua caixa de entrada e
              preencha o formulário de verificação.
            </p>
            <div className="mt-4">
              <input
                type="text"
                className="w-full p-4 bg-gray-2 rounded outline-none"
                placeholder="Código de verificação"
                value={verification.verificationCode}
                onChange={(e) =>
                  setVerification({ ...verification, verificationCode: e.target.value })
                }
              />
              <p className="mt-3">
                Não recebeu nenhum email?{' '}
                <button
                  onClick={() => setVerification({ ...verification, toggle: !verification.toggle })}
                  className="underline">
                  Reenviar e-mail
                </button>
              </p>
              <FilledButton className="px-10 mt-4" bg={Colors.Green} onClick={confirmOtp}>
                Verificar conta
              </FilledButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default VerifyAccount;
