import React, { useEffect, useRef, useState } from 'react';
import { ChevronDownIcon } from '../../../assets/imgs';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';

export default function ButtonSelect({
  options,
  label,
  onChange,
  component = null,
  menuClassName
}) {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const btnMenuRef = useRef(null);

  const choose = (option) => {
    onChange(option.value);
    setShowMenu(false);
  };

  useEffect(() => {
    if (!btnMenuRef?.current) return;

    const clickOffHandler = (e) => {
      if (!btnMenuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('click', clickOffHandler);

    return () => {
      document.removeEventListener('click', clickOffHandler);
    };
  }, [btnMenuRef]);

  const CustomComponent = component;

  return (
    <div className="btn-menu relative z-50" ref={btnMenuRef}>
      <div
        onClick={() => {
          setShowMenu(!showMenu);
        }}>
        {component ? (
          <CustomComponent />
        ) : (
          <FilledButton type="button" className="text-base px-3">
            <div className="flex gap-2 items-center">
              <div>{label}</div>
              <ChevronDownIcon />
            </div>
          </FilledButton>
        )}
      </div>
      {showMenu ? (
        <div
          ref={menuRef}
          id="menu"
          style={{ boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)' }}
          className={
            'absolute z-30 rounded-[4px] my-[8px] bg-white text-sm font-normal w-fit ' +
            menuClassName
          }>
          {options.map((option, index, array) => {
            return (
              <div
                onClick={() => choose(option)}
                key={option.value}
                className={`flex gap-2 items-center btn-menu-item px-[12px] py-[8px] bg-white hover:bg-[#f8f9fa] cursor-default ${
                  index == 0 ? 'rounded-t-[4px]' : ''
                } ${array.length - 1 === index ? 'rounded-b-[4px]' : ''}`}>
                {option.icon ? <div>{option.icon}</div> : null}
                <div>{option.label}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
