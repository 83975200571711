import React from 'react';
import { useNavigate } from 'react-router-dom';
import StepModal from '../../components/FormComponents/Modal/StepModal';
import useStepState from '../../hooks/useStepState';
import Login from '../Login/Login';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';

export default function Register() {
  const [step, previousStep, nextStep] = useStepState(1);

  const navigate = useNavigate();

  const cancel = () => navigate('/');

  return (
    <div>
      <Login />
      <StepModal
        title="Cadastre-se"
        subTitle="É rapido e fácil"
        show={true}
        steps={['Dados da conta', 'Dados da empresa', 'Recebimento']}
        currentStepNumber={step}
        onClose={cancel}
        submitTitle="Cadastrar">
        <Step1 show={step === 1} onNext={nextStep} onPrevious={previousStep} />
        <Step2 show={step === 2} onNext={nextStep} onPrevious={previousStep} />
        <Step3 show={step === 3} onNext={nextStep} onPrevious={previousStep} />
      </StepModal>
    </div>
  );
}
