import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({ setBilling, billing }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setBilling((prev) => ({
      ...prev,
      file: acceptedFiles[0]
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '*/*'
  });

  return (
    <div
      {...getRootProps()}
      className="border-2 border-dashed border-gray-400 rounded text-center cursor-pointer text-gray-400 p-5">
      <input {...getInputProps()} />
      {billing.file ? (
        <p>{billing.file.name}</p>
      ) : isDragActive ? (
        <p>Solte o comprovativo aqui ...</p>
      ) : (
        <p>Arraste e solte o comprovativo, ou clique para selecionar</p>
      )}
    </div>
  );
};

export default ImageUploader;
