export const Colors = {
  Gray1: '#F7F7F7',
  Gray2: '#EBEBEB',
  Gray3: '#C7C7C7',
  Gray4: '#9E9E9E',
  Gray5: '#5E5E5E',
  Gray6: '#3B3B3B',
  black: '#111111',
  Orange: '#EB6C4C',
  White: '#fff',
  Red: '#FF3D3C',
  Green: '#17E383',
  Blue: '#33A7E8',
  Yellow: '#F6D04A'
};
