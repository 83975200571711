import React from 'react';

export default function ChevronLeftIcon({ width = 12, height = 8, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ transform: 'rotate(270deg)', transformOrigin: '50% 50%' }}
        d="M11 7L6.76822 1.92186C6.36843 1.44211 5.63157 1.44211 5.23178 1.92187L1 7"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
