import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useStore } from '../../../store/useStore';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { formatCurrency } from '../../../utils/currencyHelper';
import { BILLING_STATUSES_LABELS, BILLING_STATUS_COLOR } from '../../../utils/constants';
import { StatusIcon } from '../../../assets/imgs';
import InfoLabeled from '../../../components/InfoLabeled/InfoLabeled';
import moment from 'moment';
import SimpleLink from '../../../components/SimpleLink/SimpleLink';

export default function InvoiceDataSection() {
  const params = useParams();
  const getInvoice = useStore((state) => state.billings.getInvoice);
  const invoice = useStore((state) => state.billings.invoice);
  const isInvoiceLoading = useStore((state) => state.billings.isInvoiceLoading);
  const billings = useStore((state) => state.billings.billings);

  useEffect(() => {
    getInvoice(params.id);
  }, [billings]);

  return (
    <DetailSection title="Dados da cobrança">
      <div>
        <div className="pt-5 px-5">
          <div className="flex gap-2">
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Status"
              info={
                <div className="flex gap-2 items-center">
                  <StatusIcon color={BILLING_STATUS_COLOR[invoice?.status]} />
                  <div style={{ color: BILLING_STATUS_COLOR[invoice?.status] }}>
                    {BILLING_STATUSES_LABELS[invoice?.status]}
                  </div>
                </div>
              }
            />
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Valor por parcela"
              info={'MZN ' + formatCurrency(invoice?.total_value / invoice?.installments_number)}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Valor total"
              info={'MZN ' + formatCurrency(invoice?.total_value)}
              withBackground
            />
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Dia de Vencimento"
              info={moment(invoice?.first_due_date).date()}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Cliente"
              info={
                <SimpleLink to={`/clientes/${invoice?.customer?.id}`}>
                  {invoice?.customer?.first_name} {invoice?.customer?.last_name}
                </SimpleLink>
              }
            />
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Produto"
              info={invoice?.product?.name}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Número de parcelas"
              info={invoice?.installments_number}
              withBackground
            />
            <InfoLabeled
              loading={isInvoiceLoading}
              label="Descrição"
              info={invoice?.product?.description}
              withBackground
              big
            />
          </div>
        </div>
      </div>
    </DetailSection>
  );
}
