import React from 'react';

export default function DolarIcon({ width = 24, height = 24, color = '#fff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4V6" stroke={color} strokeLinecap="round" />
      <path d="M12 18V20" stroke={color} strokeLinecap="round" />
      <path
        d="M15.5 8C15.5 8 15 6 12 6C9 6 8.5 7.95652 8.5 9C8.5 13.1403 15.5 10.9649 15.5 15C15.5 16.0435 15 18 12 18C9 18 8.5 16 8.5 16"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}
