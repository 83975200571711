import PageHeader from '../../components/PageHeader/PageHeader';
import SearchFilter from '../../components/Filters/SearchFilter';
import { PlusIcon } from '../../assets/imgs';
import { Colors } from '../../utils/colors';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import GroupsTable from './components/GroupsTable';
import AddGroupModal from './components/AddGroupModal';
import { useStore } from '../../store/useStore';
import { useEffect } from 'react';
import UpdateGroupModal from './components/UpdateGroupModal';
import DeleteGroupModal from './components/DeleteGroupModal';

const Group = () => {
  const showCreateGroupModal = useStore((state) => state.groups.showCreateGroupModal);
  const toggleShowCreateGroupModal = useStore((state) => state.groups.toggleShowCreateGroupModal);
  const showUpdateGroupModal = useStore((state) => state.groups.showUpdateGroupModal);
  const toggleShowUpdateGroupModal = useStore((state) => state.groups.toggleShowUpdateGroupModal);
  const showDeleteGroupModal = useStore((state) => state.groups.showDeleteGroupModal);
  const toggleShowDeleteGroupModal = useStore((state) => state.groups.toggleShowDeleteGroupModal);
  const isLoading = useStore((state) => state.groups.isLoading);
  const getGroups = useStore((state) => state.groups.getGroups);
  const groups = useStore((state) => state.groups.groups);
  const setFilter = useStore((state) => state.groups.setFilter);
  const searchFilter = useStore((state) => state.groups.filters.groupName);
  const resetFilters = useStore((state) => state.groups.resetFilters);

  useEffect(() => {
    getGroups();
  }, [searchFilter]);
  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);
  const handleChangeSearchFilter = (text) => {
    setFilter('groupName', text);
  };
  return (
    <div>
      <div>
        <PageHeader title="Grupos" />
        <div className="flex gap-4 flex-wrap">
          <SearchFilter onChange={handleChangeSearchFilter} className="w-[160px]" />
          <FilledButton
            bg={Colors.Green}
            onClick={toggleShowCreateGroupModal}
            className="px-5 h-[42px]"
            heightClass="min-h-[42px]">
            <div className="flex gap-3 items-center h-[40px]">
              <div className="font-semibold">Novo grupo</div>
              <PlusIcon color={Colors.White} />
            </div>
          </FilledButton>
        </div>
        <div className="max-w-[100%] mt-5">
          <GroupsTable data={groups} loading={isLoading} />
        </div>
      </div>
      <AddGroupModal
        isModalOpen={showCreateGroupModal}
        handleModalOpen={toggleShowCreateGroupModal}
      />
      <UpdateGroupModal
        isModalOpen={showUpdateGroupModal}
        handleModalOpen={toggleShowUpdateGroupModal}
      />
      <DeleteGroupModal
        isModalOpen={showDeleteGroupModal}
        handleModalOpen={toggleShowDeleteGroupModal}
      />
    </div>
  );
};

export default Group;
