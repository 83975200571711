import React from 'react';
import Tooltip from '../../Information/Tooltip/tooltip';

export default function LabelWrapper({
  label,
  children,
  borderBottom = false,
  type = 'error',
  text
}) {
  return (
    <div className="flex">
      <div
        className={`flex-1 border-b-0 border border-gray-2 bg-gray-1 p-[15px] font-medium flex gap-2 items-center ${
          borderBottom ? 'border-b-[1px]' : ''
        }`}>
        <span>{label}</span> {text && <Tooltip type={type} text={'text'} />}
      </div>
      <div
        className={`flex-[2] border-l-0 border-b-0 border border-gray-2 ${
          borderBottom ? 'border-b-[1px]' : ''
        } `}>
        {children}
      </div>
    </div>
  );
}
