import React from 'react';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import { RightIcon } from '../../../assets/imgs';
import { formatCurrency } from '../../../utils/currencyHelper';
import Skeleton from 'react-loading-skeleton';

const benefits = [
  'Clientes ilimitados',
  'Gestão financeira completa',
  'Cobranças automáticas',
  'Controle de dívidas',
  'Atendimento especializado'
];

export default function PlanSection() {
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.company.isLoading);

  return (
    <DetailSection title="Meu plano PiMinder">
      <div className="p-5 flex flex-col md:flex-row gap-4 md:gap-10">
        {isLoading ? (
          <Skeleton width={150} height={20} count={2} />
        ) : (
          <div className="font-semibold text-xl text-orange">
            <div>Plano {company?.plan?.name}</div>
            <div>MZN {formatCurrency(company?.plan?.value) ?? '-'}</div>
          </div>
        )}
        <div>
          {isLoading
            ? [...Array(5)].map((row, index, array) => (
                <div
                  key={index}
                  className={`flex gap-3 ${
                    index !== array.length - 1 ? 'border-b border-gray-2 pb-4' : ''
                  } ${index !== 0 ? 'pt-4' : ''}`}>
                  <Skeleton width={180} />
                </div>
              ))
            : benefits.map((benefit, index, array) => {
                return (
                  <div
                    key={index}
                    className={`flex gap-3 ${
                      index !== array.length - 1 ? 'border-b border-gray-2 pb-4' : ''
                    } ${index !== 0 ? 'pt-4' : ''}`}>
                    <RightIcon />
                    {benefit}
                  </div>
                );
              })}
        </div>
      </div>
    </DetailSection>
  );
}
