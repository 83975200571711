import React from 'react';

export default function FilterIcon({ width = 16, height = 16, color = '#fff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4055 0H0.656248C0.311033 0 0.0312481 0.279785 0.0312481 0.625C0.0312481 2.36548 0.777342 4.0271 2.07824 5.18347L4.81543 7.61634C5.29003 8.03821 5.56225 8.64441 5.56225 9.27954V15.3743C5.56225 15.8723 6.11889 16.1709 6.53381 15.8942L10.2212 13.436C10.395 13.3201 10.4995 13.125 10.4995 12.916V9.27954C10.4995 8.64441 10.7717 8.03821 11.2463 7.61634L13.9834 5.18347C15.2843 4.0271 16.0304 2.36548 16.0304 0.625C16.0304 0.279785 15.7506 0 15.4055 0ZM13.1529 4.24915L10.4159 6.68213C9.67468 7.34107 9.24951 8.28772 9.24951 9.27942V12.5815L6.81213 14.2064V9.27954C6.81213 8.28772 6.38696 7.34107 5.64575 6.68213L2.90869 4.24927C2.03149 3.46936 1.47156 2.40076 1.32165 1.24988H14.74C14.5901 2.40076 14.0303 3.46936 13.1529 4.24915Z"
        fill={color}
      />
    </svg>
  );
}
