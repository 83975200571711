import React from 'react';

export default function SearchIcon({ width = 16, height = 16, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8616 15.184L11.9725 11.2958C13.0157 10.0967 13.6474 8.53215 13.6474 6.82217C13.6474 3.05809 10.5851 0 6.82369 0C3.05877 0 0 3.06164 0 6.82217C0 10.5827 3.06232 13.6443 6.82369 13.6443C8.53404 13.6443 10.0989 13.0129 11.2983 11.9698L15.1874 15.8581C15.2797 15.9503 15.4039 16 15.5245 16C15.6452 16 15.7694 15.9539 15.8616 15.8581C16.0461 15.6736 16.0461 15.3685 15.8616 15.184ZM0.954535 6.82217C0.954535 3.5867 3.58749 0.957871 6.82014 0.957871C10.0563 0.957871 12.6857 3.59024 12.6857 6.82217C12.6857 10.0541 10.0563 12.69 6.82014 12.69C3.58749 12.69 0.954535 10.0576 0.954535 6.82217Z"
        fill={color}
      />
    </svg>
  );
}
