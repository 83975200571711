import React from 'react';
import Slider from 'react-input-slider';
import { Colors } from '../../utils/colors';

export default function SlideCard({
  info,
  value,
  onChange,
  min,
  max,
  step,
  unitName,
  customText = ''
}) {
  return (
    <div className="">
      <div className="text-center text-sm py-3 px-4">{info}</div>
      <div className="mx-10">
        <div className="flex justify-between font-medium text-lg text-gray-3">
          <div>{min}</div>
          <div>{max}</div>
        </div>
        <Slider
          styles={{
            track: {
              backgroundColor: Colors.Gray2,
              width: '100%',
              height: 12
            },
            active: {
              backgroundColor: Colors.Orange
            },
            thumb: {
              width: 30,
              height: 30,
              border: '2px solid ' + Colors.Orange
            }
          }}
          axis="x"
          xstep={step}
          xmin={min}
          x={value}
          xmax={max}
          onChange={onChange}
        />
        <div className="text-center text-orange font-medium text-2xl my-3">
          {customText ? customText : `${value} ${unitName}`}
        </div>
      </div>
    </div>
  );
}
