import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { Colors } from '../../../utils/colors';
import { ERRORS } from '../../../utils/errors';

const CreateManyBillingsError = () => {
  const showCreateManyBillingsError = useStore(
    (state) => state.billings.showCreateManyBillingsError
  );
  const toggleShowCreateManyBillingsError = useStore(
    (state) => state.billings.toggleShowCreateManyBillingsError
  );
  const billingsErrors = useStore((state) => state.billings.billingsErrors);
  const selectedCustomers = useStore((state) => state.customers.selectedCustomers);
  const errors = billingsErrors.map((error, index) => {
    return {
      customer: `${selectedCustomers[index].first_name} ${selectedCustomers[index].last_name}`,
      error: ERRORS[error.error]
    };
  });
  return (
    <Modal
      title="Erro"
      onClose={toggleShowCreateManyBillingsError}
      show={showCreateManyBillingsError}
      noDefaultHeight>
      <div className="p-5">
        <div>
          <h2 className="font-medium text-2xl mb-4">Estado das transações</h2>
          <div className="mb-4">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Nome</th>
                  <th className="text-left">Status</th>
                  <th className="text-left">Erro</th>
                </tr>
              </thead>
              <tbody className="overflow-y-scroll">
                {errors.map((error) => (
                  <tr key={error.customer}>
                    <td className="text-left p-2">{error.customer}</td>
                    <td className="text-left p-2">{error.error ? 'Recusado' : 'Aprovado'}</td>
                    <td className="text-left p-2">{error.error ? error.error : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <FilledButton
          className="px-10"
          onClick={toggleShowCreateManyBillingsError}
          bg={Colors.Red}
          color={Colors.White}>
          Fechar
        </FilledButton>
      </div>
    </Modal>
  );
};

export default CreateManyBillingsError;
