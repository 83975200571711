import React from 'react';

export default function Checkbox(props) {
  const { value, label } = props;
  return (
    <div className="flex gap-2 items-center">
      <input className="accent-orange" type="checkbox" {...props} value={value} />
      <label>{label}</label>
    </div>
  );
}
