import { Colors } from './colors';

export const LOGIN_PAGE_FORMS = {
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  LOGIN: 'LOGIN'
};

export const CUSTOMER_STATUSES = [
  { label: 'Todos', value: '' },
  { label: 'Em dia', value: 'ACTIVE' },
  { label: 'Devedores', value: 'DEFAULTER' },
  { label: 'Inativo', value: 'INACTIVE' }
];

export const BILLING_STATUSES = [
  { label: 'Todos', value: '' },
  { label: 'Pago', value: 'PAID' },
  { label: 'A confirmar', value: 'TOBEACCEPT' },
  { label: 'Pendente', value: 'WAITINGPAYMENT' },
  { label: 'Vencido', value: 'OVERDUE' }
];

export const PAYMENT_TYPES = [
  { label: 'Todos', value: '' },
  { label: 'Numerário', value: 'numerary' },
  { label: 'MPESA', value: 'mpesa' },
  { label: 'Transferência bancária', value: 'bank_deposit' }
];

export const DEDUCTION_TYPES = [
  { label: 'Todos', value: '' },
  { label: 'Aprovados', value: 'approved' },
  { label: 'Recusado', value: 'denied' }
];

export const CUSTOMER_STATUSES_LABEL = {
  ACTIVE: 'Em dia',
  DEFAULTER: 'Devedor',
  INACTIVE: 'Inativo'
};

export const CUSTOMER_STATUS_COLOR = {
  ACTIVE: Colors.Green,
  DEFAULTER: Colors.Red,
  INACTIVE: Colors.Gray6
};

export const BILLING_STATUS_COLOR = {
  PAID: Colors.Gray6,
  TOBEACCEPT: Colors.Blue,
  WAITINGPAYMENT: Colors.Yellow,
  OVERDUE: Colors.Red
};

export const BILLING_TYPES_LABELS = {
  ONETIME: 'À vista',
  INSTALLMENT: 'Parcelado',
  SIGNATURE: 'Mensalidade'
};

export const BILLING_TYPES = {
  ONETIME: 'ONETIME',
  INSTALLMENT: 'INSTALLMENT',
  SIGNATURE: 'SIGNATURE'
};

export const BILLING_STATUSES_LABELS = {
  PAID: 'Pago',
  TOBEACCEPT: 'À Confirmar',
  WAITINGPAYMENT: 'Pendente',
  OVERDUE: 'Vencido'
};

export const DEDUCTION_LABELS = {
  WHATSAPP: 'Whatsapp',
  PACKAGE: 'Pacote'
};

export const INVOICE_STATUSES = {
  PAID: { label: 'Pago', color: Colors.Gray6 },
  TOBEACCEPT: { label: 'À Confirmar', color: Colors.Blue },
  OVERDUE: { label: 'Vencido', color: Colors.Red },
  WAITINGPAYMENT: { label: 'A Pagar', color: Colors.Yellow }
};

export const INVOICE_STATUSES_VALUE = {
  PAID: 'PAID',
  TOBEACCEPT: 'TOBEACCEPT',
  OVERDUE: 'OVERDUE',
  WAITINGPAYMENT: 'WAITINGPAYMENT'
};

export const PAYMENT_METHODS_LABELS = {
  bank_deposit: 'Transferência bancária',
  mpesa: 'M-Pesa',
  numerary: 'Numerário'
};

export const BILLING_LINK_STATUS_LABELS = {
  WAITINGPAYMENT: { text: 'Aguardando pagamento', color: Colors.Orange },
  TOBEACCEPT: { text: 'Aguardando confirmação', color: Colors.Blue },
  PAID: { text: 'Pago', color: Colors.Gray6 },
  OVERDUE: { text: 'Vencido', color: Colors.Red }
};

export const PAYMENT_METHODS_OPTIONS = [
  { value: 'mpesa', label: 'MPESA' },
  { value: 'numerary', label: 'Numerário' },
  { value: 'bank_deposit', label: 'Transferência bancária' }
];

export const BILLING_INTERVAL_LABELS = {
  1: 'MÊS',
  2: 'BIMESTRE',
  6: 'SEMESTRE',
  12: 'ANO'
};

export const BILLING_INTERVALS = [
  { value: '1', label: 'Mensal' },
  { value: '2', label: 'Bimestral' },
  { value: '3', label: 'Trimestral' },
  { value: '4', label: 'Quadrimestral' },
  { value: '6', label: 'Semestral' },
  { value: '12', label: 'Anual' }
];

export const SIGNATURE_INTERVAL_LABELS = {
  1: 'Mensal',
  2: 'Bimestral',
  6: 'Semetral',
  12: 'Anual'
};

export const SIGNATURE_LABELS = {
  signature: 'Recorrente',
  in_cash_or_installments: 'À vista ou parcelado'
};

export const SIGNATURE_SITUATIONS = {
  PAID: { color: Colors.Gray6, label: 'Pago' },
  WAITINGPAYMENT: { color: Colors.Yellow, label: 'Aguardando pagamento' },
  TOBEACCEPT: { color: Colors.Blue, label: 'À confirmar' },
  OVERDUE: { color: Colors.Red, label: 'Vencido' }
};

export const ITEM_SITUATIONS = {
  PAID: { color: Colors.Gray6, label: 'Pago' },
  WAITINGPAYMENT: { color: Colors.Yellow, label: 'Aguardando pagamento' },
  TOBEACCEPT: { color: Colors.Blue, label: 'À confirmar' },
  OVERDUE: { color: Colors.Red, label: 'Vencido' }
};

export const DATE_TYPES = [
  { label: 'Data de vencimento', value: 'dueDate' },
  { label: 'Data de criação', value: 'creationDate' },
  { label: 'Data de confirmação', value: 'receiptDate' }
];
