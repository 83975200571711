import React, { useState } from 'react';
import { SearchIcon } from '../../assets/imgs';
import { Colors } from '../../utils/colors';

export default function SearchFilter(props) {
  const [debouceTimeout, setDebouceTimeout] = useState(null);

  const handleChange = (e) => {
    clearTimeout(debouceTimeout);
    const timeout = setTimeout(() => props.onChange(e.target.value), 1000);
    setDebouceTimeout(timeout);
  };

  return (
    <div className={`relative ${props.className}`}>
      <label className={`text-black relative block`}>
        <div className="pointer-events-none absolute top-1/2 left-3 transform -translate-y-1/2">
          <SearchIcon color={Colors.Gray6} />
        </div>
        <input
          {...props}
          onChange={handleChange}
          type="text"
          placeholder="Pesquisar..."
          className={`h-[42px] text-sm pl-9 rounded form-input bg-white border border-gray-2 py-2 placeholder-gray-6 appearance-none w-full block focus:outline-none`}
        />
      </label>
    </div>
  );
}
