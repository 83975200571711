import produce from 'immer';
import { toast } from 'react-toastify';
import creditService from '../../services/creditService';

export const createCreditsSlice = (set, get) => ({
  credits: {
    isLoading: false,
    isInfiniteScrollLoading: false,

    showMpesaPhoneNumberModal: false,
    showUnsufficientPackagesModal: false,
    showAddCreditsModal: false,

    credits: [],
    creditsBefore: null,
    creditsAfter: null,
    filters: {
      hasMore: true,
      status: 'approved',
      cursor: '',
      date: {
        startDate: '',
        endDate: ''
      }
    },
    resetCredits: () => {
      set(
        produce((state) => {
          state.credits.credits = [];
          state.credits.creditsBefore = null;
          state.credits.creditsAfter = null;
          state.credits.filters.hasMore = true;
          state.credits.filters.cursor = '';
          state.credits.filters.status = 'approved';
        })
      );
    },
    toggleShowUnsufficientPackagesModal: () => {
      set(
        produce((state) => {
          state.credits.showUnsufficientPackagesModal =
            !state.credits.showUnsufficientPackagesModal;
        })
      );
    },
    toggleShowAddCreditsModal: () => {
      set(
        produce((state) => {
          state.credits.showAddCreditsModal = !state.credits.showAddCreditsModal;
        })
      );
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.credits.filters[filter] = value;
        })
      );
    },
    toggleShowMpesaPhoneNumberModal: () => {
      set(
        produce((state) => {
          state.credits.showMpesaPhoneNumberModal = !state.credits.showMpesaPhoneNumberModal;
        })
      );
    },
    getCredits: () => {
      set(
        produce((state) => {
          state.credits.isLoading = true;
        })
      );

      let filters = {
        ...(get().credits.filters.deduction_type
          ? { deduction_type: get().credits.filters.deduction_type }
          : {}),
        ...get().credits.filters.date
      };

      creditService
        .getCredits(filters)
        .then((creditsData) => {
          set(
            produce((state) => {
              state.credits.creditsBefore = creditsData?.before_credits;
              state.credits.creditsAfter = creditsData?.after_credits;

              state.credits.credits = [
                { before_credits: creditsData?.before_credits ?? {} },
                ...(creditsData ? [...creditsData] : []),
                { after_credits: creditsData?.after_credits ?? {} }
              ];
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.credits.isLoading = false;
            })
          );
        });
    },
    addCreditsWithCreditCard: () => {
      toast.info('Serviço temporariamente indisponível');
      // { pkg_amount, whatsApp_amount })
      // set(
      //   produce((state) => {
      //     state.credits.isLoading = true;
      //   })
      // );
      // creditService
      //   .addCreditsWithCreditCard({ pkg_amount, whatsApp_amount })
      //   .then((link) => {
      //     window.open(link);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     toast.error(error.message);
      //   })
      //   .finally(() => {
      //     set(
      //       produce((state) => {
      //         state.credits.isLoading = false;
      //       })
      //     );
      //   });
    },
    addCreditsWithMpesa: ({ amount, phone }) => {
      const wallet_id = get().company.company.wallet.id;
      set(
        produce((state) => {
          state.credits.isLoading = true;
        })
      );
      creditService
        .addCreditsWithMpesa({ wallet_id, amount, phone })
        .then(() => {
          get().company.getCompanyResume();
          toast.success('Crédito solicitado com sucesso');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.credits.isLoading = false;
              state.credits.showMpesaPhoneNumberModal = false;
              state.credits.showAddCreditsModal = false;
            })
          );
        });
    },
    listTransactions: (isInfiniteScroll = false) => {
      const wallet_id = get().company?.company?.wallet?.id;
      if (!get().credits.filters.hasMore || !wallet_id) return;
      if (isInfiniteScroll) {
        set(
          produce((state) => {
            state.credits.isInfiniteScrollLoading = true;
          })
        );
      } else {
        set(
          produce((state) => {
            state.credits.isLoading = true;
          })
        );
      }
      creditService
        .listTransactions(wallet_id, get().credits.filters)
        .then((data) => {
          set(
            produce((state) => {
              if (isInfiniteScroll) {
                state.credits.credits = [...state.credits.credits, ...data.transactions];
              } else {
                state.credits.credits = data.transactions;
              }
              state.credits.filters.cursor = data?.next_cursor;
              state.credits.filters.hasMore = data?.has_more;
            })
          );
        })
        .catch((error) => {
          set(
            produce((state) => {
              state.credits.credits = [];
            })
          );
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.credits.isLoading = false;
              state.credits.isInfiniteScrollLoading = false;
            })
          );
        });
    }
  }
});
