import { Field, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import LabelWrapper from '../../../components/FormComponents/LabelWrapper/LabelWrapper';
import BorderlessStaticText from '../../../components/FormComponents/Inputs/BorderlessStaticText';
import { Colors } from '../../../utils/colors';
import BorderlessInput from '../../../components/FormComponents/Inputs/BorderlessInput';
import BorderlessMaskedInput from '../../../components/FormComponents/Inputs/BorderlessMaskedInput';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { useStore } from '../../../store/useStore';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { GymGravatar, SaveIcon } from '../../../assets/imgs';
import Skeleton from 'react-loading-skeleton';
import BorderlessSelect from '../../../components/FormComponents/SelectFields/BorderlessSelect';

export default function CompanySection() {
  const getCompany = useStore((state) => state.company.getCompany);
  const updateCompany = useStore((state) => state.company.updateCompany);
  const company = useStore((state) => state.company.company);
  const isLoading = useStore((state) => state.company.isLoading);
  const getCitiesAvaiableAsync = useStore((state) => state.staticData.getCitiesAvaiableAsync);
  const getCitiesAvaiable = useStore((state) => state.staticData.getCitiesAvaiable);
  const getRegionsAvaiable = useStore((state) => state.staticData.getRegionsAvaiable);
  const citiesAvaiable = useStore((state) => state.staticData.citiesAvaiable);
  const regionsAvaiable = useStore((state) => state.staticData.regionsAvaiable);
  const formRef = useRef(null);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const errorMessageObject = (formik, topic, field) =>
    formik.touched[topic] &&
    formik.errors[topic] &&
    formik.touched[topic][field] &&
    formik.errors[topic][field] ? (
      <div>{formik.errors[topic][field]}</div>
    ) : (
      ''
    );

  const onSubmit = (values) => {
    updateCompany(values);
  };

  const submit = (e) => {
    e.preventDefault();
    if (formRef.current) formRef.current.submitForm();
  };

  useEffect(() => {
    getCompany();
    getRegionsAvaiable();
  }, []);

  return (
    <div className="">
      <DetailSection
        title="Dados da empresa"
        button={
          isLoading ? (
            <Skeleton width={85} height={46} />
          ) : (
            <FilledButton
              type="button"
              loading={isLoading}
              className="text-base px-5"
              onClick={submit}>
              <div className="flex gap-3 items-center">
                Salvar
                <SaveIcon />
              </div>
            </FilledButton>
          )
        }>
        {isLoading ? (
          <div className="p-5">
            <div className="flex gap-4 pb-5 items-center">
              <Skeleton circle height={55} width={55} />
              <div>
                <Skeleton width={200} height={20} />
                <Skeleton width={150} />
              </div>
            </div>
            <Skeleton height={820} />
          </div>
        ) : (
          <Formik
            innerRef={formRef}
            initialValues={{
              email: '',
              name: '',
              phone: '+258',
              nuit: '',
              is_ei: false,
              owner_name: '',
              address: {
                street: '',
                apartment: '',
                city: '',
                province: '',
                postal_code: ''
              }
            }}
            validationSchema={Yup.object({
              email: Yup.string().email('Endereço de email inválido'),
              name: Yup.string().required('Campo obrigatório'),
              nuit: Yup.string().required('Campo obrigatório'),
              is_ei: Yup.boolean().required('Campo obrigatório'),
              owner_name: Yup.string().when('is_ei', {
                is: (val) => val == true,
                then: (schema) => schema.required('Campo obrigatório'),
                otherwise: (schema) => schema
              }),
              phone: Yup.string(),
              // .matches(
              //   /^\+258 \d\d \d\d\d \d\d\d\d$/,
              //   'O telefone deve ter o formato +258 XX XXX XXXX'
              // )
              // .required('Campo obrigatório'),
              address: Yup.object({
                street: Yup.string(),
                apartment: Yup.string(),
                city: Yup.string(),
                province: Yup.string()
                // postal_code: Yup.string().required('Campo obrigatório')
              })
            })}
            onSubmit={onSubmit}>
            {(formik) => {
              useEffect(() => {
                if (company) {
                  formik.setValues({ ...company, is_ei: company?.is_ei ? true : false });
                  if (company?.address?.province) {
                    getCitiesAvaiableAsync(company?.address?.province).then(() => {
                      formik.setFieldValue('address.city', company?.address?.city ?? '');
                    });
                  }
                }
              }, [company]);

              return (
                <form onSubmit={formik.handleSubmit} className="flex flex-col overflow-hidden">
                  <div className="h-full md:max-h-[80%] overflow-y-auto p-5">
                    <div className="flex mb-5">
                      <img className="md:w-[55px]" src={GymGravatar} />
                      <div className="pl-4">
                        <div className="font-semibold text-xl">{company?.name}</div>
                        <div>{company?.email}</div>
                      </div>
                    </div>
                    <LabelWrapper label="Email">
                      <BorderlessInput
                        name="email"
                        placeholder="Email"
                        error={errorMessage(formik, 'email')}
                        {...formik.getFieldProps('email')}
                      />
                    </LabelWrapper>
                    <LabelWrapper label="Nome da empresa">
                      <BorderlessInput
                        name="name"
                        placeholder="Nome da empresa"
                        error={errorMessage(formik, 'name')}
                        {...formik.getFieldProps('name')}
                      />
                    </LabelWrapper>
                    <LabelWrapper label="NUIT">
                      <BorderlessInput
                        name="nuit"
                        placeholder="Digite o NUIT"
                        error={errorMessage(formik, 'nuit')}
                        {...formik.getFieldProps('nuit')}
                      />
                    </LabelWrapper>
                    <LabelWrapper label="É uma EI?">
                      <BorderlessStaticText color={Colors.Orange} borderless>
                        {company?.is_ei ? 'Sim' : 'Não'}
                      </BorderlessStaticText>
                    </LabelWrapper>
                    {company?.is_ei ? (
                      <LabelWrapper label="Nome do proprietário">
                        <BorderlessInput
                          name="owner_name"
                          placeholder="Nome do proprietário"
                          error={errorMessage(formik, 'owner_name')}
                          {...formik.getFieldProps('owner_name')}
                        />
                      </LabelWrapper>
                    ) : null}
                    <LabelWrapper label="Telefone">
                      <BorderlessStaticText color={Colors.Orange}>Moçambique</BorderlessStaticText>
                      <Field
                        component={BorderlessMaskedInput}
                        name="phone"
                        mask="+258 99 999 9999"
                        alwaysShowMask={true}
                        maskPlaceholder={null}
                        error={errorMessage(formik, 'phone')}
                        {...formik.getFieldProps('phone')}
                      />
                    </LabelWrapper>

                    <LabelWrapper label="Endereço" borderBottom>
                      <BorderlessStaticText color={Colors.Orange}>Moçambique</BorderlessStaticText>
                      <BorderlessInput
                        name="address.street"
                        placeholder="Endereço de Rua"
                        borderBottom
                        error={errorMessageObject(formik, 'address', 'street')}
                        {...formik.getFieldProps('address.street')}
                      />
                      <BorderlessInput
                        name="address.apartment"
                        placeholder="Apt./Suite"
                        borderBottom
                        error={errorMessageObject(formik, 'address', 'apartment')}
                        {...formik.getFieldProps('address.apartment')}
                      />
                      <div className="pt-2 pb-2.5 px-3.5 border-b border-gray-2">
                        <Field
                          component={BorderlessSelect}
                          name="address.province"
                          onChange={(option) => {
                            formik.setFieldValue('address.province', option.value);
                            getCitiesAvaiable(option.value);
                            formik.setFieldValue('address.city', '');
                          }}
                          menuPlacement="top"
                          placeholder="Província"
                          options={regionsAvaiable}
                          error={errorMessageObject(formik, 'address', 'province')}
                        />
                      </div>
                      <div className="pt-2 pb-2.5 px-3.5 border-b border-gray-2">
                        <Field
                          component={BorderlessSelect}
                          name="address.city"
                          menuPlacement="top"
                          placeholder="Cidade"
                          options={citiesAvaiable}
                          error={errorMessageObject(formik, 'address', 'city')}
                        />
                      </div>
                      <BorderlessInput
                        name="address.postal_code"
                        placeholder="Código Postal"
                        error={errorMessageObject(formik, 'address', 'postal_code')}
                        {...formik.getFieldProps('address.postal_code')}
                      />
                    </LabelWrapper>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </DetailSection>
    </div>
  );
}
