import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { ERRORS } from '../utils/errors';
const groupService = {
  getGroups: async (page, filters) => {
    try {
      const response = await api.get('/groups', { params: { page, ...filters } });
      // Recebemos todos os grupos: groups
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao buscar grupos');
    }
  },
  createGroup: async (data) => {
    try {
      const response = await api.post('/groups/create', data);
      // Recebemos os dados do grupo: group
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(e.response.data.error);
      }
      throw new Error('Erro ao criar grupo');
    }
  },
  updateGroup: async ({ id, name, description }) => {
    try {
      const response = await api.post(`/groups/update`, {
        groupId: id,
        newName: name,
        description
      });
      // Recebemos os dados do grupo: group
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao atualizar grupo');
    }
  },
  deleteGroup: async (id) => {
    try {
      const response = await api.post('/groups/delete', { groupId: id });
      // Recebemos os dados: {message, group}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao excluir grupo');
    }
  },
  associateCustomersToGroups: async ({ customerIds, groupIds }) => {
    try {
      const response = await api.post('/groups/customer/associate', { customerIds, groupIds });
      // Recebemos os dados: {message, customers}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao associar cliente ao grupo');
    }
  },
  dissociateCustomersFromGroups: async ({ customerIds, groupIds }) => {
    try {
      const response = await api.post('/groups/customer/dissociate', { customerIds, groupIds });
      // Recebemos os dados: {message, customers}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao dissociar cliente do grupo');
    }
  },
  getGroupsWithCustomers: async () => {
    try {
      const response = await api.get('/groups/customer');
      // Recebemos os dados: {message, groupsWithCustomers}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao buscar grupos com clientes');
    }
  },
  getCustomersOnGroup: async ({ groupId }) => {
    try {
      const response = await api.get(`/groups/filter/customer`, { groupId });
      // Recebemos os dados: {message, grupo, id, company, customers}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao buscar clientes por grupo');
    }
  },
  getGroupsByCustomer: async ({ customerId }) => {
    try {
      const response = await api.get(`/groups/filter/${customerId}`);
      // Recebemos os dados: {groups}
      return response.data;
    } catch (e) {
      if (isAxiosError(e) && e?.response?.data?.error) {
        throw new Error(ERRORS[e.response.data.error]);
      }
      throw new Error('Erro ao buscar grupos por cliente');
    }
  }
};

export default groupService;
