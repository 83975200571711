import React, { useEffect } from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import BillingDetails from './BillingDetails';
import { CloseIcon, RightIcon } from '../../../assets/imgs';
import * as Yup from 'yup';
import { Formik } from 'formik';

export default function ConfirmBillingReceptionModal() {
  const confirmBilling = useStore((state) => state.billings.confirmBilling);
  const denyBilling = useStore((state) => state.billings.denyBilling);
  const isConfirmBillingLoading = useStore((state) => state.billings.isConfirmBillingLoading);
  const isDenyBillingLoading = useStore((state) => state.billings.isDenyBillingLoading);
  const showConfirmBillingModal = useStore((state) => state.billings.showConfirmBillingModal);
  const closeConfirmBillingModal = useStore((state) => state.billings.closeConfirmBillingModal);

  const selectedBilling = useStore((state) => state.billings.selectedBilling);

  const handleConfirmBilling = (confirmationData) => {
    confirmBilling(confirmationData);
  };

  const handleDenyBilling = (confirmationData) => {
    denyBilling(confirmationData);
  };

  return (
    <Modal
      title="Confirmar pagamento"
      onClose={closeConfirmBillingModal}
      show={showConfirmBillingModal}>
      <Formik
        initialValues={{
          paidWhere: '',
          paidWhen: '',
          notifyCustomer: 'no',
          renewBilling: 'no'
        }}
        validationSchema={Yup.object({
          paidWhere: Yup.string().required('Campo obrigatório'),
          paidWhen: Yup.string().required('Campo obrigatório'),
          notifyCustomer: Yup.string().required('Campo obrigatório'),
          renewBilling: Yup.string().required('Campo obrigatório')
        })}>
        {(formik) => {
          useEffect(() => {
            if (selectedBilling) {
              formik.setValues({
                paidWhen: selectedBilling.paidWhen ? new Date(selectedBilling.paidWhen) : '',
                paidWhere: selectedBilling.paidWhere,
                notifyCustomer: 'no',
                renewBilling: 'no'
              });
            }
          }, [selectedBilling]);
          return (
            <div className="grid h-full overflow-hidden">
              <div className="h-full overflow-y-auto p-5">
                <div>
                  Realize a confirmação do pagamento do seu cliente. Após a confirmação, seu
                  controle financeiro é automaticamente atualizado.
                </div>
                <BillingDetails billing={selectedBilling} formik={formik} />
              </div>

              <div className="flex sticky bottom-0 p-5 bg-white justify-around self-end">
                <FilledButton
                  onClick={() => handleDenyBilling(formik.values)}
                  loading={isDenyBillingLoading}
                  className="px-5"
                  bg={Colors.Red}>
                  <div className="flex gap-2 items-center">
                    <div>
                      <CloseIcon width={13} color={Colors.White} />
                    </div>
                    <div>Negar</div>
                  </div>
                </FilledButton>
                <FilledButton
                  // onClick={handleConfirmBilling}
                  onClick={() => {
                    formik.validateForm().then((errors) => {
                      if (!errors.paidWhen && !errors.paidWhere) {
                        handleConfirmBilling(formik.values);
                      }
                    });
                  }}
                  loading={isConfirmBillingLoading}
                  className="px-5"
                  bg={Colors.Green}>
                  <div className="flex gap-2 items-center">
                    <div>
                      <RightIcon width={13} color={Colors.White} />
                    </div>
                    <div>Confirmar</div>
                  </div>
                </FilledButton>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
}
