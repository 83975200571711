import React from 'react';
import { Colors } from '../../utils/colors';

export default function RightIcon({ width = 17, height = 18, color = Colors.Orange }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_335_1100" fill="white">
        <path d="M0 11.1071L10.1844 2.02999e-05L16.9815 6.2325L6.79716 17.3396L0 11.1071Z" />
      </mask>
      <path
        d="M6.79716 17.3396L4.76968 19.5508L6.98086 21.5783L9.00834 19.3671L6.79716 17.3396ZM14.7703 4.20502L4.58598 15.3121L9.00834 19.3671L19.1927 8.25998L14.7703 4.20502ZM8.82464 15.1284L2.02748 8.89594L-2.02748 13.3183L4.76968 19.5508L8.82464 15.1284Z"
        fill={color}
        mask="url(#path-1-inside-1_335_1100)"
      />
    </svg>
  );
}
