import React from 'react';
import { Colors } from '../../utils/colors';

export default function CloseIcon({ width = 17, height = 17, color = Colors.black }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line x1="1.60066" y1="1.35661" x2="15.4177" y2="15.1736" stroke={color} strokeWidth="2" />
      <line x1="1.5822" y1="15.3141" x2="15.3992" y2="1.4971" stroke={color} strokeWidth="2" />
    </svg>
  );
}
