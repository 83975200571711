import React from 'react';

const BoxIcon = ({ width = 24, height = 24, color = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6.02007L11.3637 3.21351C11.7766 3.07496 12.2234 3.07496 12.6363 3.21351L21 6.02007M3 6.02007L12 9.35294M3 6.02007V16.3503C3 17.1933 3.52862 17.9458 4.32168 18.2317L12 21M12 9.35294L21 6.02007M12 9.35294V21M21 6.02007V16.3503C21 17.1933 20.4714 17.9458 19.6783 18.2317L12 21"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BoxIcon;
