import React from 'react';
import Select, { components } from 'react-select';
import { Colors } from '../../utils/colors';

export default function SelectFilter({
  onChange,
  options,
  placeholder,
  iconComponent,
  height = 42,
  width = 150
}) {
  const IconComponent = iconComponent;

  return (
    <Select
      components={{
        DropdownIndicator: ({ ...props }) => (
          <components.DropdownIndicator {...props}>
            <div className="w-[26px] flex justify-center">
              <IconComponent color={Colors.Gray6} />
            </div>
          </components.DropdownIndicator>
        )
      }}
      styles={{
        menu: (base) => ({
          ...base,
          width: 'max-content',
          minWidth: '100%'
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          outline: 'none',
          boxShadow: 'none',
          fontSize: 14,
          height: height,
          width: width,
          borderColor: Colors.Gray2,
          '&:hover': {
            boxShadow: 'none'
          },
          '&:focus': {
            borderColor: Colors.Gray2
          }
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          padding: '0 6px'
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: Colors.Gray6,
          padding: 0
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          padding: 6
        }),
        option: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: '#fff',
          color: '#333',
          '&:hover': {
            backgroundColor: '#f8f9fa'
          }
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: Colors.Gray2,
          margin: 0
        })
      }}
      options={options}
      placeholder={placeholder}
      isSearchable={false}
      // value={options ? options.find((option) => option.value === field.value) : ''}
      onChange={onChange}
      // onBlur={field.onBlur}
      noOptionsMessage={() => 'Nenhum resultado encontrado'}
    />
  );
}
