import React from 'react';
import { useTable } from 'react-table';
import Skeleton from 'react-loading-skeleton';

export default function DataTableSection({ columns, data, loading = false, hiddenColumns }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    initialState: { hiddenColumns: hiddenColumns ?? [] }
  });
  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="max-w-[100%] w-[100%] font-medium">
        <thead className="font-medium text-left">
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerIndex) => (
                <th
                  key={headerIndex}
                  {...column.getHeaderProps()}
                  className={`border-b border-b-gray-2 p-2 pt-0 md:p-2 ${
                    headerIndex == 0 ? 'pl-0 md:pl-0' : ''
                  }`}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="w-full font-normal text-gray-6 ">
          {loading
            ? [...Array(4)].map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {[...Array(columns.length)].map((cell, cellIndex) => (
                      <td key={cellIndex} className="p-2 md:p-2 pl-0 md:pl-0">
                        <Skeleton height={16} />
                      </td>
                    ))}
                  </tr>
                );
              })
            : rows.map((row, rowIndex, rowArray) => {
                prepareRow(row);
                return (
                  <tr
                    key={rowIndex}
                    {...row.getRowProps()}
                    className={`${
                      rowIndex != rowArray.length - 1 ? 'border-b border-b-gray-2' : ''
                    }`}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          className={`p-2 md:p-2 ${cellIndex == 0 ? 'pl-0 md:pl-0' : ''}`}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {rows.length === 0 && !loading && (
        <div className="text-center text-gray-5 py-2">Nenhum resultado encontrado</div>
      )}
    </div>
  );
}
