import React, { useEffect } from 'react';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { useStore } from '../../../store/useStore';
import { Formik } from 'formik';
import * as Yup from 'yup';

const UpdateGroupModal = ({ isModalOpen, handleModalOpen }) => {
  const isLoading = useStore((state) => state.groups.isLoading);
  const group = useStore((state) => state.groups.group);
  const setGroup = useStore((state) => state.groups.setGroup);
  const updateGroup = useStore((state) => state.groups.updateGroup);

  useEffect(() => {
    return () => {
      setGroup({});
    };
  }, []);

  return (
    <Modal
      title={'Editar informações do grupo'}
      show={isModalOpen}
      onClose={handleModalOpen}
      noDefaultHeight>
      <Formik
        initialValues={{ name: group.name, description: group.description }}
        validationSchema={Yup.object({
          name: Yup.string().required('Campo obrigatório').min(3, 'Nome muito curto'),
          description: Yup.string()
        })}
        onSubmit={(values) => {
          updateGroup(values);
        }}>
        {(formik) => (
          <div className="overflow-y-auto p-5">
            <div className="space-y-4 font-medium text-gray-6">
              <form>
                <div>
                  <label htmlFor="name">
                    Nome <span className="text-red">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="w-full border-2 border-gray-2 py-2 px-3 rounded-[5px] focus:outline-none"
                    {...formik.getFieldProps('name')}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <p className="text-red">{formik.errors.name}</p>
                  ) : null}
                </div>

                <div className="my-4">
                  <label htmlFor="description">Descrição</label>
                  <input
                    type="text"
                    name="description"
                    id="description"
                    className="w-full border-2 border-gray-2 py-2 px-3 rounded-[5px] focus:outline-none"
                    {...formik.getFieldProps('description')}
                  />
                </div>
                <p>
                  <span className="text-red">*</span> Campos de preenchimento obrigatório.
                </p>
              </form>
            </div>

            <div className="flex justify-around mt-5">
              <FilledButton
                className="px-10"
                onClick={handleModalOpen}
                bg={Colors.Gray1}
                color={Colors.black}>
                Cancelar
              </FilledButton>
              <FilledButton
                onClick={() => formik.handleSubmit()}
                loading={isLoading}
                className="px-10"
                bg={Colors.Green}>
                Atualizar grupo
              </FilledButton>
            </div>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateGroupModal;
