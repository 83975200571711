import React from 'react';

export default function EyeOpenedIcon({ width = 22, height = 16, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 7C20 7 15.9706 13 11 13C6.02944 13 2 7 2 7C2 7 6.02944 1 11 1C15.9706 1 20 7 20 7Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="11" cy="7" r="3" stroke={color} strokeWidth="2" />
    </svg>
  );
}
