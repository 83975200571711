import produce from 'immer';

export const createNavbarSlice = (set) => ({
  navbar: {
    showNavbar: false,
    navbarHovered: false,
    setShowNavbar: (value) => {
      set(
        produce((state) => {
          state.navbar.showNavbar = value;
        })
      );
    },
    setNavbarHovered: (value) => {
      set(
        produce((state) => {
          state.navbar.navbarHovered = value;
        })
      );
    }
  }
});
