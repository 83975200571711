import React, { useEffect } from 'react';
import Modal from '../../../components/FormComponents/Modal/Modal';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import { useStore } from '../../../store/useStore';
import { useNavigate } from 'react-router-dom';

const DeleteAccountModal = ({ isModalOpen, handleModalOpen }) => {
  const navigate = useNavigate();
  const deleteAccount = useStore((state) => state.auth.deleteAccount);
  const isLoading = useStore((state) => state.auth.isLoading);
  const [isMatch, setIsMatch] = React.useState(false);
  const [reasons, setReasons] = React.useState([]);
  const company = useStore((state) => state.company.company);

  const handleDeleteAccount = () => {
    if (!isMatch || reasons.length === 0) return;
    deleteAccount({ reasons }, navigate);
    handleModalOpen();
  };
  const handleTextChange = (e) => {
    if (e.target.value === company.email) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsMatch(false);
      setReasons([]);
    };
  }, []);
  const handleReasons = (e) => {
    if (e.target.checked) {
      setReasons([...reasons, e.target.id]);
    } else {
      setReasons(reasons.filter((reason) => reason !== e.target.id));
    }
  };
  return (
    <Modal
      title={'Tem certeza que gostaria de apagar sua conta?'}
      show={isModalOpen}
      onClose={handleModalOpen}
      noDefaultHeight>
      <div className="overflow-y-auto p-5">
        <div className="space-y-4 font-medium text-gray-6">
          <p>
            Antes de proceder, por favor certifique-se de rever os{' '}
            <span className={'text-orange'}>Termos de Serviço</span> relacionados a ação de apagar
            conta.
          </p>
          <p>
            Você pode desfazer essa ação no período máximo de{' '}
            <span className={'text-orange'}>30 dias após a exclusão da conta.</span>
          </p>
          <p>Após o período de 30 dias, a conta (cobranças, clientes, etc) será excluída.</p>
          <div>
            <p>Por que gostaria de apagar sua conta?</p>
            <div className="ml-5">
              <div className="flex items-center gap-3">
                <input type="checkbox" onClick={handleReasons} id="NEEDS" />
                <p>A plataforma não atende as minhas necessidades</p>
              </div>
              <div className="flex items-center gap-3">
                <input type="checkbox" onClick={handleReasons} id="EXPENSIVE" />
                <p>Os créditos estão muito caros</p>
              </div>
              <div className="flex items-center gap-3">
                <input type="checkbox" onClick={handleReasons} id="ERRORS" />
                <p>Erros na aplicação</p>
              </div>
              <div className="flex items-center gap-3">
                <input type="checkbox" onClick={handleReasons} id="OTHER" />
                <p>Outro</p>
              </div>
              <p
                className={`text-red ${
                  isMatch && reasons.length === 0 ? 'opacity-100' : 'opacity-0'
                } transition-opacity duration-200`}>
                * Este campo é obrigatório
              </p>
            </div>
          </div>
          <div>
            <p>Digite seu email para confirmar que você deseja apagar sua conta.</p>
            <input
              type="text"
              placeholder="exemplo@email.com"
              className={`w-full border-2 border-gray-2 ${
                isMatch ? 'focus:border-green' : 'focus:border-red'
              } py-2 px-3 rounded-[5px] focus:outline-none transition-colors duration-300`}
              onChange={handleTextChange}
            />
          </div>
        </div>

        <div className="flex justify-around mt-5">
          <FilledButton
            className="px-10"
            onClick={handleModalOpen}
            bg={Colors.Gray1}
            color={Colors.black}>
            Cancelar
          </FilledButton>
          <FilledButton
            onClick={handleDeleteAccount}
            loading={isLoading}
            className="px-10"
            bg={Colors.Red}>
            Apagar
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
