import React from 'react';
export default function AttachmentIcon({ width = 11, height = 22, color = '#fff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.19711 7.5446L7.3974 16.0769C7.42127 17.1125 6.59893 17.9744 5.56335 17.9991C4.52732 18.0235 3.66499 17.2007 3.64067 16.1647L3.44351 4.50076C3.40292 2.77139 4.77089 1.33765 6.50026 1.29706C8.22918 1.25603 9.66382 2.62486 9.70397 4.3538L9.89951 15.8924C9.95634 18.3133 8.03928 20.3225 5.62007 20.3776C3.1978 20.4349 1.19039 18.5195 1.13356 16.0986L0.935791 7.69247"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
