import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { Colors } from '../../../utils/colors';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function BorderedAsyncSelect(props) {
  const { field, placeholder, form, label } = props;

  const [debouceTimeout, setDebounceTimeout] = useState(null);

  const filterOptions = (inputValue, newOptions) => {
    return newOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const loadOptions = (inputValue, callback) => {
    clearTimeout(debouceTimeout);
    setDebounceTimeout(
      setTimeout(async () => {
        const newOptions = await props.callback(inputValue);
        callback(() => filterOptions(inputValue, newOptions));
      }, 1000)
    );
  };

  return (
    <div>
      <span className="text-black inline-block font-medium text-sm">{label}</span>
      <AsyncSelect
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%'
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: 'none',
            height: 40,
            borderColor: Colors.Gray2,
            '&:hover': {
              boxShadow: 'none'
            },
            '&:focus': {
              borderColor: Colors.Gray2
            }
          }),
          dropdownIndicator: () => ({
            color: Colors.Orange
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0 3px'
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: Colors.Gray4,
            padding: '0px 3px'
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: '0px 3px'
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#333',
            '&:hover': {
              backgroundColor: '#f8f9fa'
            }
          }),
          indicatorSeparator: () => {}
        }}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        placeholder={placeholder}
        isClearable={props.isClearable}
        name={field.name}
        onChange={
          props.onChange ? props.onChange : (option) => form.setFieldValue(field.name, option.value)
        }
        onBlur={field.onBlur}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        loadingMessage={() => 'Buscando...'}
        {...props}
      />
      <FieldErrorMessage>{props.error}</FieldErrorMessage>
    </div>
  );
}
