import React from 'react';

export default function FileIcon({ width = 24, height = 17, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1667 1H3C1.89543 1 1 1.89543 1 3V14C1 15.1046 1.89543 16 3 16H10.6667C11.7712 16 12.6667 15.1046 12.6667 14V3.91667M10.1667 1L12.6667 3.91667M10.1667 1L9.63399 1.42614C8.78622 2.10436 8.66913 3.3501 9.37568 4.17441V4.17441C10.0492 4.96014 11.2243 5.07059 12.0324 4.42411L12.6667 3.91667"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
