import React, { useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../assets/imgs';
import { useStore } from '../../../store/useStore';

const AdvancedFiltering = () => {
  const currentPage = useStore((state) => state.billings.billingsCurrentPage);
  const totalPages = useStore((state) => state.billings.billingsTotalPages);
  const totalItems = useStore((state) => state.billings.billingsTotalItems);
  const changePage = useStore((state) => state.billings.changeBillingPage);
  const filters = useStore((state) => state.billings.filters);
  const setFilter = useStore((state) => state.billings.setFilter);

  useEffect(() => {
    changePage(1, false);
  }, []);

  const nextPage = () => {
    if (currentPage === totalPages || totalPages === 0) return;
    changePage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage <= 1) return;
    changePage(currentPage - 1);
  };

  const setLimit = (limit) => {
    changePage(1, false);
    setFilter('limit', limit);
  };

  const totalItemsText = React.useMemo(() => {
    const initial = currentPage * parseInt(filters.limit) - parseInt(filters.limit) + 1;
    const last = initial + parseInt(filters.limit) - 1;
    return `${initial > totalItems ? 0 : initial} - ${
      last > totalItems ? totalItems : last
    } de ${totalItems}`;
  }, [filters.limit, totalItems, currentPage]);

  return (
    <div className="flex gap-3 items-end md:items-center flex-col md:flex-row">
      <div className="space-x-2">
        <span className="text-gray-600 tracking-tighter">Ir para: </span>
        <select
          className="border border-gray-400/40 rounded bg-transparent py-2 px-4 outline-none"
          value={currentPage}
          onChange={(e) => changePage(e.target.value)}>
          {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
            <option value={page} key={page}>
              {page}
            </option>
          ))}
        </select>
      </div>
      <div className="space-x-2">
        <span className="text-gray-600 tracking-tighter">Itens por página: </span>
        <select
          onChange={(e) => setLimit(e.target.value)}
          className="border border-gray-400/40 rounded bg-transparent py-2 px-4 outline-none">
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
        </select>
      </div>
      <p className="text-gray-600 tracking-tighter px-3">{totalItemsText}</p>
      <div className="flex gap-4">
        <button
          onClick={previousPage}
          className="hover:opacity-50 duration-200 transition-opacity cursor-pointer">
          <ChevronLeftIcon color="#000" height={17} />
        </button>
        <button
          onClick={nextPage}
          className="hover:opacity-50 duration-200 transition-opacity cursor-pointer">
          <ChevronRightIcon color="#000" height={17} />
        </button>
      </div>
    </div>
  );
};

export default AdvancedFiltering;
