import { api, wallets } from '../api/api';

const creditService = {
  getCredits: async (filters) => {
    try {
      const response = await api.get('/credit', { params: filters });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar créditos');
    }
  },
  addCreditsWithCreditCard: async ({ pkg_amount, whatsApp_amount }) => {
    try {
      const response = await api.get('/add-credit', { params: { pkg_amount, whatsApp_amount } });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao solicitar créditos');
    }
  },
  addCreditsWithMpesa: async ({ wallet_id, amount, phone }) => {
    try {
      const response = await wallets.post('/wallet/deposit', {
        wallet_id,
        amount,
        method: {
          name: 'mpesa',
          phone_number: phone
        }
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao solicitar créditos');
    }
  },
  listTransactions: async (walletId, { status, date, cursor }) => {
    try {
      const response = await wallets.get(`/txr/list/${walletId}`, {
        params: {
          page_size: 20,
          status,
          ...date,
          cursor
        }
      });
      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar transações');
    }
  }
};

export default creditService;
