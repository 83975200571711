import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import * as Icons from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import ActiveMarker from './ActiveMarker';
import { useStore } from '../../../store/useStore';
import useResponsivity from '../../../hooks/useResponsivity';
import useNavbarHovered from '../../../hooks/useNavbarHovered';

// eslint-disable-next-line no-unused-vars
export default function NavbarLinkGroup({
  title,
  to,
  icon,
  children,
  showLinksTransitionClass,
  hideLinksTransitionClass,
  extraPathsActive = []
}) {
  const [showLinks, setShowLinks] = useState(false);
  const childrenRef = useRef();
  const location = useLocation();
  const isActiveLink = () =>
    extraPathsActive.length > 0
      ? [...extraPathsActive, to].some((path) => location.pathname.includes(path))
      : location.pathname.includes(to);
  const { isMobile } = useResponsivity();
  const setShowNavbar = useStore((state) => state.navbar.setShowNavbar);
  const isNavbarHovered = useNavbarHovered();
  const setNavbarHovered = useStore((state) => state.navbar.setNavbarHovered);

  const onLinkClick = () => {
    if (isMobile) setShowNavbar(false);
    setNavbarHovered(false);
  };

  useEffect(() => {
    if (isNavbarHovered) setShowLinks(false);
  }, [isNavbarHovered]);

  useEffect(() => {
    if (!isNavbarHovered) setShowLinks(isActiveLink());
  }, [location.pathname, isNavbarHovered]);

  const toggleLinks = (e) => {
    e.stopPropagation();
    setShowLinks(!showLinks);
  };

  const ChevronIcon = (props) => {
    return showLinks ? <Icons.ChevronUpIcon {...props} /> : <Icons.ChevronDownIcon {...props} />;
  };

  const IconComponent = icon;

  return (
    <div className="bg-black overflow-hidden relative z-10">
      <div className="flex items-center relative z-10 bg-black">
        <NavLink
          to={to}
          onClick={onLinkClick}
          className={() => (isActiveLink() ? 'text-white' : 'text-gray-5')}>
          {isActiveLink() ? <ActiveMarker /> : null}
          <div className="pl-5 pb-3 pt-2 pr-2 flex gap-5 items-center md:overflow-hidden whitespace-nowrap">
            <IconComponent color={isActiveLink() ? Colors.White : Colors.Gray5} />
            <span>{title}</span>
          </div>
        </NavLink>
        <div onClick={toggleLinks} className="p-2 cursor-pointer">
          <ChevronIcon width={10} color={isActiveLink() ? Colors.White : Colors.Gray5} />
        </div>
      </div>
      <div
        className={` ${
          showLinks ? showLinksTransitionClass : hideLinksTransitionClass
        }  transition-all ease-in-out duration-500 ml-12 border-l overflow-hidden ${
          isActiveLink() ? 'border-white' : 'border-gray-5'
        }  `}>
        <div ref={childrenRef}>{children}</div>
      </div>
    </div>
  );
}
