import React from 'react';
import { Colors } from '../../utils/colors';

export default function DotsIcon({ width = 13, height = 26, color = Colors.black }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00001 12.9999C5.00001 14.1044 5.89544 14.9999 7 14.9999C8.10457 14.9999 9 14.1044 9 12.9999C9 11.8953 8.10457 10.9999 7 10.9999C5.89544 10.9999 5.00001 11.8953 5.00001 12.9999Z"
        fill={color}
      />
      <path
        d="M5.00001 5C5.00001 6.10457 5.89544 7 7 7C8.10457 7 9 6.10457 9 5C9 3.89543 8.10457 3 7 3C5.89544 3 5.00001 3.89543 5.00001 5Z"
        fill={color}
      />
      <path
        d="M5.00001 21C5.00001 22.1046 5.89544 23 7 23C8.10457 23 9 22.1046 9 21C9 19.8954 8.10457 19 7 19C5.89544 19 5.00001 19.8954 5.00001 21Z"
        fill={color}
      />
    </svg>
  );
}
