import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import React, { useEffect } from 'react';
import Modal from '../../../components/FormComponents/Modal/Modal';
import * as Yup from 'yup';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import SelectInput from '../../../components/FormComponents/SelectFields/SelectInput';
import { CloseIcon } from '../../../assets/imgs';
import Checkbox from '../../../components/FormComponents/Checkbox/Checkbox';
import FieldErrorMessage from '../../../components/FormComponents/FieldErrorMessage/FieldErrorMessage';
import MaskedInput from '../../../components/FormComponents/Inputs/MaskedInput';
import Input from '../../../components/FormComponents/Inputs/Input';

export default function AddReceptionModal() {
  const getBanksAvaiable = useStore((state) => state.staticData.getBanksAvaiable);
  const getWalletsAvaiable = useStore((state) => state.staticData.getWalletsAvaiable);
  const banksAvaiable = useStore((state) => state.staticData.banksAvaiable);
  const walletsAvaiable = useStore((state) => state.staticData.walletsAvaiable);
  const showAddReceptionModal = useStore((state) => state.company.showAddReceptionModal);
  const toogleReceptionModal = useStore((state) => state.company.toogleReceptionModal);
  const paymentMethods = useStore((state) => state.company.paymentMethods);
  const updateReceivePaymentMethods = useStore(
    (state) => state.company.updateReceivePaymentMethods
  );

  const errorMessage = (formik, { topic, index, field }) => {
    return formik.touched[topic] &&
      formik.touched[topic][index] &&
      formik.errors[topic] &&
      formik.errors[topic][index] &&
      formik.touched[topic][index][field] &&
      formik.errors[topic][index][field] ? (
      <div>{formik.errors[topic][index][field]}</div>
    ) : (
      ''
    );
  };

  const handleChangeReceivePaymentMethods = (e, formik, dependentArrayFieldName, initialArray) => {
    if (formik.values[e.target.name].includes(e.target.value)) {
      formik.setFieldValue(dependentArrayFieldName, []);
      formik.setFieldValue(
        e.target.name,
        formik.values[e.target.name].filter((v) => v !== e.target.value)
      );
    } else {
      formik.setFieldValue(e.target.name, [
        ...new Set([...formik.values[e.target.name], e.target.value])
      ]);
      formik.setFieldValue(dependentArrayFieldName, [initialArray]);
    }
  };

  const initialBank = { name: '', accountNumber: '', nib: '' };
  const initialMobileWallet = { wallet: '', number: '' };

  useEffect(() => {
    getBanksAvaiable();
    getWalletsAvaiable();
  }, []);

  return (
    <Modal title="Recebimento" onClose={toogleReceptionModal} show={showAddReceptionModal}>
      <Formik
        initialValues={{
          receivePaymentMethods: ['bank_deposit', 'mobile_wallet', 'numberary'],
          banks: [initialBank],
          mobileWallets: [initialMobileWallet]
        }}
        validationSchema={Yup.object({
          receivePaymentMethods: Yup.array().min(1, 'Selecione pelo menos uma forma'),
          banks: Yup.array(
            Yup.object({
              name: Yup.string().required('Campo obrigatório'),
              accountNumber: Yup.number()
                .typeError('O número da conta contém somente números')
                .positive('O número da conta contém somente número positivos')
                .integer('O número da conta contém somente números inteiros')
                .required('Campo obrigatório'),
              nib: Yup.string()
                .matches(
                  /^\d\d\d\d \d\d\d\d \d\d\d\d\d\d\d\d\d\d\d \d\d$/,
                  'O NIB deve ter o formato XXXX XXXX XXXXXXXXXXX XX'
                )
                .required('Campo obrigatório')
            })
          ),
          mobileWallets: Yup.array(
            Yup.object({
              wallet: Yup.string().required('Campo obrigatório'),
              number: Yup.string()
                .required('Campo obrigatório')
                .matches(
                  /^\+258 (86|87|84|82|83|85) \d\d\d \d\d\d\d$/,
                  'O telefone deve ter o formato +258 XX XXX XXXX'
                )
                .required('Campo obrigatório')
            })
          )
        })}
        onSubmit={(values) => {
          updateReceivePaymentMethods(values);
        }}>
        {(formik) => {
          useEffect(() => {
            const receivePaymentMethods = [
              ...(paymentMethods.company_bank.length > 0 ? ['bank_deposit'] : []),
              ...(paymentMethods.company_wallet.length > 0 ? ['mobile_wallet'] : []),
              ...(paymentMethods.numerary ? ['numberary'] : [])
            ];
            const banks = paymentMethods.company_bank.map((bank) => {
              return {
                external_id: bank.id,
                id: bank.bank.id,
                name: bank.bank.id,
                accountNumber: bank.account_number,
                nib: bank.nib
              };
            });
            const mobileWallets = paymentMethods.company_wallet.map((wallet) => {
              return {
                external_id: wallet.id,
                id: wallet.wallet.id,
                wallet: wallet.wallet.id,
                number: wallet.account_number
              };
            });
            formik.setValues({ receivePaymentMethods, banks, mobileWallets });
          }, [paymentMethods]);

          return (
            <form
              onSubmit={formik.handleSubmit}
              className={`grid h-full overflow-hidden ${
                showAddReceptionModal ? 'block' : 'hidden'
              }`}>
              <div className="px-6 md:px-12 h-full overflow-y-auto py-5 flex flex-col gap-5">
                <div>
                  <div className="text-sm font-medium mb-3">
                    Como deseja receber dos seus clientes?
                    <span className="text-gray-3"> (selecione uma ou mais)</span>
                  </div>
                  <div className="flex gap-5">
                    <Checkbox
                      checked={formik.values.receivePaymentMethods.includes('bank_deposit')}
                      {...formik.getFieldProps(`receivePaymentMethods`)}
                      onChange={(e) =>
                        handleChangeReceivePaymentMethods(e, formik, 'banks', initialBank)
                      }
                      value="bank_deposit"
                      label="Depósito bancário"
                    />
                    <Checkbox
                      checked={formik.values.receivePaymentMethods.includes('mobile_wallet')}
                      {...formik.getFieldProps(`receivePaymentMethods`)}
                      onChange={(e) =>
                        handleChangeReceivePaymentMethods(
                          e,
                          formik,
                          'mobileWallets',
                          initialMobileWallet
                        )
                      }
                      value="mobile_wallet"
                      label="Carteira móvel"
                    />
                    <Checkbox
                      checked={formik.values.receivePaymentMethods.includes('numberary')}
                      {...formik.getFieldProps(`receivePaymentMethods`)}
                      value="numberary"
                      label="Numerário"
                    />
                  </div>
                  <ErrorMessage
                    name="receivePaymentMethods"
                    render={(msg) => <FieldErrorMessage>{msg}</FieldErrorMessage>}
                  />
                </div>
                {formik.values.banks.length > 0 ? (
                  <>
                    <div className="text-lg font-medium">Banco(s)</div>
                    <FieldArray name="banks">
                      {({ push, remove }) => (
                        <div>
                          {formik.values.banks.map((_, index) => (
                            <div
                              key={'bank-' + index}
                              className="border p-3 mb-5 border-gray-2 relative">
                              {index > 0 ? (
                                <div
                                  className="absolute right-2 cursor-pointer"
                                  onClick={() => remove(index)}>
                                  <CloseIcon width={15} />
                                </div>
                              ) : null}
                              <div className="flex flex-col gap-5">
                                <Field
                                  name={`banks[${index}].name`}
                                  component={SelectInput}
                                  menuPosition="fixed"
                                  label="Banco"
                                  options={banksAvaiable}
                                  placeholder="Digite o nome da instituição"
                                  error={errorMessage(formik, {
                                    topic: 'banks',
                                    index,
                                    field: 'name'
                                  })}
                                />
                                <Input
                                  placeholder="Digite a conta bancária"
                                  type="text"
                                  label="Número da conta bancária"
                                  error={errorMessage(formik, {
                                    topic: 'banks',
                                    index,
                                    field: 'accountNumber'
                                  })}
                                  {...formik.getFieldProps(`banks[${index}].accountNumber`)}
                                />
                                <MaskedInput
                                  placeholder="Digite o NIB"
                                  type="text"
                                  label="NIB"
                                  mask="9999 9999 99999999999 99"
                                  maskPlaceholder={null}
                                  error={errorMessage(formik, {
                                    topic: 'banks',
                                    index,
                                    field: 'nib'
                                  })}
                                  {...formik.getFieldProps(`banks[${index}].nib`)}
                                />
                              </div>
                            </div>
                          ))}
                          <div className="text-center">
                            <button
                              className="underline underline-offset-4 text-orange"
                              onClick={() => push(initialBank)}>
                              Adicionar outro banco
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </>
                ) : null}

                {formik.values.mobileWallets.length > 0 ? (
                  <>
                    <div className="text-lg font-medium">Cateira(s) Móvel(is)</div>
                    <FieldArray name="mobileWallets">
                      {({ push, remove }) => (
                        <div>
                          {formik.values.mobileWallets.map((_, index) => (
                            <div
                              key={'mobileWallet-' + index}
                              className="border p-3 mb-5 border-gray-2 relative">
                              {index > 0 ? (
                                <div
                                  className="absolute right-2 cursor-pointer"
                                  onClick={() => remove(index)}>
                                  <CloseIcon width={15} />
                                </div>
                              ) : null}

                              <div className="flex gap-5">
                                <div className="flex-1">
                                  <Field
                                    name={`mobileWallets[${index}].wallet`}
                                    component={SelectInput}
                                    menuPosition="fixed"
                                    label="Carteira"
                                    options={walletsAvaiable}
                                    placeholder="Selecione a carteira"
                                    error={errorMessage(formik, {
                                      topic: 'mobileWallets',
                                      index,
                                      field: 'wallet'
                                    })}
                                  />
                                </div>
                                <div className="flex-1">
                                  <MaskedInput
                                    placeholder="Digite o número"
                                    label="Número"
                                    mask="+258 99 999 9999"
                                    alwaysShowMask={true}
                                    maskPlaceholder={null}
                                    error={errorMessage(formik, {
                                      topic: 'mobileWallets',
                                      index,
                                      field: 'number'
                                    })}
                                    {...formik.getFieldProps(`mobileWallets[${index}].number`)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="text-center">
                            <button
                              className="underline underline-offset-4 text-orange"
                              onClick={() => push(initialMobileWallet)}>
                              Adicionar outra carteira
                            </button>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </>
                ) : null}
              </div>
              <div className="flex justify-between bg-white sticky bottom-0 p-5 self-end">
                <FilledButton
                  onClick={toogleReceptionModal}
                  type="button"
                  bg={Colors.Gray1}
                  color={Colors.Orange}>
                  Cancelar
                </FilledButton>
                <FilledButton type="submit">Salvar</FilledButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
