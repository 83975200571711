import { useEffect } from 'react';
import { useStore } from '../store/useStore';

export default function useStepState(initialStep = 1) {
  const step = useStore((state) => state.stepModal.step);
  const setStep = useStore((state) => state.stepModal.setStep);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const previousStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  return [step, previousStep, nextStep, setStep];
}
