import { api, authentication } from '../api/api';

const customerService = {
  createCustomer: async (customerData) => {
    try {
      const response = await authentication.post('/customer/create', customerData);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao criar cliente');
    }
  },
  deleteCustomers: async (customerIds) => {
    try {
      const response = await api.post('/customer/delete', { customer_ids: customerIds });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao apagar cliente');
    }
  },
  updateCustomer: async (id, customerData) => {
    try {
      const response = await authentication.put(`/customer`, customerData);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao atualizar cliente');
    }
  },
  getCustomers: async (filters) => {
    try {
      const response = await authentication.get('/customers', {
        params: { ...filters, pagination: true }
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar clientes');
    }
  },
  getCustomer: async (id) => {
    try {
      const response = await authentication.get(`/customer/${id}`);

      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados do cliente');
    }
  },
  getCustomerOptions: async (filters) => {
    try {
      const response = await api.get('/customers', {
        params: { ...filters, pagination: false }
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar clientes');
    }
  },
  getCustomerSignatures: async (id) => {
    try {
      const response = await api.get(`/customer/${id}/signatures`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar assinaturas do cliente');
    }
  },
  getCustomerItems: async (id) => {
    try {
      const response = await api.get(`/customer/${id}/items`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar items do cliente');
    }
  },
  getCustomerNotifications: async (id) => {
    try {
      const response = await api.get(`/customer/${id}/notifications`);

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar notificações do cliente');
    }
  }
};

export default customerService;
