import React from 'react';
import { useTable } from 'react-table';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import Skeleton from 'react-loading-skeleton';

const isRowValidClick = (cell) => cell.column.id !== 'selection' && cell.column.id !== 'action';

export default function DataTable({
  columns,
  data,
  loading = false,
  noTableSelection,
  hiddenColumns,
  onSelectRow,
  onClickRow,
  selectedRows
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: hiddenColumns ?? [] }
    },
    (hooks) => {
      !noTableSelection &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ rows }) => (
              <div>
                <IndeterminateCheckbox
                  onClick={(e) => {
                    onSelectRow(
                      rows.map((row) => row.original),
                      e.target.checked
                    );
                  }}
                  id="all"
                  checked={
                    rows.length > 0 &&
                    rows.every((r) => selectedRows.some((sr) => sr.id === r.original.id))
                  }
                  // {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  onClick={(e) => onSelectRow([row.original], e.target.checked)}
                  id={row.original.id}
                  checked={selectedRows.some((sr) => sr.id === row.original.id)}
                />
              </div>
            )
          },
          ...columns
        ]);
    }
  );
  return (
    <div className="overflow-x-auto">
      <table {...getTableProps()} className="max-w-[100%] w-[100%] font-medium">
        <thead className="text-gray-5 text-left text-lg">
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr key={headerGroupIndex} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerIndex) => (
                <th
                  key={headerIndex}
                  {...column.getHeaderProps()}
                  className={`p-2 pt-0 md:p-5 md:pt-0 ${noTableSelection ? 'pl-0 md:pl-0' : ''}`}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="w-full text-gray-6 ">
          {loading
            ? [...Array(10)].map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {[
                      ...Array(columns.length - hiddenColumns.length + (noTableSelection ? 0 : 1))
                    ].map((cell, cellIndex) => (
                      <td key={cellIndex} className="p-2 md:p-5 pl-0 md:pl-0">
                        {!noTableSelection && cellIndex == 0 ? (
                          <Skeleton className="ml-2 md:ml-5" width={15} />
                        ) : (
                          <Skeleton height={20} />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            : rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    key={rowIndex}
                    {...row.getRowProps()}
                    className="border-b border-b-gray-2 hover:cursor-pointer hover:bg-gray-1">
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          onClick={isRowValidClick(cell) ? () => onClickRow(row) : null}
                          className={`p-2 md:p-5 ${noTableSelection ? 'pl-0 md:pl-0' : ''}`}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {rows.length === 0 && !loading && (
        <div className="text-center text-gray-5">Nenhum resultado encontrado</div>
      )}
    </div>
  );
}
