import React from 'react';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function Input(props) {
  const {
    label,
    type,
    preIcon,
    endIcon,
    endIconComponent,
    blackTheme,
    error,
    onEndIconComponentClick
  } = props;

  return (
    <div>
      <span className={`text-${blackTheme ? 'white' : 'black'} font-medium text-base`}>
        {label}
      </span>
      <div className="relative">
        <label className={`text-${blackTheme ? 'white' : 'black'} relative text-gray-400 block`}>
          {preIcon ? (
            <img
              className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 "
              src={preIcon}
            />
          ) : null}
          <input
            {...props}
            type={type}
            className={`${preIcon ? 'pl-10' : ''} ${
              endIcon || endIconComponent ? 'pr-10' : ''
            } form-input border-b bg-${blackTheme ? 'black' : 'white'} border-b-${
              blackTheme ? 'white' : 'black'
            } py-2 4 placeholder-gray-3 appearance-none w-full block focus:outline-none ${
              blackTheme ? 'text-white' : 'text-black'
            }`}
          />
        </label>
        <div
          onClick={onEndIconComponentClick}
          className="cursor-pointer w-6 h-6 absolute top-1/2 right-0 transform -translate-y-1/2 ">
          {endIconComponent ? endIconComponent : null}
          {endIcon ? <img src={endIcon} /> : null}
        </div>
      </div>
      <FieldErrorMessage>{error}</FieldErrorMessage>
    </div>
  );
}
