import React, { useEffect, useState } from 'react';
import { useStore } from '../../../store/useStore';
import { formatDate } from '../../../utils/dateHelpers';
import FilledButton from '../../FormComponents/Buttons/FilledButton';
import BorderlessStaticText from '../../FormComponents/Inputs/BorderlessStaticText';
import LabelWrapper from '../../FormComponents/LabelWrapper/LabelWrapper';
import { BILLING_INTERVAL_LABELS, SIGNATURE_LABELS } from '../../../utils/constants';
import { Colors } from '../../../utils/colors';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import companyService from '../../../services/companyService';
import BorderlessSelect from '../../FormComponents/SelectFields/BorderlessSelect';

function BillingResume() {
  const createBillingData = useStore((state) => state.billings.createBillingData);

  const allDueDateNotificationIncludes = (dueDateNotificationType) => {
    return (
      createBillingData.daysBeforeDueDateNotifications?.includes(dueDateNotificationType) ||
      createBillingData.dueDateNotifications?.includes(dueDateNotificationType) ||
      createBillingData.daysAfterDueDateNotifications?.includes(dueDateNotificationType)
    );
  };

  const sendMethods = (count = false) => {
    if (!count) {
      const sendMethodsText = [];
      if (allDueDateNotificationIncludes('whatsapp')) {
        sendMethodsText.push('Whatsapp');
      }
      if (allDueDateNotificationIncludes('sms')) {
        sendMethodsText.push('SMS');
      }
      if (allDueDateNotificationIncludes('email')) {
        sendMethodsText.push('Email');
      }
      return sendMethodsText.join(' / ');
    } else {
      let occurs = 0;
      createBillingData.daysBeforeDueDateNotifications?.includes('whatsapp') && occurs++;
      createBillingData.dueDateNotifications?.includes('whatsapp') && occurs++;
      createBillingData.daysAfterDueDateNotifications?.includes('whatsapp') && occurs++;
      return occurs;
    }
  };

  return (
    <>
      <div className="font-medium text-lg">Quem você irá cobrar?</div>
      <div>
        <LabelWrapper label="Tipo de cobrança">
          <BorderlessStaticText borderless>
            {SIGNATURE_LABELS[createBillingData.paymentType]}
          </BorderlessStaticText>
        </LabelWrapper>
        <LabelWrapper label="Valor da cobrança">
          <BorderlessStaticText borderless>
            MZN {createBillingData.billingValue}
            {createBillingData.paymentType === 'signature'
              ? ` / ${BILLING_INTERVAL_LABELS[createBillingData.billingInterval]}`
              : ''}
          </BorderlessStaticText>
        </LabelWrapper>
        {createBillingData.paymentType === 'in_cash_or_installments' ? (
          <>
            <LabelWrapper label="Número de parcelas">
              <BorderlessStaticText borderless>
                {createBillingData.numberOfInstallments}
              </BorderlessStaticText>
            </LabelWrapper>
            <LabelWrapper label="Valor da parcela">
              <BorderlessStaticText borderless>
                MZN {createBillingData.installmentValue}
              </BorderlessStaticText>
            </LabelWrapper>
          </>
        ) : null}
        <LabelWrapper label="Vencimento da 1ª parcela">
          <BorderlessStaticText borderless>
            {formatDate(new Date(createBillingData.dueDate))}
          </BorderlessStaticText>
        </LabelWrapper>
        <LabelWrapper label="Método de envio">
          <BorderlessStaticText borderless>{sendMethods()}</BorderlessStaticText>
        </LabelWrapper>
        <LabelWrapper label="Nome do cliente" borderBottom>
          <BorderlessStaticText borderless>{createBillingData.customerName}</BorderlessStaticText>
        </LabelWrapper>
      </div>
      <div className="font-medium text-lg">Custos de notificações</div>
      <div>
        {(sendMethods().includes('SMS') || sendMethods().includes('Email')) && (
          <LabelWrapper
            // type="info"
            // text={'texto de aviso'}
            label="Pacote (Email / SMS)"
            borderBottom>
            <BorderlessStaticText borderless>-10 MZN / Por parcela</BorderlessStaticText>
          </LabelWrapper>
        )}
        {sendMethods().includes('Whatsapp') && (
          <LabelWrapper label="Whatsapp" borderBottom>
            <BorderlessStaticText borderless>-5 MZN / Por notificação</BorderlessStaticText>
          </LabelWrapper>
        )}
      </div>
    </>
  );
}

export default function Step4({ show, onPrevious }) {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const createBillingData = useStore((state) => state.billings.createBillingData);
  const isCreateBillingLoading = useStore((state) => state.billings.isCreateBillingLoading);
  const createBilling = useStore((state) => state.billings.createBilling);
  const updateCreateBillingData = useStore((state) => state.billings.updateCreateBillingData);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  useEffect(() => {
    (async () => {
      let formatted = [];
      const paymentMethods = await companyService.getPaymentMethods();
      paymentMethods.numerary && formatted.push({ value: 'numerary', label: 'Numerario' });
      paymentMethods?.company_bank.forEach((bank) => {
        formatted.push({
          value: bank.bank.id,
          label: `${bank.bank.name} - ${bank.account_number}`
        });
      });
      paymentMethods?.company_wallet.forEach((wallet) => {
        formatted.push({
          value: wallet.wallet.id,
          label: `${wallet.wallet.name} (${wallet.account_number})`
        });
      });
      setPaymentMethods(formatted);
    })();
  }, []);

  return createBillingData.paymentType === 'signature' ? (
    <Formik
      initialValues={{
        paidWhere: ''
      }}
      validationSchema={Yup.object({
        paidWhere: Yup.string().required('Campo obrigatório')
      })}
      onSubmit={(values) => {
        updateCreateBillingData(values);
        createBilling();
      }}>
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
          <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
            <div className="font-medium text-lg">Como foi paga?</div>
            <LabelWrapper label="Forma de pagamento" borderBottom>
              <BorderlessStaticText borderless>
                <Field
                  name={`paidWhere`}
                  component={BorderlessSelect}
                  options={paymentMethods}
                  menuPosition="fixed"
                  label="Método de pagamento"
                  placeholder="Selecione um método de pagamento"
                  onChange={(option) => {
                    formik.setFieldValue('paidWhere', option.label);
                  }}
                  error={errorMessage(formik, 'paidWhere')}
                />
              </BorderlessStaticText>
            </LabelWrapper>
            <BillingResume />
          </div>
          <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
            <FilledButton onClick={onPrevious} bg={Colors.Gray1} color={Colors.Orange}>
              Voltar
            </FilledButton>
            <FilledButton type="submit" loading={isCreateBillingLoading}>
              Criar
            </FilledButton>
          </div>
        </form>
      )}
    </Formik>
  ) : (
    <div className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
      <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
        <BillingResume />
      </div>
      <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
        <FilledButton onClick={onPrevious} bg={Colors.Gray1} color={Colors.Orange}>
          Voltar
        </FilledButton>
        <FilledButton type="submit" loading={isCreateBillingLoading} onClick={createBilling}>
          Criar
        </FilledButton>
      </div>
    </div>
  );
}
