import React from 'react';

export default function BillingIcon({ width = 20, height = 15, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 4V12C19 13.1046 18.1046 14 17 14H4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10.8398 4.28571C10.4969 3.86759 9.96614 3.65394 9.04283 3.64324M9.04283 3.64324C7.89383 3.62992 7.48401 3.7832 7.21398 4.22222C6.68677 5.07937 7.07466 5.77143 9.04283 6C9.74575 6 11.5428 6.28572 10.8399 7.71429C10.4825 8.05625 9.93337 8.5357 9.04283 8.54422M9.04283 3.64324V2V1M9.04283 8.54422C8.57367 8.54871 7.93709 8.33807 7.21398 7.71429M9.04283 8.54422V10V11M9.04283 1H3C1.89543 1 1 1.89543 1 3V9C1 10.1046 1.89543 11 3 11H9.04283M9.04283 1H14C15.1046 1 16 1.89543 16 3V9C16 10.1046 15.1046 11 14 11H9.04283"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
