import React, { forwardRef, useRef } from 'react';

const IndeterminateCheckbox = forwardRef((props, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  return (
    <>
      <input
        type="checkbox"
        id={props.id}
        className="accent-orange inline-block w-4 h-4 hover:cursor-auto"
        ref={resolvedRef}
        checked={props.checked}
        onClick={props.onClick}
      />
    </>
  );
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export default IndeterminateCheckbox;
