import produce from 'immer';
import dataService from '../../services/dataService';
import { formatFormOptions } from '../../services/serviceFormatters';

export const staticDataSlice = (set) => ({
  staticData: {
    banksAvaiable: [],
    walletsAvaiable: [],
    citiesAvaiable: [],
    regionsAvaiable: [],
    getBanksAvaiable: () => {
      dataService
        .getBanks()
        .then((banks) => {
          set(
            produce((state) => {
              state.staticData.banksAvaiable = formatFormOptions(banks);
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.staticData.banksAvaiable = [];
            })
          );
        });
    },
    getWalletsAvaiable: () => {
      dataService
        .getWallets()
        .then((wallets) => {
          set(
            produce((state) => {
              state.staticData.walletsAvaiable = formatFormOptions(wallets);
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.staticData.walletsAvaiable = [];
            })
          );
        });
    },
    getCitiesAvaiable: (provinceId) => {
      dataService
        .getCities(provinceId)
        .then((cities) => {
          set(
            produce((state) => {
              state.staticData.citiesAvaiable = formatFormOptions(cities);
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.staticData.citiesAvaiable = [];
            })
          );
        });
    },
    getCitiesAvaiableAsync: (provinceId) => {
      return new Promise((resolve, reject) => {
        dataService
          .getCities(provinceId)
          .then((cities) => {
            set(
              produce((state) => {
                state.staticData.citiesAvaiable = formatFormOptions(cities);
              })
            );
            resolve();
          })
          .catch(() => {
            set(
              produce((state) => {
                state.staticData.citiesAvaiable = [];
              })
            );
            reject();
          });
      });
    },
    getRegionsAvaiable: () => {
      dataService
        .getRegions()
        .then((regions) => {
          set(
            produce((state) => {
              state.staticData.regionsAvaiable = formatFormOptions(regions);
              state.staticData.citiesAvaiable = [];
            })
          );
        })
        .catch(() => {
          set(
            produce((state) => {
              state.staticData.regionsAvaiable = [];
              state.staticData.citiesAvaiable = [];
            })
          );
        });
    }
  }
});
