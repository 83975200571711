import moment from 'moment/moment';

export const MONTH_NAMES = [
  { name: 'Janeiro', abrev: 'Jan' },
  { name: 'Fevereiro', abrev: 'Fev' },
  { name: 'Março', abrev: 'Mar' },
  { name: 'Abril', abrev: 'Abr' },
  { name: 'Maio', abrev: 'Mai' },
  { name: 'Junho', abrev: 'Jun' },
  { name: 'Julho', abrev: 'Jul' },
  { name: 'Agosto', abrev: 'Ago' },
  { name: 'Setembro', abrev: 'Set' },
  { name: 'Outubro', abrev: 'Out' },
  { name: 'Novembro', abrev: 'Nov' },
  { name: 'Dezembro', abrev: 'Dez' }
];

export const YEARS = generateBirthYears();
export const MONTHS = generateMonths();
export const DAYS = generateDays();

export function generateBirthYears() {
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = [];

  for (let year = currentYear; year >= startYear; year--) {
    years.push({ label: year.toString(), value: year });
  }

  return years;
}

export function generateMonths() {
  const months = [];

  for (let i = 0; i < 12; i++) {
    months.push({ value: i + 1, label: MONTH_NAMES[i].name });
  }

  return months;
}

export function generateDays() {
  const days = [];

  for (let i = 0; i < 31; i++) {
    days.push({ label: i + 1, value: i + 1 });
  }

  return days;
}

export function formatDateToText(date) {
  const year = date.getFullYear();
  const monthAbrev = MONTH_NAMES[date.getMonth()].abrev;
  const day = date.getDate();

  return `${monthAbrev} ${day},${year}`;
}

export function formatDate(date) {
  return date.toLocaleDateString('pt-MZ');
}

export function formatDateTime(date) {
  return moment(date).format('DD/MM/YYYY à[s] HH:mm[h]');
}

export function distanceBetweenDates(data) {
  const diff = new Date(data) - new Date();
  const dias = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return dias;
}
