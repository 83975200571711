import React from 'react';
import StepProgressBar from '../../StepProgressBar/StepProgressBar';
import Modal from './Modal';

export default function StepModal({
  children,
  title,
  subTitle,
  currentStepNumber,
  steps,
  show,
  onClose,
  stepProgressBarClassName = ''
}) {
  return (
    <Modal title={title} subTitle={subTitle} show={show} onClose={onClose} noPaddingBottom>
      <div className="my-3">
        <StepProgressBar
          currentStepNumber={currentStepNumber}
          steps={steps}
          stepProgressBarClassName={stepProgressBarClassName}
        />
      </div>
      {children}
    </Modal>
  );
}
