import React from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { CloseIcon, RightIcon } from '../../../assets/imgs';
import * as Yup from 'yup';
import { Formik } from 'formik';
import MultipleBillingDetails from './MultipleBillingDetails';

export default function ConfirmMultipleBillingReceptionModal() {
  const confirmMultipleBillings = useStore((state) => state.billings.confirmMultipleBillings);
  const denyMultipleBillings = useStore((state) => state.billings.denyMultipleBillings);
  const isConfirmBillingLoading = useStore((state) => state.billings.isConfirmBillingLoading);
  const isDenyBillingLoading = useStore((state) => state.billings.isDenyBillingLoading);
  const showConfirmMultipleBillingsModal = useStore(
    (state) => state.billings.showConfirmMultipleBillingsModal
  );
  const closeConfirmMultipleBillingsModal = useStore(
    (state) => state.billings.closeConfirmMultipleBillingsModal
  );

  const selectedBillings = useStore((state) => state.billings.selectedBillings);

  const handleConfirmBillings = (confirmationData) => {
    confirmMultipleBillings(confirmationData);
  };

  const handleDenyBilling = (confirmationData) => {
    denyMultipleBillings(confirmationData);
  };

  return (
    <Modal
      title="Confirmar pagamento"
      onClose={closeConfirmMultipleBillingsModal}
      show={showConfirmMultipleBillingsModal}>
      <Formik
        initialValues={{
          paidWhere: '',
          paidWhen: '',
          notifyCustomer: 'no'
        }}
        validationSchema={Yup.object({
          paidWhere: Yup.string().required('Campo obrigatório'),
          paidWhen: Yup.string().required('Campo obrigatório'),
          notifyCustomer: Yup.string().required('Campo obrigatório')
        })}>
        {(formik) => {
          return (
            <div className="grid h-full overflow-hidden">
              <div className="h-full overflow-y-auto p-5">
                <div>
                  Realize a confirmação dos pagamento do seus cliente. Após a confirmação, seu
                  controle financeiro é automaticamente atualizado.
                </div>
                <MultipleBillingDetails billings={selectedBillings} formik={formik} />
              </div>

              <div className="flex sticky bottom-0 p-5 bg-white justify-around self-end">
                <FilledButton
                  onClick={() => handleDenyBilling(formik.values)}
                  loading={isDenyBillingLoading}
                  className="px-5"
                  bg={Colors.Red}>
                  <div className="flex gap-2 items-center">
                    <div>
                      <CloseIcon width={13} color={Colors.White} />
                    </div>
                    <div>Negar</div>
                  </div>
                </FilledButton>
                <FilledButton
                  // onClick={handleConfirmBillings}
                  onClick={() => {
                    formik.validateForm().then((errors) => {
                      if (!errors.paidWhen && !errors.paidWhere) {
                        handleConfirmBillings(formik.values);
                      }
                    });
                  }}
                  loading={isConfirmBillingLoading}
                  className="px-5"
                  bg={Colors.Green}>
                  <div className="flex gap-2 items-center">
                    <div>
                      <RightIcon width={13} color={Colors.White} />
                    </div>
                    <div>Confirmar</div>
                  </div>
                </FilledButton>
              </div>
            </div>
          );
        }}
      </Formik>
    </Modal>
  );
}
