import produce from 'immer';
import { toast } from 'react-toastify';
import customerService from '../../services/customerService';
import {
  formatCustomerSave,
  formatCustomerData,
  formatFormObjectOptions
} from '../../services/serviceFormatters';

export const createCustomersSlice = (set, get) => ({
  customers: {
    showCreateCustomerModal: false,
    showDeleteCustomersModal: false,
    showImportCustomersModal: false,
    showAssociateCustomersToGroupsModal: false,

    isCustomerLoading: false,
    isCreateCustomerLoading: false,
    isUpdatingCustomerLoading: false,
    isCustomersTableLoading: false,
    isCustomerSignaturesLoading: false,
    isCustomerItemsLoading: false,
    isCustomerNotificationsLoading: false,
    isDeletingCustomersLoading: false,
    isAssociatingCustomersToGroupsLoading: false,
    isDissociateCustomersFromGroupsLoading: false,

    customersCurrentPage: 1,
    customersTotalPages: 1,
    signaturesCurrentPage: 1,
    signaturesTotalPages: 1,
    itemsCurrentPage: 1,
    itemsTotalPages: 1,
    notificationsCurrentPage: 1,
    notificationsTotalPages: 1,

    selectedCustomers: [],
    customer: {
      id: null,
      signatures: [],
      items: [],
      notifications: []
    },
    customers: [],
    filters: {
      status: '',
      search: '',
      group: ''
    },
    resetCustomerId: () => {
      //This function is used to reset the customer id when the user navigates away from customer page
      set(
        produce((state) => {
          state.customers.customer.id = null;
        })
      );
    },
    resetCustomersSelected: () => {
      set(
        produce((state) => {
          state.customers.selectedCustomers = [];
        })
      );
    },
    toggleShowAssociateCustomersToGroupsModal: () => {
      set(
        produce((state) => {
          state.customers.showAssociateCustomersToGroupsModal =
            !state.customers.showAssociateCustomersToGroupsModal;
        })
      );
    },
    selectCustomers: (customers, checked) => {
      set(
        produce((state) => {
          const selectedCustomers = get().customers.selectedCustomers;
          let newSelectedCustomers;

          if (checked) {
            // Add the entire array of customers, eliminating duplicates based on unique ID
            const uniqueCustomerIds = new Set([
              ...selectedCustomers.map((c) => c.id),
              ...customers.map((c) => c.id)
            ]);
            newSelectedCustomers = Array.from(uniqueCustomerIds).map((id) =>
              [...selectedCustomers, ...customers].find((c) => c.id === id)
            );
            // newSelectedCustomers = [...selectedCustomers, ...newSelectedCustomers];
          } else {
            // Remove the entire array of customers based on unique ID
            const customerIdsToRemove = new Set(customers.map((c) => c.id));
            newSelectedCustomers = selectedCustomers.filter((c) => !customerIdsToRemove.has(c.id));
          }
          state.customers.selectedCustomers = newSelectedCustomers;
        })
      );
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.customers.filters[filter] = value;
        })
      );
    },
    openCreateCustomerModal: () => {
      set(
        produce((state) => {
          state.customers.showCreateCustomerModal = true;
        })
      );
    },
    closeCreateCustomerModal: () => {
      set(
        produce((state) => {
          state.customers.showCreateCustomerModal = false;
        })
      );
    },
    openDeleteCustomersModal: () => {
      set(
        produce((state) => {
          state.customers.showDeleteCustomersModal = true;
        })
      );
    },
    closeDeleteCustomersModal: () => {
      set(
        produce((state) => {
          state.customers.showDeleteCustomersModal = false;
        })
      );
    },
    openImportCustomersModal: () => {
      set(
        produce((state) => {
          state.customers.showImportCustomersModal = true;
        })
      );
    },
    closeImportCustomersModal: () => {
      set(
        produce((state) => {
          state.customers.showImportCustomersModal = false;
        })
      );
    },
    createCustomer: (customerData) => {
      set(
        produce((state) => {
          state.customers.isCreateCustomerLoading = true;
        })
      );

      customerService
        .createCustomer(formatCustomerSave(customerData))
        .then(() => {
          get().customers.closeCreateCustomerModal();
          get().customers.getCustomers();
          toast.success('Cliente criado com sucesso');
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCreateCustomerLoading = false;
            })
          );
        });
    },
    deleteCustomers: () => {
      set(
        produce((state) => {
          state.customers.isDeletingCustomersLoading = true;
        })
      );

      customerService
        .deleteCustomers(get().customers.selectedCustomers.map((c) => c.id))
        .then(() => {
          toast.success('Clientes deletados com sucesso');
          get().customers.closeDeleteCustomersModal();
          get().customers.getCustomers();
          set(
            produce((state) => {
              state.customers.selectedCustomers = [];
            })
          );
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isDeletingCustomersLoading = false;
            })
          );
        });
    },
    updateCustomer: (id, customerData) => {
      set(
        produce((state) => {
          state.customers.isUpdatingCustomerLoading = true;
        })
      );

      customerService
        .updateCustomer(id, formatCustomerSave(customerData, id))
        .then(() => {
          toast.success('Cliente atualizado com sucesso');
          get().customers.getCustomer(id);
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isUpdatingCustomerLoading = false;
            })
          );
        });
    },
    changeCustomerPage: (page, makeRequest = true) => {
      set(
        produce((state) => {
          state.customers.customersCurrentPage = page;
        })
      );
      if (makeRequest) get().customers.getCustomers();
    },
    getCustomers: () => {
      set(
        produce((state) => {
          state.customers.isCustomersTableLoading = true;
        })
      );

      let filters = {
        ...(get().customers.filters.search ? { search: get().customers.filters.search } : {}),
        ...(get().customers.filters.status ? { status: get().customers.filters.status } : {}),
        ...(get().customers.filters.group ? { group: get().customers.filters.group } : {})
      };

      customerService
        .getCustomers({ ...filters, page: get().customers.customersCurrentPage })
        .then((customersData) => {
          set(
            produce((state) => {
              state.customers.customers = customersData.data;
              state.customers.customersTotalPages = customersData.page.last_page;
              state.customers.customersCurrentPage = customersData.page.actualPage;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCustomersTableLoading = false;
            })
          );
        });
    },
    getCustomer: (id) => {
      set(
        produce((state) => {
          state.customers.isCustomerLoading = true;
          state.customers.customer.id = id;
        })
      );
      customerService
        .getCustomer(id)
        .then((customerData) => {
          set(
            produce((state) => {
              state.customers.customer = {
                ...state.customers.customer,
                ...formatCustomerData(customerData)
              };
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCustomerLoading = false;
            })
          );
        });
    },
    changeSignaturesPage: (customerId, page, makeRequest = true) => {
      set(
        produce((state) => {
          state.customers.signaturesCurrentPage = page;
        })
      );
      if (makeRequest) get().customers.getCustomerSignatures(customerId);
    },
    getCustomerSignatures: (id) => {
      set(
        produce((state) => {
          state.customers.isCustomerSignaturesLoading = true;
        })
      );
      customerService
        .getCustomerSignatures(id)
        .then((signaturesData) => {
          set(
            produce((state) => {
              state.customers.customer.signatures = signaturesData.data;
              state.customers.signaturesTotalPages = signaturesData.page.last_page;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCustomerSignaturesLoading = false;
            })
          );
        });
    },
    changeItemsPage: (customerId, page, makeRequest = true) => {
      set(
        produce((state) => {
          state.customers.itemsCurrentPage = page;
        })
      );
      if (makeRequest) get().customers.getCustomerItems(customerId);
    },
    getCustomerItems: (id) => {
      set(
        produce((state) => {
          state.customers.isCustomerItemsLoading = true;
        })
      );
      customerService
        .getCustomerItems(id)
        .then((itemsData) => {
          set(
            produce((state) => {
              state.customers.customer.items = itemsData.data;
              state.customers.itemsTotalPages = itemsData.page.last_page;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCustomerItemsLoading = false;
            })
          );
        });
    },
    changeNotificationsPage: (customerId, page, makeRequest = true) => {
      set(
        produce((state) => {
          state.customers.notificationsCurrentPage = page;
        })
      );
      if (makeRequest) get().customers.getCustomerNotifications(customerId);
    },
    getCustomerNotifications: (id) => {
      set(
        produce((state) => {
          state.customers.isCustomerNotificationsLoading = true;
        })
      );
      customerService
        .getCustomerNotifications(id)
        .then((notificationsData) => {
          set(
            produce((state) => {
              state.customers.customer.notifications = notificationsData.data;
              state.customers.notificationsTotalPages = notificationsData.page.last_page;
            })
          );
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isCustomerNotificationsLoading = false;
            })
          );
        });
    },
    getCustomerOptions: async (filters) => {
      try {
        const customersData = await customerService.getCustomerOptions(filters);
        return formatFormObjectOptions(customersData, 'customer_name');
      } catch (error) {
        toast.error(error.message);
      }
    }
  }
});
