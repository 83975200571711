import React from 'react';
import Select from 'react-select';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function SelectInput(props) {
  const { options, field, placeholder, form, label, error, onChange } = props;

  return (
    <div>
      <span className="text-black inline-block mb-[3px] font-medium text-base">{label}</span>
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: 'none',
            borderBottom: '1px solid #111',
            borderRadius: 'none',
            outline: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#C7C7C7'
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#333',
            '&:hover': {
              backgroundColor: '#f8f9fa'
            }
          }),

          indicatorSeparator: () => {}
        }}
        options={options}
        placeholder={placeholder}
        name={field.name}
        value={
          field.value === ''
            ? null // Set the value to null if field.value is an empty string
            : options
            ? options.find((option) => option.value === field.value)
            : ''
        }
        onChange={onChange ? onChange : (option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        {...props}
      />
      <FieldErrorMessage>{error}</FieldErrorMessage>
    </div>
  );
}
