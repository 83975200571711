import React from 'react';

export default function RefreshIcon({ width = 16, height = 18, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99998 15.6667C11.6819 15.6667 14.6666 12.6819 14.6666 9C14.6666 7.22465 13.9727 5.61139 12.8412 4.41667M7.99998 15.6667L10.9166 16.5M7.99998 15.6667L9.66665 13.1667M7.99998 2.33333C4.31808 2.33333 1.33331 5.3181 1.33331 9C1.33331 10.9066 2.13363 12.6262 3.41665 13.8413M7.99998 2.33333L6.33331 4.83333M7.99998 2.33333L4.66665 1.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
