import produce from 'immer';
import { toast } from 'react-toastify';
import billingService from '../../services/billingService';
import {
  formatBillingCreationData,
  formatBillingsCreationData
} from '../../services/serviceFormatters';
import * as xlsx from 'xlsx';
import { BILLING_STATUSES_LABELS, DATE_TYPES, PAYMENT_METHODS_LABELS } from '../../utils/constants';

export const createBillingsSlice = (set, get) => ({
  billings: {
    createBillingData: {},
    createBillingsData: {},

    showCreateBillingModal: false,
    showCreateBillingsModal: false,
    showDeleteBillingModal: false,
    showCancelBillingModal: false,
    showConfirmBillingModal: false,
    showDeleteBillingsModal: false,
    showDeleteInvoiceModal: false,
    showCancelCreationConfirmationModal: false,
    showCancelCreateManyBillingsConfirmationModal: false,
    showConfirmMultipleBillingsModal: false,
    showCreateManyBillingsError: false,
    showConfirmManyBillingsError: false,

    createBillingModalPaymentType: 'signature',

    isCreateBillingLoading: false,
    isCreateBillingsLoading: false,
    isBillingsTableLoading: false,
    isBillingLoading: false,
    isBillingNotificationsLoading: false,
    isDeleteBillingLoading: false,
    isDenyBillingLoading: false,
    isConfirmBillingLoading: false,
    isDeletingBillingsLoading: false,
    isInvoiceLoading: false,
    isInvoiceInstallmentsLoading: false,
    isDeleteInvoiceLoading: false,

    billingsCurrentPage: 1,
    billingsTotalPages: 1,
    billingsTotalItems: 0,

    notificationsCurrentPage: 1,
    notificationsTotalPages: 1,

    installmentsCurrentPage: 1,
    installmentsTotalPages: 1,

    selectedBillings: [],
    billingsErrors: [],

    selectedBilling: null,
    billing: {
      notifications: []
    },
    selectedInvoice: null,
    invoice: {
      installments: []
    },
    billings: [],
    filters: {
      chargeStatus: '',
      formOfPayment: '',
      search: '',
      date: {
        startDate: '',
        endDate: ''
      },
      date_type: 'dueDate',
      limit: 10
    },
    toggleShowConfirmManyBillingsError: () => {
      set(
        produce((state) => {
          state.billings.showConfirmManyBillingsError =
            !state.billings.showConfirmManyBillingsError;
        })
      );
    },
    toggleShowCreateManyBillingsError: () => {
      set(
        produce((state) => {
          state.billings.showCreateManyBillingsError = !state.billings.showCreateManyBillingsError;
        })
      );
    },
    resetInvoice: () => {
      set(
        produce((state) => {
          state.billings.selectedInvoice = null;
          state.billings.invoice = {
            installments: []
          };
        })
      );
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.billings.filters[filter] = value;
        })
      );
    },
    setShowCancelCreationConfirmationModal: (value) => {
      set(
        produce((state) => {
          state.billings.showCancelCreationConfirmationModal = value;
        })
      );
    },
    setShowCancelCreateManyBillingsConfirmationModal: (value) => {
      set(
        produce((state) => {
          state.billings.showCancelCreateManyBillingsConfirmationModal = value;
        })
      );
    },
    openCreateBillingModal: (e, paymentType = 'signature') => {
      get().stepModal.setStep(1);
      set(
        produce((state) => {
          state.billings.showCreateBillingModal = true;
          state.billings.createBillingModalPaymentType = paymentType;
        })
      );
    },
    closeCreateBillingModal: () => {
      set(
        produce((state) => {
          state.billings.showCreateBillingModal = false;
          state.billings.createBillingModalPaymentType = 'signature';
        })
      );
    },
    openCreateBillingsModal: () => {
      get().stepModal.setStep(1);
      set(
        produce((state) => {
          state.billings.showCreateBillingsModal = true;
        })
      );
    },
    closeCreateBillingsModal: () => {
      set(
        produce((state) => {
          state.billings.showCreateBillingsModal = false;
        })
      );
    },
    openDeleteBillingModal: (billing) => {
      set(
        produce((state) => {
          state.billings.selectedBilling = billing;
          state.billings.showDeleteBillingModal = true;
        })
      );
    },
    closeDeleteBillingModal: () => {
      set(
        produce((state) => {
          state.billings.selectedBilling = null;
          state.billings.showDeleteBillingModal = false;
        })
      );
    },
    openDeleteInvoiceModal: (invoice) => {
      set(
        produce((state) => {
          state.billings.selectedInvoice = invoice;
          state.billings.showDeleteInvoiceModal = true;
        })
      );
    },
    closeDeleteInvoiceModal: () => {
      set(
        produce((state) => {
          state.billings.selectedInvoice = null;
          state.billings.showDeleteInvoiceModal = false;
        })
      );
    },
    openCancelBillingModal: (billing) => {
      set(
        produce((state) => {
          state.billings.selectedBilling = billing;
          state.billings.showCancelBillingModal = true;
        })
      );
    },
    closeCancelBillingModal: () => {
      set(
        produce((state) => {
          state.billings.selectedBilling = null;
          state.billings.showCancelBillingModal = false;
        })
      );
    },
    openConfirmMultipleBillingsModal: () => {
      set(
        produce((state) => {
          state.billings.showConfirmMultipleBillingsModal = true;
        })
      );
    },
    openConfirmBillingModal: (billing) => {
      set(
        produce((state) => {
          state.billings.selectedBilling = billing;
          state.billings.showConfirmBillingModal = true;
        })
      );
    },
    closeConfirmMultipleBillingsModal: () => {
      set(
        produce((state) => {
          state.billings.showConfirmMultipleBillingsModal = false;
          state.billings.selectedBillings = [];
        })
      );
    },
    closeConfirmBillingModal: () => {
      set(
        produce((state) => {
          state.billings.selectedBilling = null;
          state.billings.showConfirmBillingModal = false;
        })
      );
    },
    openDeleteBillingsModal: () => {
      set(
        produce((state) => {
          state.billings.showDeleteBillingsModal = true;
        })
      );
    },
    closeDeleteBillingsModal: () => {
      set(
        produce((state) => {
          state.billings.showDeleteBillingsModal = false;
        })
      );
    },
    resetBillingsSelected: () => {
      set(
        produce((state) => {
          state.billings.selectedBillings = [];
        })
      );
    },
    selectBillings: (billings, checked) => {
      set(
        produce((state) => {
          const selectedBillings = get().billings.selectedBillings;
          let newSelectedBillings;

          if (checked) {
            // Add the entire array of billings, eliminating duplicates based on unique ID
            const uniqueBillingIds = new Set([
              ...selectedBillings.map((b) => b.id),
              ...billings.map((b) => b.id)
            ]);
            newSelectedBillings = Array.from(uniqueBillingIds).map((id) =>
              [...selectedBillings, ...billings].find((b) => b.id === id)
            );
          } else {
            // Remove the entire array of billings based on unique ID
            const billingIdsToRemove = new Set(billings.map((b) => b.id));
            newSelectedBillings = selectedBillings.filter((b) => !billingIdsToRemove.has(b.id));
          }
          state.billings.selectedBillings = newSelectedBillings;
        })
      );
    },
    deleteBillings: () => {
      set(
        produce((state) => {
          state.billings.isDeletingBillingsLoading = true;
        })
      );
      billingService
        .deleteBillings(get().billings.selectedBillings.map((c) => c.id))
        .then(() => {
          toast.success('Cobranças deletadas com sucesso');
          get().billings.closeDeleteBillingsModal();
          get().billings.getBillings();
          set(
            produce((state) => {
              state.billings.selectedBillings = [];
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isDeletingBillingsLoading = false;
            })
          );
        });
    },
    deleteBilling: (navigate) => {
      const selectedBilling = get().billings.selectedBilling;
      set(
        produce((state) => {
          state.billings.isDeleteBillingLoading = true;
        })
      );
      billingService
        .deleteBilling(selectedBilling?.id)
        .then(() => {
          toast.success('Cobrança removida com sucesso');
          set(
            produce((state) => {
              state.billings.showDeleteBillingModal = false;
            })
          );

          get().billings.getBillings();
          navigate('/cobrancas');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isDeleteBillingLoading = false;
            })
          );
        });
    },
    deleteInvoice: (navigate) => {
      const selectedInvoice =
        get().billings.selectedInvoice === null
          ? get().billings.invoice
          : get().billings.selectedInvoice;
      set(
        produce((state) => {
          state.billings.isDeleteInvoiceLoading = true;
          state.billings.showDeleteBillingModal = false;
        })
      );
      billingService
        .deleteInvoice(
          selectedInvoice?.id ||
            get().billings.selectedBilling?.invoice_id ||
            get().billings.selectedBilling?.invoice.id
        )
        .then(() => {
          toast.success('Cobrança removida com sucesso');
          set(
            produce((state) => {
              state.billings.showDeleteInvoiceModal = false;
            })
          );
          get().billings.getBillings();
          if (navigate) navigate('/cobrancas');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isDeleteInvoiceLoading = false;
            })
          );
        });
    },
    cancelBilling: (notifyCustomer) => {
      const selectedBilling = get().billings.selectedBilling;

      set(
        produce((state) => {
          state.billings.isCancelBillingLoading = true;
        })
      );
      billingService
        .changeBillingStatus(selectedBilling?.id, 'pending', notifyCustomer)
        .then(() => {
          toast.success('Recebimento cancelado com sucesso');
          set(
            produce((state) => {
              state.billings.showCancelBillingModal = false;
              state.billings.billing.status = 'pending';
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isCancelBillingLoading = false;
              state.billings.selectedBilling = null;
            })
          );
        });
    },
    confirmBilling: ({ paidWhere, paidWhen, notifyCustomer, renewBilling }) => {
      const selectedBilling = get().billings.selectedBilling;

      set(
        produce((state) => {
          state.billings.isConfirmBillingLoading = true;
        })
      );
      billingService
        .changeBillingStatus(selectedBilling?.id, true, {
          paidWhere,
          paidWhen,
          notifyCustomer,
          renewBilling
        })
        .then(() => {
          toast.success('Recebimento confirmado com sucesso');

          get().billings.getBilling(get().billings.selectedBilling.id);
          get().billings.getBillings();

          set(
            produce((state) => {
              state.billings.showConfirmBillingModal = false;
              state.billings.selectedBilling = null;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isConfirmBillingLoading = false;
            })
          );
        });
    },
    denyMultipleBillings: ({ paidWhere, paidWhen, notifyCustomer }) => {
      const selectedBillings = get().billings.selectedBillings;
      const installments = selectedBillings.map((billing) => billing.id);
      set(
        produce((state) => {
          state.billings.isDenyBillingLoading = true;
        })
      );
      billingService
        .changeMultipleBillingsStatuses(installments, false, {
          paidWhere,
          paidWhen,
          notifyCustomer
        })
        .then(() => {
          toast.success('Recebimentos confirmados com sucesso');
          get().billings.getBillings();
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao recusar as cobrancas');
          set(
            produce((state) => {
              state.billings.billingsErrors = JSON.parse(error.message).message;
            })
          );
          get().billings.toggleShowConfirmManyBillingsError();
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isDenyBillingLoading = false;
              state.billings.showConfirmMultipleBillingsModal = false;
            })
          );
        });
    },
    confirmMultipleBillings: ({ paidWhere, paidWhen, notifyCustomer }) => {
      const selectedBillings = get().billings.selectedBillings;
      const installments = selectedBillings.map((billing) => billing.id);
      set(
        produce((state) => {
          state.billings.isConfirmBillingLoading = true;
        })
      );
      billingService
        .changeMultipleBillingsStatuses(installments, true, { paidWhere, paidWhen, notifyCustomer })
        .then(() => {
          toast.success('Recebimentos confirmados com sucesso');
          get().billings.getBillings();
        })
        .catch((error) => {
          toast.error('Ocorreu um erro ao confirmar as cobrancas');
          set(
            produce((state) => {
              state.billings.billingsErrors = JSON.parse(error.message).message;
            })
          );
          get().billings.toggleShowConfirmManyBillingsError();
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isConfirmBillingLoading = false;
              state.billings.showConfirmMultipleBillingsModal = false;
            })
          );
        });
    },
    denyBilling: ({ paidWhere, paidWhen, notifyCustomer }) => {
      const selectedBilling = get().billings.selectedBilling;

      set(
        produce((state) => {
          state.billings.isDenyBillingLoading = true;
        })
      );
      billingService
        .changeBillingStatus(selectedBilling?.id, false, { paidWhere, paidWhen, notifyCustomer })
        .then(() => {
          toast.success('Recebimento negado com sucesso');

          get().billings.getBilling(get().billings.selectedBilling.id);
          get().billings.getBillings();

          set(
            produce((state) => {
              state.billings.showConfirmBillingModal = false;
              state.billings.selectedBilling = null;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isDenyBillingLoading = false;
            })
          );
        });
    },
    updateCreateBillingData: (payload) => {
      set(
        produce((state) => {
          state.billings.createBillingData = { ...state.billings.createBillingData, ...payload };
        })
      );
    },
    updateCreateBillingsData: (payload) => {
      set(
        produce((state) => {
          state.billings.createBillingsData = { ...state.billings.createBillingsData, ...payload };
        })
      );
    },
    createBilling: () => {
      const createBillingData = get().billings.createBillingData;

      set(
        produce((state) => {
          state.billings.isCreateBillingLoading = true;
        })
      );

      billingService
        .createBilling(formatBillingCreationData(createBillingData))
        .then(() => {
          get().company.getCompanyResume();
          toast.success('Cobrança criada com sucesso');
          set(
            produce((state) => {
              state.billings.showCreateBillingModal = false;
            })
          );

          if (window.location.pathname === '/cobrancas') get().billings.getBillings();
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isCreateBillingLoading = false;
            })
          );
          get().customers.getCustomers();
        });
    },
    createBillings: () => {
      const createBillingsData = get().billings.createBillingsData;
      const selectedCustomers = get().customers.selectedCustomers;

      set(
        produce((state) => {
          state.billings.isCreateBillingsLoading = true;
        })
      );

      billingService
        .createBillings(
          formatBillingsCreationData({
            ...createBillingsData,
            selectedCustomers: selectedCustomers
          })
        )
        .then(() => {
          toast.success('Cobranças criadas com sucesso');
          set(
            produce((state) => {
              state.billings.showCreateBillingsModal = false;
            })
          );

          if (window.location.pathname === '/cobrancas') get().billings.getBillings();
        })
        .catch((error) => {
          toast.error('Erro ao criar cobranças');
          set(
            produce((state) => {
              state.billings.billingsErrors = JSON.parse(error.message).message;
            })
          );
          get().billings.toggleShowCreateManyBillingsError();
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isCreateBillingsLoading = false;
            })
          );
        });
    },
    changeBillingPage: (page, makeRequest = true) => {
      set(
        produce((state) => {
          state.billings.billingsCurrentPage = page;
        })
      );
      if (makeRequest) get().billings.getBillings();
    },
    getBillings: () => {
      const dateType = {};
      dateType[get().billings.filters.date_type] = true;
      set(
        produce((state) => {
          state.billings.isBillingsTableLoading = true;
        })
      );

      let filters = {
        ...(get().billings.filters.search ? { search: get().billings.filters.search } : {}),
        ...(get().billings.filters.chargeStatus
          ? { chargeStatus: get().billings.filters.chargeStatus }
          : {}),
        ...(get().billings.filters.formOfPayment
          ? { formOfPayment: get().billings.filters.formOfPayment }
          : {}),
        ...get().billings.filters.date,
        ...dateType,
        limit: get().billings.filters.limit
      };
      billingService
        .getBillings({ ...filters, page: get().billings.billingsCurrentPage })
        .then((billingsData) => {
          set(
            produce((state) => {
              state.billings.billings = billingsData.data;
              state.billings.billingsTotalPages = billingsData.total_page;
              state.billings.billingsCurrentPage = billingsData.current_page;
              state.billings.billingsTotalItems = billingsData.total_items;
            })
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isBillingsTableLoading = false;
            })
          );
        });
    },
    getBilling: (id) => {
      set(
        produce((state) => {
          state.billings.isBillingLoading = true;
        })
      );
      billingService
        .getBilling(id)
        .then((billingData) => {
          set(
            produce((state) => {
              state.billings.billing = { ...state.billings.billing, ...billingData };
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isBillingLoading = false;
            })
          );
        });
    },
    getInvoice: (id) => {
      set(
        produce((state) => {
          state.billings.isInvoiceLoading = true;
        })
      );
      billingService
        .getInvoice(id)
        .then((billingData) => {
          set(
            produce((state) => {
              state.billings.invoice = {
                ...state.billings.invoice,
                ...billingData
              };
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isInvoiceLoading = false;
            })
          );
        });
    },
    changeNotificationsPage: (billingId, page, makeRequest = true) => {
      set(
        produce((state) => {
          state.billings.notificationsCurrentPage = page;
        })
      );
      if (makeRequest) get().billings.getBillingNotifications(billingId);
    },
    getBillingNotifications: (id) => {
      set(
        produce((state) => {
          state.billings.isBillingNotificationsLoading = true;
        })
      );
      billingService
        .getBillingNotifications(id)
        .then((notifications) => {
          set(
            produce((state) => {
              state.billings.billing.notifications = notifications;
            })
          );
          //  IF IT HAS PAGINATION
          // set(
          //   produce((state) => {
          //     state.billings.billing.notifications = notifications.data;
          //     state.billings.notificationsTotalPages = notifications.total_pages;
          //   })
          // );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isBillingNotificationsLoading = false;
            })
          );
        });
    },
    changeInstallmentsPage: (invoiceId, page, makeRequest = true) => {
      set(
        produce((state) => {
          state.billings.installmentsCurrentPage = page;
        })
      );
      if (makeRequest) get().billings.getInvoiceInstallments(invoiceId);
    },
    getInvoiceInstallments: (id) => {
      set(
        produce((state) => {
          state.billings.isInvoiceInstallmentsLoading = true;
        })
      );
      billingService
        .getInvoiceInstallments(id)
        .then((installments) => {
          set(
            produce((state) => {
              state.billings.invoice.installments = installments.data;
              state.billings.installmentsTotalPages = installments.total_pages;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.billings.isInvoiceInstallmentsLoading = false;
            })
          );
        });
    },
    downloadExcel: () => {
      const dateType = {};
      dateType[get().billings.filters.date_type] = true;
      let filters = {
        ...(get().billings.filters.search ? { search: get().billings.filters.search } : {}),
        ...(get().billings.filters.chargeStatus
          ? { chargeStatus: get().billings.filters.chargeStatus }
          : {}),
        ...(get().billings.filters.formOfPayment
          ? { formOfPayment: get().billings.filters.formOfPayment }
          : {}),
        ...get().billings.filters.date,
        ...dateType
      };
      const filteredBy = DATE_TYPES.find(
        (dateType) => dateType.value === get().billings.filters.date_type
      );
      const excelFilters = [
        [],
        ['Filtrado por:'],
        [
          filteredBy.label + ':',
          new Date(get().billings.filters.date.startDate).toString().slice(4, 15),
          '',
          new Date(get().billings.filters.date.endDate).toString().slice(4, 15)
        ],
        ['Status:', BILLING_STATUSES_LABELS[get().billings.filters.chargeStatus] ?? 'Todos'],
        [
          'Forma de Pagamento:',
          PAYMENT_METHODS_LABELS[get().billings.filters.formOfPayment] ?? 'Todos'
        ],
        ['Pesquisa:', get().billings.filters.search],
        []
      ];
      const excelName = filters.startDate
        ? `relatorio_${new Date(filters.startDate).toLocaleDateString()}-${new Date(
            filters.endDate
          ).toLocaleDateString()}.xlsx`
        : 'relatorio.xlsx';
      billingService
        .getExcelData(filters)
        .then((response) => {
          toast.success('Download concluído com sucesso');
          const workbook = xlsx.utils.book_new();
          const worksheet = xlsx.utils.aoa_to_sheet(
            response.data ? [...excelFilters, ...response.data] : []
          );
          xlsx.utils.book_append_sheet(workbook, worksheet, 'Dados');
          xlsx.writeFile(workbook, excelName);
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    }
  }
});
