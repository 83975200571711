import React, { useEffect } from 'react';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import { useParams } from 'react-router';
import { SIGNATURE_INTERVAL_LABELS, SIGNATURE_SITUATIONS } from '../../../utils/constants';
import { formatCurrency } from '../../../utils/currencyHelper';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import { PlusIcon, StatusIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import { formatDate } from '../../../utils/dateHelpers';
import SimpleLink from '../../../components/SimpleLink/SimpleLink';

function SignaturesTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Vencimento',
      accessor: 'first_due_date',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          {formatDate(new Date(row.original.first_due_date))}
        </div>
      )
    },
    {
      Header: 'Assinatura',
      accessor: 'name',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <SimpleLink to={`/cobrancas/${row.original?.installments[0]?.id}`}>
            {row.original.product.name}
          </SimpleLink>
        </div>
      )
    },
    {
      Header: 'Intervalo',
      accessor: 'interval',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          {SIGNATURE_INTERVAL_LABELS[row.original.product?.company_plan?.interval] ?? '-'}
        </div>
      )
    },
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          MZN {formatCurrency(row.original.total_value)}
        </div>
      )
    },
    {
      Header: 'Situação',
      accessor: 'situation',
      Cell: ({ row }) => (
        <div className="flex gap-2 items-center">
          <StatusIcon color={SIGNATURE_SITUATIONS[row.original?.installments[0].status]?.color} />
          <div style={{ color: SIGNATURE_SITUATIONS[row.original?.installments[0].status]?.color }}>
            {SIGNATURE_SITUATIONS[row.original?.installments[0].status]?.label}
          </div>
        </div>
      )
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

export default function SignatureSection() {
  const getCustomerSignatures = useStore((state) => state.customers.getCustomerSignatures);
  const signatures = useStore((state) => state.customers.customer?.signatures);
  const openCreateBillingModal = useStore((state) => state.billings.openCreateBillingModal);

  const isCustomerSignaturesLoading = useStore(
    (state) => state.customers.isCustomerSignaturesLoading
  );
  const params = useParams();

  useEffect(() => {
    getCustomerSignatures(params.id);
  }, []);

  return (
    <DetailSection
      noBorderBottom
      title="Assinaturas"
      button={
        <OutlinedButton
          onClick={openCreateBillingModal}
          type="button"
          className="text-base px-2 py-1 md:py-3 md:px-5">
          <div className="flex gap-3 items-center">
            <div>Nova Assinatura</div>
            <PlusIcon color={Colors.Orange} />
          </div>
        </OutlinedButton>
      }>
      <div className="pb-1 px-5 border-b border-gray-2 rounded">
        <SignaturesTable data={signatures} loading={isCustomerSignaturesLoading} />
      </div>
    </DetailSection>
  );
}
