import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

const DateInput = forwardRef(
  ({ value, onClick, label, customPlaceholder, onClear, error }, ref) => {
    return (
      <div>
        <span className={`font-medium text-sm flex justify-between`}>
          <span>{label}</span>
          {onClear ? (
            <button
              type="button"
              onClick={onClear}
              className="ml-auto underline font-normal text-orange">
              Limpar
            </button>
          ) : null}
        </span>
        <button
          className="border w-full text-left border-gray-2 p-2 rounded"
          type="button"
          onClick={onClick}
          ref={ref}>
          {value ? value : customPlaceholder}
        </button>
        <FieldErrorMessage>{error}</FieldErrorMessage>
      </div>
    );
  }
);

DateInput.displayName = 'DateInput';

export default function BorderedDatePicker({
  selected,
  onChange,
  label,
  customPlaceholder,
  error,
  onClear
}) {
  return (
    <DatePicker
      selected={selected}
      locale="ptBR"
      dateFormat="dd/MM/yyyy"
      customInput={
        <DateInput
          customPlaceholder={customPlaceholder}
          label={label}
          onClear={onClear}
          error={error}
        />
      }
      onChange={onChange}
    />
  );
}
