import React from 'react';
import { Colors } from '../../utils/colors';

export default function ThinFileIcon({ width = 12, height = 16, color = Colors.black }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6.75 1.25V5.75H11.25" stroke={color} strokeLinejoin="round" />
      <path
        d="M11.25 5L7.5 1.25L0.75 1.25002L0.75 14.75L11.25 14.75L11.25 5Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
