import React from 'react';

export default function InstallmentsIcon({ width = 20, height = 20, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 15.8333H3.75" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M7.08331 15.8333H17.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M2.5 10.8333H3.75" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M7.08331 10.8333H17.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M9.58331 5.83333H17.5" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path
        d="M2.5 5.83333L4.16667 7.5L8.33333 3.33333"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
