import React from 'react';
import { CancelIcon, DeleteIcon, RightIcon, ThinFileIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import { useStore } from '../../../store/useStore';
import ButtonSelect from './ButtonSelect';
import DotsIcon from '../../../assets/imgs/DotsIcon';

const getOptions = (billing) => {
  let options = [];

  if (billing?.status === 'paid') {
    options = [
      {
        value: 'view-link',
        icon: <ThinFileIcon color={Colors.black} />,
        label: 'Visualizar factura'
      },
      {
        value: 'cancel-reception',
        icon: <CancelIcon color={Colors.black} />,
        label: 'Cancelar recebimento'
      },
      {
        value: 'delete-billing',
        icon: <DeleteIcon color={Colors.black} />,
        label: 'Apagar cobrança'
      }
    ];
  } else {
    options = [
      {
        value: 'view-link',
        icon: <ThinFileIcon color={Colors.black} />,
        label: 'Visualizar factura'
      },
      {
        value: 'confirm-reception',
        icon: <RightIcon width={13} color={Colors.black} />,
        label: 'Confirmar recebimento'
      },
      {
        value: 'delete-billing',
        icon: <DeleteIcon color={Colors.black} />,
        label: 'Apagar cobrança'
      }
    ];
  }

  return options;
};

export default function MoreActionBilling({ billing, asDots }) {
  const openDeleteBillingModal = useStore((state) => state.billings.openDeleteBillingModal);
  const openCancelBillingModal = useStore((state) => state.billings.openCancelBillingModal);
  const openConfirmBillingModal = useStore((state) => state.billings.openConfirmBillingModal);
  let options = getOptions(billing);

  const billingLink = new URL(window.location.origin);

  const onChooseOption = (option) => {
    switch (option) {
      case 'view-link':
        billingLink.pathname = `cobrancas/link/${billing.id}`;
        window.open(billingLink?.href, '_blank').focus();
        break;
      case 'cancel-reception':
        openCancelBillingModal(billing);
        break;
      case 'confirm-reception':
        openConfirmBillingModal(billing);
        break;
      case 'delete-billing':
        openDeleteBillingModal(billing);
    }
  };

  return (
    <ButtonSelect
      options={options}
      label="Mais ações"
      onChange={onChooseOption}
      component={asDots ? DotsIcon : null}
      menuClassName={asDots ? 'left-[-200px] top-[-72px]' : 'left-[-68px]'}
    />
  );
}
