import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import Pagination from '../../../components/Pagination/Pagination';
import { useStore } from '../../../store/useStore';
import { formatCurrency } from '../../../utils/currencyHelper';
import MoreActionProduct from './MoreActionProduct';

export default function ProductsTable({ data, loading = false }) {
  const selectedProducts = useStore((state) => state.products.selectedProducts);
  const selectProducts = useStore((state) => state.products.selectProducts);
  const { page, lastPage } = useStore((state) => state.products.filters);
  const changeProductPage = useStore((state) => state.products.changeProductPage);

  const onSelectRow = (products, checked) => {
    selectProducts(products, checked);
  };

  const columns = [
    {
      Header: 'Nome',
      accessor: 'name', // accessor is the "key" in the data
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original.name}</div>
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.description}</div>
        </div>
      )
    },
    {
      Header: 'Preço',
      accessor: 'price',
      Cell: ({ row }) => <div>MZN {formatCurrency(row.original.price)}</div>
    },
    {
      Header: 'Público',
      accessor: 'is_public',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.is_public ? 'Sim' : 'Não'}</div>
        </div>
      )
    },
    {
      accessor: 'action',
      Cell: ({ row }) => (
        <div>
          <MoreActionProduct product={row.original} asDots />
        </div>
      )
    }
  ];
  return (
    <>
      <DataTable
        loading={loading}
        onClickRow={() => {}}
        onSelectRow={onSelectRow}
        hiddenColumns={[]}
        columns={columns}
        data={data}
        selectedRows={selectedProducts}
      />
      <div className="mt-5 flex justify-center">
        <Pagination currentPage={page} totalPages={lastPage} changePage={changeProductPage} />
      </div>
    </>
  );
}
