import React from 'react';
import { useStore } from '../../../store/useStore';
import { useNavigate } from 'react-router-dom';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';

export default function DeleteBillingModal() {
  const deleteBilling = useStore((state) => state.billings.deleteBilling);
  const showDeleteBillingModal = useStore((state) => state.billings.showDeleteBillingModal);
  const closeDeleteBillingModal = useStore((state) => state.billings.closeDeleteBillingModal);
  const isDeleteBillingLoading = useStore((state) => state.billings.isDeleteBillingLoading);
  const isDeleteInvoiceLoading = useStore((state) => state.billings.isDeleteInvoiceLoading);
  const deleteInvoice = useStore((state) => state.billings.deleteInvoice);
  const selectedBilling = useStore((state) => state.billings.selectedBilling);
  const navigate = useNavigate();
  const handleDeleteBilling = () => {
    deleteBilling(navigate);
  };
  const handleDeleteAllInstallments = () => {
    deleteInvoice(navigate);
  };

  return (
    <Modal
      title="Apagar cobrança"
      onClose={closeDeleteBillingModal}
      show={showDeleteBillingModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          {selectedBilling?.type === 'ONETIME' || selectedBilling?.type === 'SIGNATURE' ? (
            <p>
              Tem certeza que deseja apagar esta cobrança? Depois de feito, você não pode desfazer
              essa ação.
            </p>
          ) : (
            <p>
              Esta é uma cobrança parcelada. Escolha abaixo se deseja excluir somente esta parcela
              ou todas as{' '}
              {selectedBilling?.total_installments && selectedBilling?.total_installments} parcelas.
            </p>
          )}
        </div>
      </div>
      <div className="p-5 space-y-2">
        <FilledButton
          className="px-10 w-full"
          onClick={closeDeleteBillingModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={handleDeleteBilling}
          loading={isDeleteBillingLoading}
          className="px-10 w-full"
          bg={Colors.Red}>
          Apagar
        </FilledButton>
        {selectedBilling?.type === 'INSTALLMENT' && (
          <FilledButton
            onClick={handleDeleteAllInstallments}
            loading={isDeleteInvoiceLoading}
            className="px-10 w-full"
            bg={Colors.Red}>
            Excluir esta e as{' '}
            {selectedBilling?.total_installments && selectedBilling?.total_installments - 1} demais
            parcelas
          </FilledButton>
        )}
      </div>
    </Modal>
  );
}
