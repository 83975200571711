import { NavLink } from 'react-router-dom';

export default function SimpleLink({ to, children, generalLink }) {
  return !generalLink ? (
    <NavLink className="cursor-pointer underline text-orange" to={to}>
      {children}
    </NavLink>
  ) : (
    <a className="cursor-pointer underline text-orange" href={to}>
      {children}
    </a>
  );
}
