import produce from 'immer';
import { toast } from 'react-toastify';
import groupService from '../../services/groupService';
import { formatFormObjectOptions } from '../../services/serviceFormatters';

export const createGroupsSlice = (set, get) => ({
  groups: {
    isLoading: false,

    showCreateGroupModal: false,
    showUpdateGroupModal: false,
    showDeleteGroupModal: false,

    groupsCurrentPage: 1,
    groupsTotalPages: 1,

    group: {},
    groups: [],
    selectedGroups: [],
    groupsFormatted: [],

    filters: {
      groupName: ''
    },
    toggleShowDeleteGroupModal: () => {
      set(
        produce((state) => {
          state.groups.showDeleteGroupModal = !state.groups.showDeleteGroupModal;
        })
      );
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.groups.filters[filter] = value;
        })
      );
    },
    resetFilters: () => {
      set(
        produce((state) => {
          state.groups.filters = { groupName: '' };
        })
      );
    },
    resetGroupsFormatted: () => {
      set(
        produce((state) => {
          state.groups.groupsFormatted = [];
        })
      );
    },
    setGroup: (group) => {
      set(
        produce((state) => {
          state.groups.group = group;
        })
      );
    },
    toggleShowCreateGroupModal: () => {
      set(
        produce((state) => {
          state.groups.showCreateGroupModal = !state.groups.showCreateGroupModal;
        })
      );
    },
    toggleShowUpdateGroupModal: () => {
      set(
        produce((state) => {
          state.groups.showUpdateGroupModal = !state.groups.showUpdateGroupModal;
        })
      );
    },
    getGroups: () => {
      const filters = get().groups.filters;
      set(
        produce((state) => {
          state.groups.isLoading = true;
        })
      );
      groupService
        .getGroups(filters.groupName ? 1 : get().groups.groupsCurrentPage, filters)
        .then((groups) => {
          set(
            produce((state) => {
              state.groups.groups = groups.groups;
              state.groups.groupsTotalPages = groups.totalPages;
              state.groups.groupsCurrentPage = groups.page;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.groups.isLoading = false;
            })
          );
        });
    },
    getGroupsOptions: () => {
      groupService
        .getGroups(null, { noLimit: true })
        .then((groups) => {
          set(
            produce((state) => {
              state.groups.groupsFormatted = formatFormObjectOptions(groups.groups, 'name');
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    },
    updateGroup: ({ name, description }) => {
      set(
        produce((state) => {
          state.groups.isLoading = true;
        })
      );
      groupService
        .updateGroup({ id: get().groups.group?.id, name, description })
        .then(() => {
          get().groups.toggleShowUpdateGroupModal();
          get().groups.getGroups();
          toast.success('Grupo atualizado com sucesso');
        })
        .catch((error) => {
          console.error(error.message);
          toast.error('Erro ao atualizar grupo');
        })
        .finally(() => {
          set(
            produce((state) => {
              state.groups.isLoading = false;
            })
          );
        });
    },
    selectGroups: (groups, checked) => {
      set(
        produce((state) => {
          const selectedGroups = get().groups.selectedGroups;
          let newSelectedGroups;

          if (checked) {
            // Add the entire array of Groups, eliminating duplicates based on unique ID
            const uniqueGroupIds = new Set([
              ...selectedGroups.map((c) => c.id),
              ...groups.map((c) => c.id)
            ]);
            newSelectedGroups = Array.from(uniqueGroupIds).map((id) =>
              [...selectedGroups, ...groups].find((c) => c.id === id)
            );
            // newSelectedGroups = [...selectedGroups, ...newSelectedGroups];
          } else {
            // Remove the entire array of Groups based on unique ID
            const groupIdsToRemove = new Set(groups.map((c) => c.id));
            newSelectedGroups = selectedGroups.filter((c) => !groupIdsToRemove.has(c.id));
          }

          state.groups.selectedGroups = newSelectedGroups;
        })
      );
    },
    resetGroupsSelected: () => {
      set(
        produce((state) => {
          state.groups.selectedGroups = [];
        })
      );
    },
    changeGroupsPage: (page) => {
      set(
        produce((state) => {
          state.groups.groupsCurrentPage = page;
        })
      );
      get().groups.getGroups();
    },
    createGroup: (data) => {
      set(
        produce((state) => {
          state.groups.isLoading = true;
        })
      );
      groupService
        .createGroup(data)
        .then(() => {
          toast.success('Grupo criado com sucesso!');
          get().groups.getGroups();
          get().groups.getGroupsOptions();
          get().groups.toggleShowCreateGroupModal();
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.groups.isLoading = false;
            })
          );
        });
    },
    associateCustomersToGroups: (data) => {
      set(
        produce((state) => {
          state.groups.isAssociatingCustomersToGroup = true;
        })
      );
      groupService
        .associateCustomersToGroups(data)
        .then(() => {
          get().customers.resetCustomersSelected();
          toast.success('Associação concluída com sucesso');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.groups.isAssociatingCustomersToGroup = false;
              state.customers.showAssociateCustomersToGroupsModal = false;
            })
          );
        });
    },
    dissociateCustomersFromGroups: (data) => {
      set(
        produce((state) => {
          state.customers.isDissociatingCustomersFromGroupLoading = true;
        })
      );
      groupService
        .dissociateCustomersFromGroups(data)
        .then(() => {
          get().customers.getCustomers();
          get().customers.resetCustomersSelected();
          toast.success('Desassociação concluída com sucesso');
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.customers.isDissociatingCustomersFromGroupLoading = false;
              state.customers.showAssociateCustomersToGroupsModal = false;
            })
          );
        });
    },
    deleteGroup: (id) => {
      set(
        produce((state) => {
          state.groups.isLoading = true;
        })
      );
      groupService
        .deleteGroup(id)
        .then(() => {
          toast.success('Grupo excluído com sucesso');
          get().groups.getGroups();
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.groups.isLoading = false;
              state.groups.showDeleteGroupModal = false;
            })
          );
        });
    }
  }
});
