import React, { useEffect, useState } from 'react';
import BorderlessStaticText from '../../../components/FormComponents/Inputs/BorderlessStaticText';
import LabelWrapper from '../../../components/FormComponents/LabelWrapper/LabelWrapper';
import RadioButton from '../../../components/FormComponents/RadioButton/RadioButton';
import { BILLING_TYPES_LABELS } from '../../../utils/constants';
import { formatCurrency } from '../../../utils/currencyHelper';
import { formatDate } from '../../../utils/dateHelpers';
import { Field } from 'formik';
import BorderlessSelect from '../../../components/FormComponents/SelectFields/BorderlessSelect';
import BorderlessDatePicker from '../../../components/FormComponents/BorderlessDatePicker/BorderlessDatePicker';
import companyService from '../../../services/companyService';

export default function MultipleBillingDetails({ billings, formik }) {
  const [paymentMethods, setPaymentMethods] = useState([]);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  useEffect(() => {
    (async () => {
      let formatted = [];
      const paymentMethods = await companyService.getPaymentMethods();
      paymentMethods.numerary && formatted.push({ value: 'numerary', label: 'Numerario' });
      paymentMethods?.company_bank.forEach((bank) => {
        formatted.push({
          value: bank.bank.id,
          label: `${bank.bank.name} - ${bank.account_number}`
        });
      });
      paymentMethods?.company_wallet.forEach((wallet) => {
        formatted.push({
          value: wallet.wallet.id,
          label: `${wallet.wallet.name} (${wallet.account_number})`
        });
      });
      setPaymentMethods(formatted);
    })();
  }, []);

  return (
    <div>
      <div className="text-lg font-medium py-5">Dados das cobranças</div>
      {billings.map((billing) => (
        <div key={billing.id} className="mb-5">
          <LabelWrapper label="ID da cobrança">
            <BorderlessStaticText borderless>{billing?.id}</BorderlessStaticText>
          </LabelWrapper>
          {billing?.type && (
            <LabelWrapper label="Tipo da cobrança">
              <BorderlessStaticText borderless>
                {BILLING_TYPES_LABELS[billing?.type]}
              </BorderlessStaticText>
            </LabelWrapper>
          )}
          <LabelWrapper label="Valor da cobrança">
            <BorderlessStaticText borderless>
              MZN {formatCurrency(billing?.value)}
            </BorderlessStaticText>
          </LabelWrapper>
          <LabelWrapper label="Vencimento">
            <BorderlessStaticText borderless>
              {formatDate(new Date(billing?.due_date))}
            </BorderlessStaticText>
          </LabelWrapper>
          {billing?.customer && (
            <LabelWrapper label="Nome do cliente" borderBottom>
              <BorderlessStaticText borderless>
                {billing?.customer?.first_name} {billing?.customer?.last_name}
              </BorderlessStaticText>
            </LabelWrapper>
          )}
        </div>
      ))}
      <div className="text-lg font-medium py-5">Dados do pagamento</div>
      <div>
        <>
          <LabelWrapper label="Forma de pagamento">
            <BorderlessStaticText borderless>
              <Field
                name={`paidWhere`}
                component={BorderlessSelect}
                options={paymentMethods}
                menuPosition="fixed"
                label="Método de pagamento"
                placeholder="Selecione um método de pagamento"
                onChange={(option) => {
                  formik.setFieldValue('paidWhere', option.label);
                }}
                error={errorMessage(formik, 'paidWhere')}
              />
            </BorderlessStaticText>
          </LabelWrapper>
          <LabelWrapper label="Que dia foi pago?" borderBottom>
            <div className="p-[15px]">
              <BorderlessDatePicker
                selected={formik.values?.paidWhen}
                customPlaceholder="__/__/____"
                error={errorMessage(formik, 'paidWhen')}
                onChange={(date) => formik.setFieldValue('paidWhen', date)}
              />
            </div>
          </LabelWrapper>
        </>
      </div>
      <div className="text-lg font-medium py-5">Notificação</div>
      <div>Deseja notificar o seu cliente por email?</div>
      <div className="flex gap-5 pt-2">
        <RadioButton
          label="Sim"
          checked={formik.values.notifyCustomer === 'yes'}
          value="yes"
          onChange={() => formik.setFieldValue('notifyCustomer', 'yes')}
        />
        <RadioButton
          label="Não"
          checked={formik.values.notifyCustomer === 'no'}
          value="no"
          onChange={() => formik.setFieldValue('notifyCustomer', 'no')}
        />
      </div>
    </div>
  );
}
