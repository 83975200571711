import React, { useEffect } from 'react';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import { useParams } from 'react-router';
import { formatCurrency } from '../../../utils/currencyHelper';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import { PlusIcon, StatusIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import { formatDate } from '../../../utils/dateHelpers';
import { BILLING_TYPES, ITEM_SITUATIONS } from '../../../utils/constants';
import SimpleLink from '../../../components/SimpleLink/SimpleLink';

const formatItemName = (item) => {
  if (item.type === BILLING_TYPES.ONETIME) {
    return `${item?.product?.name ?? '-'}(à vista)`;
  } else if (item.type === BILLING_TYPES.INSTALLMENT) {
    return `${item?.product?.name ?? '-'}(${item?.installments_number} meses)`;
  }

  return `${item?.product?.name ?? '-'}`;
};

const getItemLink = (item) => {
  let link = `/cobrancas/${item?.installments[0]?.id}`;
  if (item.type === BILLING_TYPES.INSTALLMENT) {
    link = `/parcelamentos/${item?.id}`;
  }
  return link;
};

function ItemsTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Data',
      accessor: 'date',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          {formatDate(new Date(row.original.first_due_date))}
        </div>
      )
    },
    {
      Header: 'Item',
      accessor: 'item',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <SimpleLink to={getItemLink(row.original)}>{formatItemName(row.original)}</SimpleLink>
        </div>
      )
    },
    {
      Header: 'Situação',
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="flex gap-2 items-center">
          <StatusIcon color={ITEM_SITUATIONS[row.original.status]?.color} />
          <div style={{ color: ITEM_SITUATIONS[row.original.status]?.color }}>
            {ITEM_SITUATIONS[row.original.status]?.label}
          </div>
        </div>
      )
    },
    {
      Header: 'Parcelas',
      accessor: 'installments',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          {row.original.installments_number} x MZN{' '}
          {formatCurrency(row.original.total_value / row.original.installments_number)}
        </div>
      )
    },
    {
      Header: 'Valor total',
      accessor: 'value',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          MZN {formatCurrency(row.original.total_value)}
        </div>
      )
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

export default function ItemsSection() {
  const getCustomerItems = useStore((state) => state.customers.getCustomerItems);
  const items = useStore((state) => state.customers.customer?.items);
  const isCustomerItemsLoading = useStore((state) => state.customers.isCustomerItemsLoading);
  const openCreateBillingModal = useStore((state) => state.billings.openCreateBillingModal);

  const handleOpenCreateBillingModal = (e) => {
    openCreateBillingModal(e, 'in_cash_or_installments');
  };

  const params = useParams();

  useEffect(() => {
    getCustomerItems(params.id);
  }, []);

  return (
    <DetailSection
      title="Itens adquiridos (à vista ou parcelado)"
      button={
        <OutlinedButton
          onClick={handleOpenCreateBillingModal}
          type="button"
          className="text-base md:py-3 px-5">
          <div className="flex gap-3 items-center">
            <div>Novo Item</div>
            <PlusIcon color={Colors.Orange} />
          </div>
        </OutlinedButton>
      }>
      <div className="px-5">
        <ItemsTable data={items} loading={isCustomerItemsLoading} />
      </div>
    </DetailSection>
  );
}
