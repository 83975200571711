import React from 'react';
import Select from 'react-select';
import { Colors } from '../../../utils/colors';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function BorderedSelect(props) {
  const { options, field, placeholder, form, label } = props;

  return (
    <div>
      <span className="text-black inline-block mb font-medium text-sm">{label}</span>
      <Select
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%'
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            outline: 'none',
            boxShadow: 'none',
            height: 40,
            borderColor: Colors.Gray2,
            '&:hover': {
              boxShadow: 'none'
            },
            '&:focus': {
              borderColor: Colors.Gray2
            }
          }),
          dropdownIndicator: () => ({
            color: Colors.Orange
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0 6px'
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: Colors.Gray3,
            padding: 6
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: 6
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#333',
            '&:hover': {
              backgroundColor: '#f8f9fa'
            }
          }),
          indicatorSeparator: () => {}
        }}
        defaultValue={props.defaultValue}
        options={options}
        placeholder={placeholder}
        name={field.name}
        value={options ? options.find((option) => option.value == field.value) : ''}
        onChange={(option) => form.setFieldValue(field.name, option.value)}
        onBlur={field.onBlur}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        {...props}
      />
      <FieldErrorMessage>{props.error}</FieldErrorMessage>
    </div>
  );
}
