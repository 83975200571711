import React from 'react';

export default function SaveIcon({ width = 20, height = 20, color = '#fff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5858 1C14.851 1 15.1054 1.10536 15.2929 1.29289L19 5L19 18C19 18.5523 18.5523 19 18 19L2 19C1.44772 19 1 18.5523 1 18L1 2C1 1.44772 1.44772 1 2 1L14.5858 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="5" y="11" width="10" height="8" stroke={color} strokeLinejoin="round" />
      <rect x="6" y="1" width="8" height="5" stroke={color} strokeLinejoin="round" />
    </svg>
  );
}
