import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import { useEffect, useRef, useState } from 'react';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import { Formik } from 'formik';
import Input from '../../components/FormComponents/Inputs/Input';
import MaskedInput from '../../components/FormComponents/Inputs/MaskedInput';
import * as Yup from 'yup';
const OtpVerification = () => {
  const [count, setCount] = useState(30);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const signUpData = useStore((state) => state.auth.signUpData);
  const getOtp = useStore((state) => state.auth.getOtp);
  const confirmOtp = useStore((state) => state.auth.confirmOtp);
  const isLoading = useStore((state) => state.auth.isLoading);
  const isEmailVerified = useStore((state) => state.auth.isEmailVerified);
  const updateSignUpDataWithToast = useStore((state) => state.auth.updateSignUpDataWithToast);
  const [isUserUpdatingInfo, setIsUserUpdatingInfo] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    if (!signUpData.email) {
      navigate('/cadastro');
    }
  }, []);

  const resendOtp = () => {
    setCount(30);
    getOtp(navigate);
  };
  const handleClick = () => {
    const code = inputRef.current?.value;
    confirmOtp(code, navigate);
    inputRef.current.value = '';
  };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <div className="h-screen flex justify-center items-center bg-[#E9EBEE]">
      <div className="w-11/12 md:w-6/12 xl:w-4/12 shadow bg-white rounded-xl">
        {isUserUpdatingInfo ? (
          <Formik
            initialValues={{ email: signUpData.email, phone: signUpData.phone }}
            validationSchema={Yup.object({
              email: Yup.string().email('Endereço de email inválido').required('Campo obrigatório'),
              phone: Yup.string()
                .matches(
                  /^\+258 (86|87|84|82|83) \d\d\d \d\d\d\d$/,
                  'O telefone deve ter o formato +258 XX XXX XXXX'
                )
                .required('Campo obrigatório')
            })}
            onSubmit={(values) => {
              updateSignUpDataWithToast(values);
              setIsUserUpdatingInfo(false);
            }}>
            {(formik) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}>
                <h2 className=" border-gray-2 border-b-2 p-5 text-xl font-bold">
                  Atualização de informações de contacto
                </h2>
                <div className="p-5 space-y-7">
                  <div>
                    <Input
                      placeholder="Digite seu Email"
                      type="email"
                      label="Email"
                      error={errorMessage(formik, 'email')}
                      {...formik.getFieldProps('email')}
                    />
                  </div>
                  <MaskedInput
                    placeholder="Digite seu Telefone"
                    label="Telefone"
                    mask="+258 99 999 9999"
                    alwaysShowMask={true}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'phone')}
                    {...formik.getFieldProps('phone')}
                  />
                </div>
                <div className="border-gray-2 border-t-2 p-4 flex justify-end space-x-2">
                  <button
                    onClick={() => {
                      setIsUserUpdatingInfo(false);
                    }}
                    className="bg-gray-2 text-orange font-semibold p-3 rounded">
                    Voltar
                  </button>
                  <FilledButton type={'submit'}>Atualizar</FilledButton>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <div>
            <h2 className=" border-gray-2 border-b-2 p-5 text-xl font-bold">
              Insira o código de verificação do {isEmailVerified ? 'celular' : 'email'}
            </h2>
            <div className="p-5">
              <p className="text-gray-5">
                Avise-nos se este {isEmailVerified ? 'celular' : 'email'} pertence a você. Insira o
                código de verificação enviado para{' '}
                <span className="text-black font-semibold">
                  {isEmailVerified ? signUpData.phone : signUpData.email}
                </span>
              </p>
              <input
                ref={inputRef}
                type="text"
                className="border-2 border-gray-2 px-4 p-4 w-4/12 block rounded my-3 focus:outline-gray-3"
                placeholder="PI-"
              />
              {count > 0 ? (
                <p className="text-gray-5">
                  {count} segundos para reenviar o {isEmailVerified ? 'código' : 'email'}
                </p>
              ) : (
                <button
                  onClick={resendOtp}
                  className="mb-3 text-orange cursor-pointer hover:text-orange/90 duration-200 transition-colors">
                  Enviar {isEmailVerified ? 'código' : 'email'} novamente
                </button>
              )}
            </div>
            <div className="border-gray-2 border-t-2 p-4 flex justify-end space-x-2">
              <button
                onClick={() => setIsUserUpdatingInfo(true)}
                className="bg-gray-2 text-orange font-semibold p-3 rounded">
                Atualizar informações de contacto
              </button>
              <FilledButton type="button" onClick={handleClick} loading={isLoading}>
                {isEmailVerified ? 'Cadastrar' : 'Confirmar'}
              </FilledButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OtpVerification;
