import React from 'react';

export default function RadioCardOption(props) {
  const { value, label, info, onClick } = props;

  return (
    <div
      onClick={onClick}
      className={`flex select-none text-sm cursor-pointer gap-2 p-3 rounded-[5px] border ${
        props.checked ? 'border-orange' : 'border-gray-2'
      }`}>
      <div>
        <input className="accent-orange" type="radio" value={value} {...props} />
      </div>
      <div>
        <label className="font-medium ">{label}</label>
        <div className="pt-5 text-gray-3">{info}</div>
      </div>
    </div>
  );
}
