import React, { useEffect } from 'react';
// import { StatusIcon } from '../../../assets/imgs';
import PersonIcon from '../../../assets/imgs/PersonIcon';
import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import { CUSTOMER_STATUS_COLOR } from '../../../utils/constants';
import useResponsivity from '../../../hooks/useResponsivity';
import { useNavigate } from 'react-router';
import { useStore } from '../../../store/useStore';
import Pagination from '../../../components/Pagination/Pagination';
import MoreActionGroup from './MoreActionGroup';

export default function GroupsTable({ data, loading = false }) {
  const { isMobile } = useResponsivity();
  const hiddenColumns = isMobile ? ['status', 'created_at', 'last_contact', 'description'] : [];
  const navigate = useNavigate();
  const selectGroups = useStore((state) => state.groups.selectGroups);
  const selectedGroups = useStore((state) => state.groups.selectedGroups);
  const resetGroupsSelected = useStore((state) => state.groups.resetGroupsSelected);

  const groupsCurrentPage = useStore((state) => state.groups.groupsCurrentPage);
  const groupsTotalPages = useStore((state) => state.groups.groupsTotalPages);
  const changeGroupsPage = useStore((state) => state.groups.changeGroupsPage);
  const setFilter = useStore((state) => state.customers.setFilter);

  const onClickRow = (row) => {
    setFilter('group', row.original.id);
    navigate('/clientes/');
  };

  const onSelectRow = (groups, checked) => {
    selectGroups(groups, checked);
  };

  useEffect(() => {
    return () => {
      resetGroupsSelected();
    };
  }, []);

  const columns = [
    {
      Header: 'Grupo',
      accessor: 'name', // accessor is the "key" in the data
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <div className="!w-6">
            <PersonIcon
              width={24}
              height={24}
              color={CUSTOMER_STATUS_COLOR[row.original.customer_situation]}
            />
          </div>
          {row.original.name}
        </div>
      )
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.description}</div>
        </div>
      )
    },
    {
      Header: 'Nr. de clientes',
      accessor: 'customers',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.custumers}</div>
        </div>
      )
    },
    {
      Header: 'Nr. de clientes devedores',
      accessor: 'inactive_clients',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.inadimplentes.length}</div>
        </div>
      )
    },
    {
      accessor: 'action',
      Cell: ({ row }) => (
        <div>
          <MoreActionGroup asDots={true} group={row.original} />
        </div>
      )
    }
  ];
  return (
    <>
      <DataTable
        loading={loading}
        onClickRow={onClickRow}
        onSelectRow={onSelectRow}
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
        selectedRows={selectedGroups}
      />
      <div className="mt-5 flex justify-center">
        <Pagination
          currentPage={groupsCurrentPage}
          totalPages={groupsTotalPages}
          changePage={changeGroupsPage}
        />
      </div>
    </>
  );
}
