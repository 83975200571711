import produce from 'immer';

export const createStepModalSlice = (set) => ({
  stepModal: {
    step: 1,
    setStep: (stepValue) => {
      set(
        produce((state) => {
          state.stepModal.step = stepValue;
        })
      );
    }
  }
});
