import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { AttachmentIcon } from '../../../assets/imgs';
import { api } from '../../../api/api';
import { toast } from 'react-toastify';

export default function ImportCustomersModal() {
  const showImportCustomersModal = useStore((state) => state.customers.showImportCustomersModal);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const closeImportCustomersModal = useStore((state) => state.customers.closeImportCustomersModal);
  const getCustomers = useStore((state) => state.customers.getCustomers);
  const inputRef = useRef();
  const uploadContainer = {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23EB6C4C' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: 14,
    padding: 10,
    color: Colors.Orange,
    cursor: 'pointer',
    textAlign: 'center'
  };
  const [file, setFile] = useState(null);

  const openSelectFile = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    return () => {
      setFile(null);
    };
  }, [showImportCustomersModal]);

  const uploadFile = () => {
    setIsUploadingFile(true);
    let formData = new FormData();
    formData.append('file', file);

    api
      .post(`/customers/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        const registered = response.data.customers.registeredCustomers;
        const failures = response.data.failures.countFailures;
        registered && toast.success(`${registered} clientes importados com sucesso!`);
        failures && toast.error(`${failures} clientes falharam ao importar!`);
      })
      .catch(() => {
        toast.error('Erro ao importar clientes');
      })
      .finally(() => {
        setIsUploadingFile(false);
        closeImportCustomersModal();
        getCustomers();
      });
  };

  return (
    <Modal
      title="Importar clientes"
      onClose={closeImportCustomersModal}
      show={showImportCustomersModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5 flex gap-5 flex-col">
          <div>
            Para importar clientes a partir de uma planilha, anexe um arquivo abaixo no formato do
            arquivo de exemplo presente.
          </div>
          <div>
            <div className="font-semibold">Arquivo de exemplo</div>
            <div className="underline" style={{ color: Colors.Blue }}>
              <a href={process.env.PUBLIC_URL + '/clientes.xlsx'} download>
                clientes.xlsx
              </a>
            </div>
          </div>
          <div style={uploadContainer} onClick={openSelectFile}>
            <div className="py-5">
              {file ? (
                <div className="flex justify-center items-center">
                  <AttachmentIcon color={Colors.Orange} />
                  <span className="ml-3 text-orange">{file?.name}</span>
                </div>
              ) : (
                'Clique para anexar o arquivo'
              )}
            </div>
          </div>
        </div>
        <input type="file" ref={inputRef} onChange={handleFileChange} className="hidden" />
      </div>
      <div className="flex p-5 justify-around">
        <FilledButton
          className="px-10"
          onClick={closeImportCustomersModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={uploadFile}
          loading={isUploadingFile}
          className="px-10"
          disabled={!file}>
          Importar
        </FilledButton>
      </div>
    </Modal>
  );
}
