import * as Icons from '../../../assets/imgs';
import useNavbarHovered from '../../../hooks/useNavbarHovered';

export default function NavbarCreateBillingButton({ onClick }) {
  const isNavbarHovered = useNavbarHovered();

  return (
    <button onClick={onClick} className="bg-orange text-white w-[100%]">
      <div className="flex items-center bg-orange relative px-3 left-[26px] rounded-r-[10px] h-[57px] font-medium md:overflow-hidden">
        <span
          className={`text-left font-medium text-lg mr-auto ml-[-10px] transition-all ease-in-out duration-700 ${
            !isNavbarHovered ? 'w-[250px] translate-x-[0px]' : 'w-[0] translate-x-[-250px]'
          }`}>
          Criar Cobrança
        </span>
        <div className="mr-8">
          <Icons.PlusIcon width={10} />
        </div>
      </div>
    </button>
  );
}
