import { useEffect, useState } from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';

const SelectGroupsToAssociate = () => {
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const groupsFormatted = useStore((state) => state.groups.groupsFormatted);
  const associateCustomersToGroups = useStore((state) => state.groups.associateCustomersToGroups);
  const dissociateCustomersFromGroups = useStore(
    (state) => state.groups.dissociateCustomersFromGroups
  );
  const selectedCustomers = useStore((state) => state.customers.selectedCustomers);
  const isAssociatingCustomersToGroupsLoading = useStore(
    (state) => state.customers.isAssociatingCustomersToGroupsLoading
  );
  const isDissociateCustomersFromGroupsLoading = useStore(
    (state) => state.customers.isDissociateCustomersFromGroupsLoading
  );
  useEffect(() => {
    setGroups(groupsFormatted);
  }, [groupsFormatted]);

  const handleSelectGroup = (group_id) => {
    if (selectedGroups.includes(group_id)) {
      setSelectedGroups(selectedGroups.filter((selectedGroup) => selectedGroup !== group_id));
    } else {
      setSelectedGroups([...selectedGroups, group_id]);
    }
  };
  const handleAssociate = () => {
    const selectedCustomersIds = selectedCustomers.map((customer) => customer.id);
    associateCustomersToGroups({
      customerIds: selectedCustomersIds,
      groupIds: selectedGroups
    });
  };

  const handleDissociate = () => {
    const selectedCustomersIds = selectedCustomers.map((customer) => customer.id);
    dissociateCustomersFromGroups({
      customerIds: selectedCustomersIds,
      groupIds: selectedGroups
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    const filteredGroups = groupsFormatted.filter((group) =>
      group.label.toLowerCase().includes(value.toLowerCase())
    );
    setGroups(filteredGroups);
  };
  return (
    <div className="p-5">
      <input
        type="text"
        placeholder="Pesquisar"
        onChange={handleSearchChange}
        className="w-full bg-gray-1 border-2 border-gray-3 focus:outline-none focus:border-orange transition-colors duration-300 p-3 rounded-lg"
      />
      <div className="bg-gray-1 rounded-lg max-h-52 mt-2 overflow-auto">
        {groups.map((group) => (
          <div
            key={group.value.id}
            className="flex items-center space-x-3 p-3 border-b border-gray-2">
            <input
              type="checkbox"
              className="accent-orange"
              onChange={() => handleSelectGroup(group.value.id)}
            />
            <p>{group.label}</p>
          </div>
        ))}
      </div>
      <div className="flex p-5 justify-around">
        <FilledButton
          className="px-10"
          onClick={handleDissociate}
          disabled={isAssociatingCustomersToGroupsLoading}
          loading={isDissociateCustomersFromGroupsLoading}
          bg={Colors.Red}
          color={Colors.White}>
          Desassociar
        </FilledButton>
        <FilledButton
          onClick={handleAssociate}
          disabled={isAssociatingCustomersToGroupsLoading}
          loading={isAssociatingCustomersToGroupsLoading}
          className="px-10"
          bg={Colors.Green}>
          Associar
        </FilledButton>
      </div>
    </div>
  );
};

export default SelectGroupsToAssociate;
