import { Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { useStore } from '../../../store/useStore';
import RadioCardOption from '../../../components/FormComponents/RadioCardOption/RadioCardOption';
import BorderedInput from '../../../components/FormComponents/Inputs/BorderedInput';
import BorderedMaskedInput from '../../../components/FormComponents/Inputs/BorderedMaskedInput';
import {
  maskToCurrency,
  numberToStringMasked,
  stringMaskedToNumber
} from '../../../utils/currencyHelper';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
// import BorderedSelect from '../../../components/FormComponents/SelectFields/BorderedSelect';
// import { BILLING_INTERVALS } from '../../../utils/constants';

export default function UpdateProductModal({ show, onClose }) {
  const isLoading = useStore((state) => state.products.isLoading);
  const updateProduct = useStore((state) => state.products.updateProduct);
  const selectedProduct = useStore((state) => state.products.selectedProduct);
  const calculateInstallmentValue = (value, numberOfInstallments) => {
    let installmentValue = value / numberOfInstallments;
    installmentValue = numberToStringMasked(installmentValue);
    return installmentValue;
  };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <Modal title={'Atualizar produto'} show={show} onClose={onClose} noDefaultHeight>
      <Formik
        initialValues={{
          item: '',
          billingValue: '',
          description: '',
          paymentType: 'signature',
          billingInterval: '1',
          installmentValue: '',
          numberOfInstallments: '1'
        }}
        validationSchema={Yup.object({
          item: Yup.string().required('Campo obrigatório').min(2, 'Mínimo 2 caracteres'),
          billingValue: Yup.string().required('Campo obrigatório'),
          description: Yup.string(),
          paymentType: Yup.string().required('Campo obrigatório'),
          billingInterval: Yup.string().when('paymentType', {
            is: (val) => val == 'signature',
            then: (schema) => schema.required('Campo obrigatório'),
            otherwise: (schema) => schema
          }),
          installmentValue: Yup.string(),
          numberOfInstallments: Yup.string().when('paymentType', {
            is: (val) => val == 'in_cash_or_installments',
            then: (schema) => schema.required('Campo obrigatório'),
            otherwise: (schema) => schema
          })
        })}
        onSubmit={(values) => {
          const productId =
            selectedProduct?.item?.product_id ?? selectedProduct?.company_plan?.product_id;
          updateProduct(productId, values);
        }}>
        {(formik) => {
          useEffect(() => {
            formik.setValues({
              ...formik.values,
              item: selectedProduct.name,
              description: selectedProduct.description,
              billingValue: numberToStringMasked(selectedProduct.price),
              paymentType: selectedProduct.company_plan ? 'signature' : 'in_cash_or_installments',
              billingInterval: selectedProduct?.company_plan?.interval ?? 1
            });
          }, []);

          return (
            <form
              onSubmit={formik.handleSubmit}
              className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
              <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
                <div className="flex gap-3">
                  <div className="flex-1">
                    <BorderedInput
                      placeholder="Digite um item"
                      type="text"
                      label="Item ou Plano"
                      error={errorMessage(formik, 'item')}
                      {...formik.getFieldProps('item')}
                    />
                  </div>
                  <div className="flex-1">
                    <BorderedMaskedInput
                      placeholder="0,00"
                      prefix="MZN"
                      type="text"
                      label="Valor"
                      mask="99999999999999"
                      beforeMaskedStateChange={maskToCurrency}
                      alwaysShowMask={false}
                      maskPlaceholder={null}
                      error={errorMessage(formik, 'billingValue')}
                      {...formik.getFieldProps('billingValue')}
                      onChange={(e) => {
                        let billingValue = e.target.value;
                        formik.setFieldValue('billingValue', billingValue);
                        formik.setFieldError('billingValue', null, false);
                        formik.setFieldTouched('billingValue', null, false);

                        billingValue = stringMaskedToNumber(billingValue);
                        let installmentValue = calculateInstallmentValue(
                          billingValue,
                          stringMaskedToNumber(formik.values.numberOfInstallments)
                        );

                        formik.setFieldValue('installmentValue', installmentValue);
                      }}
                    />
                  </div>
                </div>
                <BorderedInput
                  placeholder="O que você vai cobrar?"
                  textarea
                  labelInfo=" (opcional)"
                  type="text"
                  label="Descrição"
                  error={errorMessage(formik, 'description')}
                  {...formik.getFieldProps('description')}
                />
                <div className="font-medium text-lg">Qual será a forma de pagamento?</div>
                <div className="flex gap-3">
                  <RadioCardOption
                    label="Assinatura"
                    disabled
                    checked={formik.values.paymentType === 'signature'}
                    info="Cobranças recorrentes em um intervalo de tempo definido por você."
                    value="signature"
                  />
                  <RadioCardOption
                    label="À vista ou parcelado"
                    disabled
                    checked={formik.values.paymentType === 'in_cash_or_installments'}
                    info="Cobranças únicas podem ser pagas à vista ou de forma parcelada."
                    value="in_cash_or_installments"
                  />
                </div>
              </div>

              <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
                <FilledButton onClick={onClose} bg={Colors.Gray1} color={Colors.Orange}>
                  Cancelar
                </FilledButton>
                <FilledButton type="submit" loading={isLoading}>
                  Avançar
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
