import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Colors } from '../../utils/colors';
import Select, { components } from 'react-select';
import { CalendarIcon, ChevronDownIcon, ChevronUpIcon } from '../../assets/imgs';
import moment from 'moment';

const options = [
  { label: 'Hoje', value: 'today' },
  { label: 'Próximos 7 dias', value: '7d' },
  { label: 'Próximas 4 semanas', value: '4w' },
  { label: 'Próximos 3 meses', value: '3m' },
  { label: 'Próximos 12 meses', value: '12m' },
  { label: 'Personalizado', value: 'custom' }
];

export default function DateRangeFilter({ onChange }) {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, 'month'));
  const [period, setPeriod] = useState('4w');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const onChangePeriod = (periodOption) => {
    setShowDatePicker(false);
    const periodValue = periodOption.value;
    setPeriod(periodValue);
    if (periodValue === 'today') {
      setStartDate(moment().startOf('day'));
      setEndDate(moment().endOf('day'));
    } else if (periodValue === '7d') {
      setStartDate(moment());
      setEndDate(moment().add(7, 'days'));
    } else if (periodValue === '4w') {
      setStartDate(moment());
      setEndDate(moment().add(4, 'weeks'));
    } else if (periodValue === '3m') {
      setStartDate(moment());
      setEndDate(moment().add(3, 'months'));
    } else if (periodValue === '12m') {
      setStartDate(moment());
      setEndDate(moment().add(12, 'months'));
    } else if (periodValue === 'custom') {
      setStartDate(null);
      setEndDate(null);

      setShowDatePicker(true);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      let startDateISO = startDate.toISOString();
      let endDateISO = endDate.toISOString();
      onChange([startDateISO, endDateISO]);
    }
  }, [startDate, endDate]);

  const onChangeDatePicker = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setShowDatePicker(false);
      setPeriod('custom');
    }
  };

  const renderDate = () => {
    if (!startDate && !endDate) {
      return '';
    }
    if (period === 'today' || (startDate && !endDate)) {
      return `${moment(startDate).format('MMM DD, YYYY')}`;
    }
    return `${moment(startDate).format('MMM DD') ?? ''} - ${
      moment(endDate).format('MMM DD') ?? ''
    }`;
  };

  return (
    <div>
      <div className="flex items-center">
        <Select
          onMenuClose={() => setIsSelectOpen(false)}
          onMenuOpen={() => setIsSelectOpen(true)}
          components={{
            DropdownIndicator: ({ ...props }) => (
              <components.DropdownIndicator {...props}>
                <div className="pr-2 flex items-center">
                  {!isSelectOpen ? (
                    <ChevronDownIcon color={Colors.black} />
                  ) : (
                    <ChevronUpIcon color={Colors.black} />
                  )}
                </div>
                <div className="relative">
                  <div className="absolute bg-gray-2 h-[40px] mt-[-10px]"></div>
                </div>
              </components.DropdownIndicator>
            )
          }}
          styles={{
            menu: (base) => ({
              ...base,
              width: 'max-content',
              minWidth: '100%',
              zIndex: 200
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              outline: 'none',
              boxShadow: 'none',
              fontSize: 14,
              height: 42,
              borderColor: Colors.Gray2,
              '&:hover': {
                boxShadow: 'none'
              },
              '&:focus': {
                borderColor: Colors.Gray2
              }
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              width: 130,
              padding: '0 6px'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: Colors.Gray6,
              padding: 0
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              padding: 6
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: '#fff',
              color: '#333',
              '&:hover': {
                backgroundColor: '#f8f9fa'
              }
            }),
            indicatorContainer: () => ({
              padding: 0
            }),
            indicatorSeparator: () => ({})
          }}
          options={options}
          // placeholder={placeholder}
          isSearchable={false}
          value={options ? options.find((option) => option.value === period) : ''}
          onChange={onChangePeriod}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />

        <div
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            setShowDatePicker(true);
          }}
          className="flex gap-2 cursor-pointer min-w-[160px] text-gray-6 border border-l-0 p-2 border-gray-2 rounded-r pl-2">
          <CalendarIcon color={Colors.Gray6} />
          <div>{renderDate()}</div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute top-[8px] right-0 z-10">
          {showDatePicker ? (
            <DatePicker
              selected={startDate}
              inline
              locale="ptBR"
              startDate={startDate}
              endDate={endDate}
              selectsRange
              onChange={onChangeDatePicker}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
