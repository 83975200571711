import React from 'react';
import { Colors } from '../../utils/colors';

export default function CancelIcon({ width = 12, height = 8, color = Colors.black }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.5 4.75H9.25C10.3546 4.75 11.25 3.85457 11.25 2.75V1"
        stroke={color}
        strokeLinecap="round"
      />
      <path d="M3 7L0.75 4.75L3 2.5" stroke={color} strokeLinecap="round" />
    </svg>
  );
}
