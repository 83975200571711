import React from 'react';
import { useStore } from '../../../store/useStore';
import { useNavigate } from 'react-router-dom';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';

export default function DeleteInvoiceModal() {
  const deleteInvoice = useStore((state) => state.billings.deleteInvoice);
  const showDeleteInvoiceModal = useStore((state) => state.billings.showDeleteInvoiceModal);
  const closeDeleteInvoiceModal = useStore((state) => state.billings.closeDeleteInvoiceModal);
  const isDeleteInvoiceLoading = useStore((state) => state.billings.isDeleteInvoiceLoading);

  const navigate = useNavigate();

  const handleDeleteInvoice = () => {
    deleteInvoice(navigate);
  };

  return (
    <Modal
      title="Apagar cobrança"
      onClose={closeDeleteInvoiceModal}
      show={showDeleteInvoiceModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          Tem certeza que deseja apagar essa cobrança? Depois de feito, você não pode desfazer essa.
        </div>
      </div>
      <div className="flex p-5 justify-around">
        <FilledButton
          className="px-10"
          onClick={closeDeleteInvoiceModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={handleDeleteInvoice}
          loading={isDeleteInvoiceLoading}
          className="px-10"
          bg={Colors.Red}>
          Apagar
        </FilledButton>
      </div>
    </Modal>
  );
}
