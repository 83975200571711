// import React from 'react';
// import { useNavigate } from 'react-router-dom';

import React from 'react';
import { formatCurrency } from '../../utils/currencyHelper';

// export default function CreditCard({ title, value }) {
//   const navigate = useNavigate();
//   return (
//     <div
//       onClick={() => navigate('/meus-creditos')}
//       className="rounded-[10px] flex flex-col justify-evenly pt-2 text-center border border-gray-2 w-full md:w-[170px] cursor-pointer">
//       <div className="mx-1 font-medium text-sm md:text-base">{title}</div>
//       <div className="mx-1 font-semibold text-base md:text-2xl">{value}</div>
//     </div>
//   );
// }

export default function CreditCard({ wallet }) {
  const [visible, setVisible] = React.useState(false);
  return (
    <div className="group relative ml-auto">
      <div className="flex items-center gap-4 text-gray-500 cursor-pointer">
        <div>
          <h3 className="">Saldo em conta</h3>
          <span className={`text-lg font-semibold`}>
            MZN{' '}
            <span className={` ${wallet?.balance < 0 && visible && 'text-red'}`}>
              {visible && !isNaN(wallet?.balance) ? formatCurrency(wallet?.balance) : '****'}
            </span>
          </span>
        </div>
        <span className="relative">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`absolute transition-opacity duration-300 ${
              visible ? 'opacity-0 delay-300' : 'opacity-100'
            } transition-all duration-300`}
            onClick={() => setVisible(!visible)}>
            <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            onClick={() => setVisible(!visible)}
            className={`transition-opacity duration-300 ${!visible ? 'opacity-0' : 'opacity-100'}`}>
            <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24" />
            <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
            <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" />
            <line x1="2" x2="22" y1="2" y2="22" />
          </svg>
        </span>
      </div>
      <div
        className={`absolute right-0 w-80 top-[140%] opacity-0 z-50 ${
          visible && 'group-hover:visible group-hover:opacity-100 group-hover:top-[115%]'
        } transition-all duration-300 p-5 text-gray-600 border border-gray-300 rounded bg-white invisible`}>
        <h3 className="font-medium">{wallet?.name}</h3>
        <div className="flex flex-col mt-2">
          <span>
            Emails / SMS:{' '}
            <span className="font-semibold">{Math.floor(wallet?.notifications?.email) ?? '-'}</span>
          </span>
          <span>
            Whatsapp:{' '}
            <span className="font-semibold">
              {Math.floor(wallet?.notifications?.whatsapp) ?? '-'}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
