import React, { useEffect } from 'react';
import SelectFilter from '../../components/Filters/SelectFilter';

import { useStore } from '../../store/useStore';
import { DEDUCTION_TYPES } from '../../utils/constants';

import CreditsTable from './components/CreditsTable';
import { FilterIcon, PlusIcon } from '../../assets/imgs';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import OutlinedButton from '../../components/FormComponents/Buttons/OutlinedButton';
import { Colors } from '../../utils/colors';
import AddCreditModal from './components/AddCreditModal';
import PageHeader from '../../components/PageHeader/PageHeader';
// import moment from 'moment';

export default function MyCredits() {
  const isLoading = useStore((state) => state.credits.isLoading);
  const isInfiniteScrollLoading = useStore((state) => state.credits.isInfiniteScrollLoading);

  const listTransactions = useStore((state) => state.credits.listTransactions);
  const resetCredits = useStore((state) => state.credits.resetCredits);
  const credits = useStore((state) => state.credits.credits);
  const company = useStore((state) => state.company.company);

  const setFilter = useStore((state) => state.credits.setFilter);
  const status = useStore((state) => state.credits.filters.status);
  const dateFilter = useStore((state) => state.credits.filters.date);

  const toggleShowAddCreditsModal = useStore((state) => state.credits.toggleShowAddCreditsModal);
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      if (isLoading) {
        return;
      }
      listTransactions(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      resetCredits();
    };
  }, []);

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate && company) {
      listTransactions();
    }
    // return () => {
    // setFilter('status', 'approved');
    // setFilter('date', { startDate: moment().subtract(7, 'days'), endDate: moment() });
    // };
  }, [status, company, dateFilter]);

  const handleStatusChange = (status) => {
    setFilter('status', status.value);
    setFilter('cursor', '');
    setFilter('hasMore', true);
  };

  const handleChangeDateRangeFilter = ([startDate, endDate]) => {
    setFilter('cursor', '');
    setFilter('hasMore', true);
    setFilter('date', { startDate, endDate });
  };

  return (
    <div>
      <div>
        <PageHeader title="Extrato" />
        <div className="flex gap-4 flex-wrap">
          <DateRangeFilter onChange={handleChangeDateRangeFilter} />
          <SelectFilter
            iconComponent={FilterIcon}
            onChange={handleStatusChange}
            options={DEDUCTION_TYPES}
            placeholder="Tipo"
          />
          <OutlinedButton onClick={toggleShowAddCreditsModal} className="px-5">
            <div className="flex gap-3 items-center h-[40px]">
              <div className="font-semibold">Adicionar Crédito</div>
              <PlusIcon color={Colors.Orange} />
            </div>
          </OutlinedButton>
        </div>
        <div className="max-w-[100%] mt-5">
          <CreditsTable data={credits} loading={isLoading} />
        </div>
        <div className="flex items-center justify-center h-20">
          <div
            className={`text-center text-gray-500 flex gap-2 items-center ${
              isInfiniteScrollLoading ? 'opacity-100' : 'opacity-0'
            } transition-opacity duration-150`}>
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="animate-spin stroke-orange">
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>{' '}
            <span className="text-lg font-medium">Carregando...</span>
          </div>
        </div>
      </div>
      <AddCreditModal />
    </div>
  );
}
