import { useEffect, useRef, useState } from 'react';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import { Formik } from 'formik';
import Input from '../../components/FormComponents/Inputs/Input';
import * as Yup from 'yup';
import { useStore } from '../../store/useStore';
import { useNavigate } from 'react-router-dom';

const AccountRecovery = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(30);
  const inputRef = useRef(null);
  const [email, setEmail] = useState('');
  const recoveryAccount = useStore((state) => state.auth.recoveryAccount);
  const confirmRecoveryCode = useStore((state) => state.auth.confirmRecoveryCode);
  const isLoading = useStore((state) => state.auth.isLoading);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    if (count === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [count]);

  const resendRecoveryCode = () => {
    setCount(30);
    recoveryAccount(email);
  };
  const handleClick = () => {
    const code = inputRef.current?.value;
    if (code.length > 3) {
      confirmRecoveryCode({ email, code }, navigate);
      inputRef.current.value = '';
    }
  };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';
  return (
    <div className="h-screen flex justify-center items-center bg-[#E9EBEE]">
      <div className="w-11/12 md:w-6/12 xl:w-4/12 shadow bg-white rounded-xl">
        {!email ? (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string().email('Endereço de email inválido').required('Campo obrigatório')
            })}
            onSubmit={(values) => {
              setEmail(values.email);
              recoveryAccount(values.email);
            }}>
            {(formik) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}>
                <h2 className=" border-gray-2 border-b-2 p-5 text-xl font-bold">
                  Informações da conta
                </h2>
                <div className="p-5 space-y-7">
                  <div>
                    <p className="mb-4">Por favor, informe-nos o seu email</p>
                    <Input
                      placeholder="Digite seu Email"
                      type="email"
                      label="Email"
                      error={errorMessage(formik, 'email')}
                      {...formik.getFieldProps('email')}
                    />
                  </div>
                </div>
                <div className="border-gray-2 border-t-2 p-4 flex justify-end space-x-2">
                  <button
                    onClick={() => {
                      window.location.replace('/');
                    }}
                    className="bg-gray-2 text-orange font-semibold p-3 rounded">
                    Voltar
                  </button>
                  <FilledButton type={'submit'} loading={isLoading}>
                    Confirmar
                  </FilledButton>
                </div>
              </form>
            )}
          </Formik>
        ) : (
          <div>
            <h2 className=" border-gray-2 border-b-2 p-5 text-xl font-bold">
              Insira o código de verificação
            </h2>
            <div className="p-5">
              <p className="text-gray-5">
                Avise-nos se este email pertence a você. Insira o código de verificação enviado para{' '}
                <span className="text-black font-semibold">{email}</span>
              </p>
              <input
                ref={inputRef}
                type="text"
                className="border-2 border-gray-2 px-4 p-4 w-4/12 block rounded my-3 focus:outline-gray-3"
                placeholder="PI-"
              />
              {count > 0 ? (
                <p className="text-gray-5">{count} segundos para reenviar o código</p>
              ) : (
                <button
                  onClick={resendRecoveryCode}
                  className="mb-3 text-orange cursor-pointer hover:text-orange/90 duration-200 transition-colors">
                  Enviar código novamente
                </button>
              )}
            </div>
            <div className="border-gray-2 border-t-2 p-4 flex justify-end space-x-2">
              <button
                onClick={() => {
                  setEmail('');
                }}
                className="bg-gray-2 text-orange font-semibold p-3 rounded">
                Voltar
              </button>
              <FilledButton type="button" onClick={handleClick} loading={isLoading}>
                Confirmar
              </FilledButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountRecovery;
