import produce from 'immer';
import { toast } from 'react-toastify';
import dashboardService from '../../services/dashboardService';

export const createDashboardSlice = (set, get) => ({
  dashboard: {
    isDashboardLoading: false,
    graph: [],
    customers: null,
    installments: null,
    invoicing: null,
    filters: {
      date: {
        startDate: '',
        endDate: ''
      },
      date_type: 'dueDate'
    },
    setFilter: (filter, value) => {
      set(
        produce((state) => {
          state.dashboard.filters[filter] = value;
        })
      );
    },
    getDashboard: () => {
      const dateType = {};
      dateType[get().dashboard.filters.date_type] = true;
      set(
        produce((state) => {
          state.dashboard.isDashboardLoading = true;
        })
      );
      dashboardService
        .getDashboard({
          ...get().dashboard.filters.date,
          ...dateType
        })
        .then((data) => {
          set(
            produce((state) => {
              state.dashboard.customers = data.customers;
              state.dashboard.installments = data.installments;
              state.dashboard.invoicing = data.invoicing;
              state.dashboard.graph = data.graph;
            })
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        })
        .finally(() => {
          set(
            produce((state) => {
              state.dashboard.isDashboardLoading = false;
            })
          );
        });
    }
  }
});
