import React from 'react';
import { PiminderLargeLogo } from '../../assets/imgs';
import MenuIcon from '../../assets/imgs/menu-icon.png';
import { useStore } from '../../store/useStore';

export default function NavbarMobile() {
  const company = useStore((state) => state.company.company);
  const setShowNavbar = useStore((state) => state.navbar.setShowNavbar);
  const showNavbar = useStore((state) => state.navbar.showNavbar);
  const toggleShowVerifyAccountModal = useStore(
    (state) => state.company.toggleShowVerifyAccountModal
  );

  const toggleNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <>
      <div className="md:hidden grid grid-cols-2 w-full bg-black py-2 px-6">
        <div className="flex gap-3 items-center">
          <span className="font-semibold text-3xl text-white">
            <img width={96} src={PiminderLargeLogo} />
          </span>
        </div>
        <div onClick={toggleNavbar} className="justify-self-end self-center cursor-pointer">
          <img width={24} height={24} src={MenuIcon} />
        </div>
      </div>
      {!company?.isVerified && (
        <div className="md:hidden bg-orange text-white text-center">
          Verifique o seu email para extrair o máximo da Piminder.
          <button onClick={toggleShowVerifyAccountModal} className="underline ml-2">
            Confirmar email
          </button>
        </div>
      )}
    </>
  );
}
