export function formatFormOptions(options, key = null) {
  return options.map((option) => {
    return { value: option.id, label: key ? option[key] : option.name };
  });
}

export function formatFormObjectOptions(options, labelKey) {
  return options.map((option) => {
    return { value: option, label: option[labelKey] };
  });
}

export function formatCompanySignup(inputObj) {
  const formattedObj = {
    account: {
      name: inputObj.company,
      phone: inputObj.phone,
      email: inputObj.email,
      password: inputObj.password,
      is_ei: inputObj.isIndividualCompany === 'yes',
      owner_name: inputObj.owner,
      company_type_id: inputObj.companyType,
      nuit: inputObj.nuit
    },
    address: {
      country_id: 1,
      city_id: inputObj.city,
      region_id: inputObj.province,
      postal_code: inputObj.postalCode,
      street: inputObj.street,
      complement: inputObj.complement
    },
    payment: {
      numerary: inputObj.receivePaymentMethods.includes('numberary'),
      banks: inputObj.banks.map((bank) => ({
        bank_id: bank.name,
        account_number: bank.accountNumber,
        nib: bank.nib
      })),
      wallets: inputObj.mobileWallets.map((wallet) => ({
        wallet_id: wallet.wallet,
        account_number: wallet.number
      }))
    }
  };

  return formattedObj;
}

export function formatCustomerSave(obj, id = null) {
  let formattedObject = {
    customer_data: {
      first_name: obj.firstName,
      last_name: obj.lastName,
      phone: obj.phone,
      identification_number: obj.bi,
      email: obj.email,
      birthday:
        obj.birthday.year && obj.birthday.month && obj.birthday.day
          ? `${obj.birthday.year}-${obj.birthday.month}-${obj.birthday.day}`
          : '',
      work_where: obj.company,
      reference: obj.referenceId,
      groups: obj.groups
    },
    address: {
      country_id: 1, // Replace with the appropriate country ID
      city_id: obj.address.city || 1, // Replace with the appropriate city ID
      region_id: obj.address.province || 1, // send the id, region_id: 2
      postal_code: obj.address.postalCode,
      street: obj.address.street,
      complement: obj.address.apartment
    }
  };

  if (id) formattedObject.customer_data.id = id;

  return formattedObject;
}

export function formatCustomerData(obj) {
  const formattedObj = {
    firstName: obj?.first_name ?? '',
    lastName: obj?.last_name ?? '',
    phone: obj?.phone ?? '',
    email: obj?.email ?? '',
    bi: obj?.identification_number ?? '',
    company: obj.work_where ?? '',
    referenceId: obj.reference ?? '',
    address: {
      street: obj.customer_address?.address?.street ?? '',
      apartment: obj.customer_address?.address?.complement ?? '',
      city: obj.customer_address?.address?.city?.id ?? '',
      province: obj.customer_address?.address?.region?.id ?? '', // receive the id, region_id: 2
      postalCode: obj.customer_address?.address?.postal_code ?? ''
    },
    status: obj.customer_situation,
    groups: '',
    birthday: {
      month: '',
      day: '',
      year: ''
    }
  };

  if (obj.birthday) {
    const date = new Date(obj.birthday);
    formattedObj.birthday.month = date.getMonth() + 1;
    formattedObj.birthday.day = date.getDate();
    formattedObj.birthday.year = date.getFullYear();
  }

  return formattedObj;
}

export function formatBillingCreationData(inputObject) {
  const billingValue = inputObject.billingValue.replace(/\./g, '').replace(',', '.');

  let outputObject = {
    first_due_date: inputObject.dueDate,
    installments_number: inputObject?.numberOfInstallments
      ? parseInt(inputObject.numberOfInstallments)
      : 1,
    product: {
      name: inputObject.item,
      price: parseFloat(billingValue),
      description: inputObject.description,
      is_signature: inputObject.paymentType === 'signature',
      // installmentValue
      interval: parseInt(inputObject.billingInterval),
      signature_end: inputObject.finishSignatureDate ? inputObject.finishSignatureDate : '',
      save: inputObject.saveItem === 'yes',
      paid_where: inputObject.paymentType === 'signature' ? inputObject.paidWhere : null
    },
    interest: {},
    customer: {
      customer_name: inputObject.customerName,
      email: inputObject.customerEmail,
      phone: inputObject.customerPhone,
      identification_number: inputObject.customerBI
    },
    notifications: {
      before: {
        days: inputObject.daysBeforeDueDate ? parseInt(inputObject.daysBeforeDueDate) : 5,
        whatsapp: inputObject.daysBeforeDueDateNotifications.includes('whatsapp'),
        email: inputObject.daysBeforeDueDateNotifications.includes('email'),
        sms: inputObject.daysBeforeDueDateNotifications.includes('sms')
      },
      due_date: {
        whatsapp: inputObject.dueDateNotifications.includes('whatsapp'),
        email: inputObject.dueDateNotifications.includes('email'),
        sms: inputObject.dueDateNotifications.includes('sms')
      },
      after: {
        days: inputObject.daysAfterDueDate ? parseInt(inputObject.daysAfterDueDate) : 5,
        whatsapp: inputObject.daysAfterDueDateNotifications.includes('whatsapp'),
        email: inputObject.daysAfterDueDateNotifications.includes('email'),
        sms: inputObject.daysAfterDueDateNotifications.includes('sms')
      }
    }
  };

  if (inputObject.isItemRegistered === 'yes') {
    outputObject.product.product_id = inputObject.productId;
  }

  if (inputObject.isCustomerRegistered === 'yes') {
    outputObject.customer.customer_id = inputObject.customerId;
  }

  return outputObject;
}

export function formatBillingsCreationData(inputObject) {
  const billingValue = inputObject.billingValue.replace(/\./g, '').replace(',', '.');

  let outputObject = {
    product: {
      name: inputObject.item,
      price: parseFloat(billingValue),
      description: inputObject.description,
      is_signature: inputObject.paymentType === 'signature',
      // installmentValue
      installments: inputObject?.numberOfInstallments
        ? parseInt(inputObject.numberOfInstallments)
        : 1,
      interval: parseInt(inputObject.billingInterval),
      first_due_date: inputObject.dueDate,
      signature_end: inputObject.finishSignatureDate ? inputObject.finishSignatureDate : '',
      save: inputObject.saveItem === 'yes',
      paid_where: inputObject.paymentType === 'signature' ? inputObject.paidWhere : null
    },
    interest: {},
    customers_id: inputObject.selectedCustomers.map((c) => c.id),
    notifications: {
      before: {
        days: inputObject.daysBeforeDueDate ? parseInt(inputObject.daysBeforeDueDate) : 5,
        whatsapp: inputObject.daysBeforeDueDateNotifications.includes('whatsapp'),
        email: inputObject.daysBeforeDueDateNotifications.includes('email'),
        sms: inputObject.daysBeforeDueDateNotifications.includes('sms')
      },
      due_date: {
        whatsapp: inputObject.dueDateNotifications.includes('whatsapp'),
        email: inputObject.dueDateNotifications.includes('email'),
        sms: inputObject.dueDateNotifications.includes('sms')
      },
      after: {
        days: inputObject.daysAfterDueDate ? parseInt(inputObject.daysAfterDueDate) : 5,
        whatsapp: inputObject.daysAfterDueDateNotifications.includes('whatsapp'),
        email: inputObject.daysAfterDueDateNotifications.includes('email'),
        sms: inputObject.daysAfterDueDateNotifications.includes('sms')
      }
    }
  };

  if (inputObject.isItemRegistered === 'yes') {
    outputObject.product.product_id = inputObject.productId;
  }

  if (inputObject.isCustomerRegistered === 'yes') {
    outputObject.customer.customer_id = inputObject.customerId;
  }

  return outputObject;
}

export function formatPaymentMethods(originalObject) {
  const transformedObject = {
    numerary: originalObject.receivePaymentMethods.includes('numberary'),
    banks: originalObject.banks.map((bank) => ({
      id: bank.external_id,
      bank_id: bank.name,
      account_number: bank.accountNumber,
      nib: bank.nib
    })),
    wallets: originalObject.mobileWallets.map((wallet) => ({
      id: wallet.external_id,
      wallet_id: wallet.wallet,
      account_number: wallet.number
    }))
  };

  return transformedObject;
}

export function formatUpdateCompany(originalObject) {
  const transformedObject = {
    company_data: {
      email: originalObject.email,
      name: originalObject.name,
      nuit: originalObject.nuit,
      owner_name: originalObject.owner_name,
      phone: originalObject.phone,
      is_ei: originalObject.is_ei
    },
    address: {
      city_id: originalObject.address.city,
      region_id: originalObject.address.province,
      street: originalObject.address.street,
      complement: originalObject.address.apartment,
      postal_code: originalObject.address.postal_code
    }
  };

  return transformedObject;
}

export function formatGetCompany(originalObject) {
  const transformedObject = {
    company_data: {
      email: originalObject.email,
      name: originalObject.name,
      nuit: originalObject.nuit,
      owner_name: originalObject.owner_name,
      phone: originalObject.phone,
      is_ei: originalObject.is_ei
    },
    address: {
      city: originalObject.address.city?.id,
      province: originalObject.address.region?.id,
      street: originalObject.address.street,
      apartment: originalObject.address.complement,
      postal_code: originalObject.address.postal_code
    },
    plan: originalObject.plan
  };

  return transformedObject;
}
