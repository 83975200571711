import React from 'react';

export default function PersonIcon({ width = 15, height = 15, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.27272 7.27273C9.28102 7.27273 10.9091 5.64467 10.9091 3.63636C10.9091 1.62806 9.28102 0 7.27272 0C5.26441 0 3.63635 1.62806 3.63635 3.63636C3.63635 5.64467 5.26441 7.27273 7.27272 7.27273Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27273 8.18182C4.84545 8.18182 0 9.40001 0 11.8182V14.5455H14.5455V11.8182C14.5455 9.40001 9.7 8.18182 7.27273 8.18182Z"
        fill={color}
      />
    </svg>
  );
}
