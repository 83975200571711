import { Field, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FilledButton from '../../FormComponents/Buttons/FilledButton';
import BorderedMaskedInput from '../../FormComponents/Inputs/BorderedMaskedInput';
import BorderedInput from '../../FormComponents/Inputs/BorderedInput';
import RadioButton from '../../FormComponents/RadioButton/RadioButton';
import BorderedAsyncSelect from '../../FormComponents/SelectFields/BorderedAsyncSelect';
import { onlyPositiveNumbers } from '../../../utils/currencyHelper';
import Checkbox from '../../FormComponents/Checkbox/Checkbox';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';
import { toast } from 'react-toastify';
import CustomError from './CustomError';

export default function Step3({ show, onNext, onPrevious }) {
  const [showError, setShowError] = React.useState(false);
  const updateCreateBillingData = useStore((state) => state.billings.updateCreateBillingData);
  const getCustomerOptions = useStore((state) => state.customers.getCustomerOptions);
  const customer = useStore((state) => state.customers.customer);
  const isClientSelected = customer.id !== null;
  const getCustomerOptionsCallback = async (inputValue) => {
    const options = await getCustomerOptions({ search: inputValue });
    return options;
  };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <Formik
      initialValues={
        isClientSelected
          ? {
              isCustomerRegistered: 'yes',
              customerId: customer.id,
              customerName: `${customer.firstName} ${customer.lastName}`,
              customerEmail: customer.email,
              customerPhone: customer.phone,
              customerBI: customer.bi,
              daysBeforeDueDate: '3',
              daysBeforeDueDateNotifications: [],
              dueDateNotifications: [],
              daysAfterDueDate: '3',
              daysAfterDueDateNotifications: []
            }
          : {
              isCustomerRegistered: 'no',
              customerId: null,
              customerName: '',
              customerEmail: '',
              customerPhone: '',
              customerBI: '',
              daysBeforeDueDate: '3',
              daysBeforeDueDateNotifications: [],
              dueDateNotifications: [],
              daysAfterDueDate: '3',
              daysAfterDueDateNotifications: []
            }
      }
      validationSchema={Yup.object({
        isCustomerRegistered: Yup.string(),
        customerName: Yup.string()
          .required('Campo obrigatório')
          .matches(/^[\p{L}']+([\p{L} ,.'-]*[\p{L} ]+)*$/u, 'Nome inválido'),
        customerEmail: Yup.string().email('Endereço de email inválido').nullable(),
        // .required('Campo obrigatório'),
        // customerBI: Yup.string()
        //   .matches(/^\d{12}[A-Z]{1}$/, 'O BI deve possuir 12 números e 1 letra maiúscula ao final')
        //   .required('Campo obrigatório'),
        customerPhone: Yup.string().nullable(),
        // .matches(
        //   /^\+258 (86|87|84|82|83|85) \d\d\d \d\d\d\d$/,
        //   'O telefone deve ter o formato +258 XX XXX XXXX'
        // )
        // .required('Campo obrigatório'),
        daysBeforeDueDate: Yup.string(),
        daysBeforeDueDateNotifications: Yup.array(),
        dueDateNotifications: Yup.array(),
        daysAfterDueDate: Yup.string(),
        daysAfterDueDateNotifications: Yup.array()
      })}
      onSubmit={(values) => {
        if (!values.customerEmail && values.customerPhone.length < 9) {
          toast.error('Preencha pelo menos o email ou telefone');
          return;
        }
        if (
          values.daysAfterDueDateNotifications.length === 0 &&
          values.dueDateNotifications.length === 0 &&
          values.daysBeforeDueDateNotifications.length === 0
        ) {
          if (!showError) setShowError(true);
          return;
        }
        updateCreateBillingData(values);
        onNext();
      }}>
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
          <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
            <>
              <div className="font-medium text-lg">Quem você irá cobrar?</div>
              <div className="flex gap-3">
                <div className="font-medium">É um cliente já cadastrado?</div>
                <RadioButton
                  label="Sim"
                  checked={formik.values.isCustomerRegistered === 'yes'}
                  value="yes"
                  onClick={() => {
                    formik.setFieldValue('isCustomerRegistered', 'yes');
                    formik.setFieldValue('customerName', '');
                    formik.setFieldValue('customerEmail', '');
                    formik.setFieldValue('customerPhone', '');
                    formik.setFieldValue('customerBI', '');
                  }}
                />
                <RadioButton
                  label="Não"
                  checked={formik.values.isCustomerRegistered === 'no'}
                  value="no"
                  onClick={() => {
                    formik.setFieldValue('isCustomerRegistered', 'no');
                    formik.setFieldValue('customerName', '');
                    formik.setFieldValue('customerEmail', '');
                    formik.setFieldValue('customerPhone', '');
                    formik.setFieldValue('customerBI', '');
                  }}
                />
              </div>
              <div className="flex gap-3">
                <div className="flex-1">
                  {formik.values.isCustomerRegistered === 'no' ? (
                    <BorderedInput
                      placeholder="Informe o nome ou referência"
                      type="text"
                      label="Cliente"
                      error={errorMessage(formik, 'customerName')}
                      {...formik.getFieldProps('customerName')}
                    />
                  ) : isClientSelected ? (
                    <BorderedInput
                      placeholder="Informe o nome ou referência"
                      type="text"
                      label="Cliente"
                      error={errorMessage(formik, 'customerName')}
                      {...formik.getFieldProps('customerName')}
                      disabled={true}
                    />
                  ) : (
                    <Field
                      name={`customerName`}
                      menuPosition="fixed"
                      component={BorderedAsyncSelect}
                      callback={getCustomerOptionsCallback}
                      label="Cliente"
                      placeholder="Informe o nome ou referência"
                      onChange={(option) => {
                        formik.setFieldValue('customerId', option.value?.id ?? '');
                        formik.setFieldValue('customerName', option.value?.customer_name ?? '');
                        formik.setFieldValue('customerEmail', option.value?.email ?? '');
                        formik.setFieldValue('customerPhone', option.value?.phone ?? '');
                        formik.setFieldValue(
                          'customerBI',
                          option.value?.identification_number ?? ''
                        );

                        formik.setFieldError('customerName', null, false);
                        formik.setFieldTouched('customerName', null, false);
                        formik.setFieldError('customerEmail', null, false);
                        formik.setFieldTouched('customerEmail', null, false);
                        formik.setFieldError('customerPhone', null, false);
                        formik.setFieldTouched('customerPhone', null, false);
                        formik.setFieldTouched('customerBI', null, false);
                      }}
                      error={errorMessage(formik, 'customerName')}
                    />
                  )}
                </div>
                <div className="flex-1">
                  <BorderedInput
                    disabled={formik.values.isCustomerRegistered === 'yes'}
                    placeholder="Digite o BI"
                    type="text"
                    label="Identidade(BI/NUIT)"
                    onChange={(e) => {
                      formik.setFieldValue('customerBI', e.target.value.toUpperCase());
                    }}
                    error={errorMessage(formik, 'customerBI')}
                    {...formik.getFieldProps('customerBI')}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="flex-1">
                  <BorderedInput
                    disabled={formik.values.isCustomerRegistered === 'yes'}
                    placeholder="Digite o email"
                    type="text"
                    label="Email"
                    error={errorMessage(formik, 'customerEmail')}
                    {...formik.getFieldProps('customerEmail')}
                  />
                </div>
                <div className="flex-1">
                  <BorderedMaskedInput
                    disabled={formik.values.isCustomerRegistered === 'yes'}
                    placeholder="0,00"
                    type="text"
                    label="Telefone"
                    mask="+258 99 999 9999"
                    alwaysShowMask={true}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'customerPhone')}
                    {...formik.getFieldProps('customerPhone')}
                  />
                </div>
              </div>
            </>
            <div className="font-medium text-lg">Como você irá notificar o cliente?</div>
            <div>
              Com até quantos dias de <span className="font-bold">antecedência</span> e como você
              deseja notificar o cliente?
              <br />
              <span className="text-gray-3">
                (enviamos como padrão a partir de 3 dias antes do vencimento)
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <BorderedMaskedInput
                placeholder="Digite o número de dias"
                type="text"
                mask={[/[1-9]/, /[0-9]/]}
                alwaysShowMask={true}
                beforeMaskedStateChange={onlyPositiveNumbers}
                maskPlaceholder={null}
                error={errorMessage(formik, 'daysBeforeDueDate')}
                {...formik.getFieldProps('daysBeforeDueDate')}
              />
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="sms"
                  label="SMS"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="email"
                  label="Email"
                  disabled={!/^[\w.-]+@[\w.-]+.\w+$/.test(formik.values.customerEmail)}
                />
              </div>
            </div>

            <div>
              Como deseja notificar o cliente <span className="font-bold">no vencimento</span>?
            </div>
            <div className="flex gap-3 items-center">
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="sms"
                  label="SMS"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="email"
                  label="Email"
                  disabled={!/^[\w.-]+@[\w.-]+.\w+$/.test(formik.values.customerEmail)}
                />
              </div>
            </div>

            <div>
              Com até quantos dias de <span className="font-bold">após o vencimento</span> e como
              você deseja notificar o cliente?
              <br />
              <span className="text-gray-3">
                (enviamos como padrão a partir de 3 dias após o vencimento)
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <BorderedMaskedInput
                placeholder="Digite o número de dias"
                type="text"
                mask={[/[1-9]/, /[0-9]/]}
                alwaysShowMask={true}
                beforeMaskedStateChange={onlyPositiveNumbers}
                maskPlaceholder={null}
                error={errorMessage(formik, 'daysAfterDueDate')}
                {...formik.getFieldProps('daysAfterDueDate')}
              />
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="sms"
                  label="SMS"
                  disabled={formik.values.customerPhone.length < 9}
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="email"
                  label="Email"
                  disabled={!/^[\w.-]+@[\w.-]+.\w+$/.test(formik.values.customerEmail)}
                />
              </div>
            </div>
            <CustomError
              isValid={
                formik.values.daysAfterDueDateNotifications.length === 0 &&
                formik.values.daysBeforeDueDateNotifications.length === 0 &&
                formik.values.dueDateNotifications.length === 0 &&
                showError
              }
              message={'Selecione pelo menos uma opção de notificação'}
            />
            <div>
              Obs: Caso o pagamento da cobrança já tenha sido identificado, as futuras notificações
              não serão enviadas e cobradas.
            </div>
          </div>
          <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
            <FilledButton onClick={onPrevious} bg={Colors.Gray1} color={Colors.Orange}>
              Voltar
            </FilledButton>
            <FilledButton type="submit">Avançar</FilledButton>
          </div>
        </form>
      )}
    </Formik>
  );
}
