import React, { useState } from 'react';
import Modal from '../../../components/FormComponents/Modal/Modal';
import SlideCard from '../../../components/SlideCard/SlideCard';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Formik } from 'formik';
import * as Yup from 'yup';
import BorderedMaskedInput from '../../../components/FormComponents/Inputs/BorderedMaskedInput';
import MpesaIcon from '../../../assets/imgs/mpesa.png';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import { Colors } from '../../../utils/colors';

export default function AddCreditModal() {
  const [packages, setPackages] = useState(1000);
  const isLoading = useStore((state) => state.credits.isLoading);
  const addCreditsWithMpesa = useStore((state) => state.credits.addCreditsWithMpesa);

  const showMpesaPhoneNumberModal = useStore((state) => state.credits.showMpesaPhoneNumberModal);
  const toggleShowMpesaPhoneNumberModal = useStore(
    (state) => state.credits.toggleShowMpesaPhoneNumberModal
  );

  const onChangePackages = ({ x }) => setPackages(parseInt(x));

  const showAddCreditsModal = useStore((state) => state.credits.showAddCreditsModal);
  const toggleShowAddCreditsModal = useStore((state) => state.credits.toggleShowAddCreditsModal);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const requestPackagesWithMpesa = (phone) => {
    addCreditsWithMpesa({ amount: packages, phone: phone });
  };

  return (
    <>
      <Modal
        title="Adicionar Saldo"
        onClose={toggleShowAddCreditsModal}
        show={showAddCreditsModal}
        noDefaultHeight>
        <div className="flex overflow-hidden">
          <div className="h-full flex flex-col gap-8 overflow-y-auto px-5">
            <SlideCard
              unitName="Meticais"
              info={
                <span>
                  Um pacote pode enviar notificações de cobranças que inclui SMS e Email por 10 MZN
                  por notificação. Cada notificação adicional de Whatsapp custa 5 MZN.
                </span>
              }
              min={1000}
              max={10000}
              step={10}
              value={packages}
              onChange={onChangePackages}
            />
            <div className="flex flex-col md:flex-row pb-10 gap-5 bg-white">
              <div className="flex-1">
                <OutlinedButton
                  onClick={toggleShowMpesaPhoneNumberModal}
                  color={Colors.black}
                  className="w-full h-[50px]">
                  <div className="flex gap-3 justify-center items-center px-2">
                    <img src={MpesaIcon} className="w-8 h-8 rounded" />
                    <span>Pagar via M-Pesa</span>
                  </div>
                </OutlinedButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="Adicionar Crédito via M-pesa"
        onClose={toggleShowMpesaPhoneNumberModal}
        show={showMpesaPhoneNumberModal}
        noDefaultHeight>
        <Formik
          initialValues={{ phone: '' }}
          validationSchema={Yup.object({
            phone: Yup.string()
              .matches(
                /^\+258 \d\d \d\d\d \d\d\d\d$/,
                'O telefone deve ter o formato +258 XX XXX XXXX'
              )
              .required('Campo obrigatório')
          })}
          onSubmit={({ phone }) => {
            const phoneFormatted = phone.replace(/[^\d]/g, '');
            requestPackagesWithMpesa(phoneFormatted);
          }}>
          {(formik) => {
            return (
              <form onSubmit={formik.handleSubmit} className="h-full overflow-hidden">
                <div className="overflow-y-auto p-5">
                  Preencha o número de telefone associado ao m-pesa.
                </div>
                <div className="flex justify-between w-full gap-5 pb-8 px-5 !visible !opacity-100 pointer-events-auto">
                  <BorderedMaskedInput
                    className="w-full !visible"
                    component={BorderedMaskedInput}
                    name="phone"
                    mask="+258 99 999 9999"
                    alwaysShowMask={true}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'phone')}
                    {...formik.getFieldProps('phone')}
                  />
                  <FilledButton
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    className="px-5 w-[200px] !visible"
                    heightClass="h-[42px]">
                    Solicitar
                  </FilledButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}
