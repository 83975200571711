import React from 'react';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function BorderlessInput(props) {
  const { placeholder, borderBottom, error } = props;
  return (
    <div className={`${borderBottom ? 'border-b border-gray-2' : ''}`}>
      <input
        className={`outline-none w-full p-[15px] ${error ? 'pb-0' : ''}`}
        placeholder={placeholder}
        {...props}
        {...props.field}
      />
      <FieldErrorMessage className="pl-[15px]">{error}</FieldErrorMessage>
    </div>
  );
}
