import { apiPublic } from '../api/api';

const dataService = {
  getBanks: async () => {
    try {
      const response = await apiPublic.get('/banks');

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar bancos');
    }
  },

  getWallets: async () => {
    try {
      const response = await apiPublic.get('/wallets');

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar carteiras');
    }
  },

  getCities: async (provinceId) => {
    try {
      const response = await apiPublic.get('/cities', { params: { state_id: provinceId } });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar cidades');
    }
  },

  getRegions: async () => {
    try {
      const response = await apiPublic.get('/regions');

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar regiões');
    }
  }
};

export default dataService;
