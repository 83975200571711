import React, { useState } from 'react';
import { EyeClosedIcon, EyeOpenedIcon } from '../../../assets/imgs';
import Input from './Input';

export default function PasswordInput(props) {
  const [showPassword, setShowPassowrd] = useState(false);

  const togglePasswordInput = () => {
    setShowPassowrd(!showPassword);
  };

  const color = props.blackTheme ? 'white' : 'black';

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      endIconComponent={
        showPassword ? <EyeOpenedIcon color={color} /> : <EyeClosedIcon color={color} />
      }
      onEndIconComponentClick={togglePasswordInput}
    />
  );
}
