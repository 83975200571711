import React from 'react';
import useStepState from '../../hooks/useStepState';
import { useStore } from '../../store/useStore';
import StepModal from '../FormComponents/Modal/StepModal';
import Step1 from './Steps/Step1';
// import Step2 from './Steps/Step2';
import Step4 from './Steps/Step4';
import Step3 from './Steps/Step3';
import Modal from '../FormComponents/Modal/Modal';
import FilledButton from '../FormComponents/Buttons/FilledButton';
import { Colors } from '../../utils/colors';

export default function CreateBillingsModal() {
  const [step, previousStep, nextStep] = useStepState(1);
  const showCreateBillingsModal = useStore((state) => state.billings.showCreateBillingsModal);
  const closeCreateBillingsModal = useStore((state) => state.billings.closeCreateBillingsModal);
  const showCancelCreateManyBillingsConfirmationModal = useStore(
    (state) => state.billings.showCancelCreateManyBillingsConfirmationModal
  );
  const setShowCancelCreateManyBillingsConfirmationModal = useStore(
    (state) => state.billings.setShowCancelCreateManyBillingsConfirmationModal
  );

  const confirmCloseBillingModal = () => {
    setShowCancelCreateManyBillingsConfirmationModal(false);
    closeCreateBillingsModal();
  };

  return (
    <>
      <StepModal
        title="Criar Cobranças"
        show={showCreateBillingsModal}
        steps={['Dados da cobrança', 'Notificação', 'Resumo']}
        currentStepNumber={step}
        onClose={() => setShowCancelCreateManyBillingsConfirmationModal(true)}
        submitTitle="Criar"
        stepProgressBarClassName="text-sm">
        <Step1 show={step === 1} onNext={nextStep} onPrevious={previousStep} />
        <Step3 show={step === 2} onNext={nextStep} onPrevious={previousStep} />
        <Step4 show={step === 3} onNext={nextStep} onPrevious={previousStep} />
        {/* <Step2 show={step === 2} onNext={nextStep} onPrevious={previousStep} />
        <Step3 show={step === 3} onNext={nextStep} onPrevious={previousStep} />
        <Step4 show={step === 4} onNext={nextStep} onPrevious={previousStep} /> */}
      </StepModal>
      <Modal
        title="Cancelar Criação de Cobranças"
        onClose={() => setShowCancelCreateManyBillingsConfirmationModal(false)}
        show={showCancelCreateManyBillingsConfirmationModal}
        noDefaultHeight>
        <div className="overflow-hidden">
          <div className="overflow-y-auto p-5">
            Tem certeza que deseja cancelar a criação dessas cobranças?
          </div>
        </div>
        <div className="flex p-5 justify-around">
          <FilledButton
            className="px-10"
            onClick={() => setShowCancelCreateManyBillingsConfirmationModal(false)}
            bg={Colors.Gray1}
            color={Colors.black}>
            Não
          </FilledButton>
          <FilledButton onClick={confirmCloseBillingModal} className="px-10" bg={Colors.Red}>
            Sim
          </FilledButton>
        </div>
      </Modal>
    </>
  );
}
