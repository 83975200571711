import { useStore } from '../../../store/useStore';
import Modal from '../../../components/FormComponents/Modal/Modal';
import SelectGroupsToAssociate from './SelectGroupsToAssociate';

export default function AssociateCustomersToGroups() {
  const toggleShowAssociateCustomersToGroupsModal = useStore(
    (state) => state.customers.toggleShowAssociateCustomersToGroupsModal
  );
  const ShowAssociateCustomersToGroupsModal = useStore(
    (state) => state.customers.showAssociateCustomersToGroupsModal
  );

  return (
    <Modal
      title="Selecionar grupos"
      onClose={toggleShowAssociateCustomersToGroupsModal}
      show={ShowAssociateCustomersToGroupsModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          <p>Selecione os grupos: </p>
          <SelectGroupsToAssociate />
        </div>
      </div>
    </Modal>
  );
}
