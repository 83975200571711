import React from 'react';

export default function DetailSection({ title, button, children, noBorderBottom = false }) {
  return (
    <div className={`${noBorderBottom ? 'border-b-0' : ''} border border-gray-2 rounded`}>
      <div className="p-5 bg-gray-1 border-b border-gray-2 rounded-tr rounded-tl font-medium text-lg md:text-xl flex justify-between items-center">
        <div>{title}</div>
        <div>{button}</div>
      </div>
      <div>{children}</div>
    </div>
  );
}
