import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import BillingDetails from './BillingDetails';

export default function CancelBillingReceptionModal() {
  const cancelBilling = useStore((state) => state.billings.cancelBilling);
  const isCancelBillingLoading = useStore((state) => state.billings.isCancelBillingLoading);
  const showCancelBillingModal = useStore((state) => state.billings.showCancelBillingModal);
  const closeCancelBillingModal = useStore((state) => state.billings.closeCancelBillingModal);
  const selectedBilling = useStore((state) => state.billings.selectedBilling);
  const [notifyCustomer, setNotifyCustomer] = useState('no');

  const handleCancelBilling = () => {
    cancelBilling(notifyCustomer);
  };

  return (
    <Modal
      title="Remover pagamento"
      onClose={closeCancelBillingModal}
      show={showCancelBillingModal}>
      <div className="grid h-full overflow-hidden">
        <div className="h-full overflow-y-auto p-5">
          <div>Você tem certeza que deseja cancelar o recebimento dessa cobrança?</div>
          <BillingDetails
            billing={selectedBilling}
            notifyCustomer={notifyCustomer}
            onChangeNotifyCustomer={(value) => setNotifyCustomer(value)}
          />
        </div>
        <div className="flex sticky bottom-0 p-5 bg-white justify-around self-end">
          <FilledButton
            className="px-10"
            onClick={closeCancelBillingModal}
            bg={Colors.Gray1}
            color={Colors.black}>
            Cancelar
          </FilledButton>
          <FilledButton
            onClick={handleCancelBilling}
            loading={isCancelBillingLoading}
            className="px-10"
            bg={Colors.Red}>
            Remover
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
}
