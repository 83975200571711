import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Colors } from '../../../utils/colors';
import ActiveMarker from './ActiveMarker';
import { useStore } from '../../../store/useStore';
import useResponsivity from '../../../hooks/useResponsivity';

export default function NavbarLink({
  title,
  to,
  icon,
  hasActiveMark,
  color = Colors.Gray5,
  onClick,
  extraPathsActive = []
}) {
  const location = useLocation();

  const isActiveLink = () =>
    extraPathsActive.length > 0
      ? [...extraPathsActive, to].some((path) => location.pathname.includes(path))
      : location.pathname.includes(to);
  const { isMobile } = useResponsivity();
  const setShowNavbar = useStore((state) => state.navbar.setShowNavbar);
  const setNavbarHovered = useStore((state) => state.navbar.setNavbarHovered);

  const onLinkClick = () => {
    if (onClick) onClick();
    if (isMobile) setShowNavbar(false);
    setNavbarHovered(false);
  };

  const IconComponent = icon;
  return (
    <NavLink
      to={to}
      onClick={onLinkClick}
      style={() => {
        return {
          color: isActiveLink() ? Colors.White : color
        };
      }}>
      {hasActiveMark && isActiveLink() ? <ActiveMarker /> : null}
      <div className="pl-5 gap-5 pb-3 pt-2 flex items-center md:overflow-hidden md:min-w-[200px]">
        <IconComponent color={isActiveLink() ? Colors.White : color} />
        <span>{title}</span>
      </div>
    </NavLink>
  );
}
