import React from 'react';

export default function WalletIcon({ width = 20, height = 19, color = '#5E5E5E' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 5.625V14C1 16.2091 2.79086 18 5 18H15C17.2091 18 19 16.2091 19 14V13.7812M1 5.625H18M1 5.625L11.9462 1.37863C13.936 0.606725 16.1813 1.53298 17.0481 3.4833L18 5.625M18 5.625V5.625C18.5523 5.625 19 6.07272 19 6.625V9.84375M19 13.7812V11.8125V9.84375M19 13.7812H15.9688C14.8814 13.7812 14 12.8998 14 11.8125V11.8125C14 10.7252 14.8814 9.84375 15.9688 9.84375H19"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
