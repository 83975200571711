import React from 'react';

const StrengthValidator = ({ password, mode = 'light' }) => {
  const [percent, setPercent] = React.useState(0);
  const [passwordStrength, setPasswordStrength] = React.useState({
    length: false,
    number: false,
    uppercase: false,
    lowercase: false
  });
  const [message, setMessage] = React.useState('');
  React.useEffect(() => {
    setPasswordStrength({
      length: password.length >= 6,
      number: /\d/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password)
    });
    return () => {};
  }, [password]);

  React.useEffect(() => {
    setPercent(
      (passwordStrength.length ? 25 : 0) +
        (passwordStrength.number ? 25 : 0) +
        (passwordStrength.uppercase ? 25 : 0) +
        (passwordStrength.lowercase ? 25 : 0)
    );

    if (percent && !passwordStrength.length)
      setMessage('Sua senha deve ter pelo menos 6 caracteres');
    else if (percent && !passwordStrength.number)
      setMessage('Sua senha deve conter pelo menos um número');
    else if (percent && !passwordStrength.uppercase)
      setMessage('Sua senha deve conter pelo menos uma letra maiúscula');
    else if (percent && !passwordStrength.lowercase)
      setMessage('Sua senha deve conter pelo menos uma letra minúscula');
    else if (Object.values(passwordStrength).every((value) => value === true)) setMessage('');
    return () => {};
  }, [passwordStrength]);
  return (
    <div>
      <div className="rounded-full w-full h-2 bg-gray-2 relative">
        <div
          className={`inset-0 h-2 transition-all duration-700 ease-in-out rounded-full absolute`}
          style={{
            width: `${percent}%`,
            backgroundColor: `${percent > 75 ? '#74d314' : percent > 25 ? '#f3cb04' : '#cb0404'}`
          }}
        />
      </div>
      {message && (
        <p className={`${mode === 'light' ? 'text-gray-6' : 'text-white'} text-sm mt-2`}>
          {message}
        </p>
      )}
    </div>
  );
};

export default StrengthValidator;
