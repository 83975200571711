import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { Colors } from '../../../utils/colors';

const AccountReactivationModal = ({ isModalOpen, handleModalOpen }) => {
  const handleReactivateAccount = () => {
    handleModalOpen();
    window.location.replace('/recuperar-conta');
  };
  return (
    <Modal title={'Reativar conta'} show={isModalOpen} onClose={handleModalOpen} noDefaultHeight>
      <div className="overflow-y-auto p-5">
        <div className="space-y-4 font-medium text-gray-6">
          <p>Essa conta foi excluída. Gostaria de recuperá-la?</p>
        </div>
        <div className="flex justify-around mt-5">
          <FilledButton
            className="px-10"
            onClick={handleModalOpen}
            bg={Colors.Gray1}
            color={Colors.black}>
            Cancelar
          </FilledButton>
          <FilledButton onClick={handleReactivateAccount} className="px-10" bg={Colors.Green}>
            Reativar conta
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default AccountReactivationModal;
