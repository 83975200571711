import React, { useEffect } from 'react';
import SearchFilter from '../../components/Filters/SearchFilter';
import SelectFilter from '../../components/Filters/SelectFilter';

import { useStore } from '../../store/useStore';
import { BILLING_STATUSES, DATE_TYPES, PAYMENT_TYPES } from '../../utils/constants';

import BillingsTable from './components/BillingsTable';
import { ChevronDownIcon, DolarIcon, StatusFilterIcon } from '../../assets/imgs';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import PageHeader from '../../components/PageHeader/PageHeader';
import DeleteBillingModal from '../Billing/components/DeleteBillingModal';
import CancelBillingReceptionModal from '../Billing/components/CancelBillingReceptionModal';
import ConfirmBillingReceptionModal from '../Billing/components/ConfirmBillingReceptionModal';
import DeleteBillingsModal from '../Billing/components/DeleteBillingsModal';
import { Colors } from '../../utils/colors';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import moment from 'moment';
import ConfirmMultipleBillingReceptionModal from '../Billing/components/ConfirmMultipleBillingReceptionModal';
import OutlinedButton from '../../components/FormComponents/Buttons/OutlinedButton';
import ConfirmManyBillingsError from '../Customers/components/ConfirmManyBillingsError';
// import Selector from './components/Selector';

export default function Billings() {
  const isBillingsTableLoading = useStore((state) => state.billings.isBillingsTableLoading);

  const getBillings = useStore((state) => state.billings.getBillings);
  const billings = useStore((state) => state.billings.billings);
  const openConfirmMultipleBillingsModal = useStore(
    (state) => state.billings.openConfirmMultipleBillingsModal
  );

  const setFilter = useStore((state) => state.billings.setFilter);
  const chargeStatusFilter = useStore((state) => state.billings.filters.chargeStatus);
  const formOfPaymentFilter = useStore((state) => state.billings.filters.formOfPayment);
  const searchFilter = useStore((state) => state.billings.filters.search);
  const dateFilter = useStore((state) => state.billings.filters.date);
  const selectedBillings = useStore((state) => state.billings.selectedBillings);
  const openDeleteBillingsModal = useStore((state) => state.billings.openDeleteBillingsModal);
  const resetBillingsSelected = useStore((state) => state.billings.resetBillingsSelected);
  const changeBillingPage = useStore((state) => state.billings.changeBillingPage);
  const date_type = useStore((state) => state.billings.filters.date_type);
  const downloadExcel = useStore((state) => state.billings.downloadExcel);
  const limit = useStore((state) => state.billings.filters.limit);

  useEffect(() => {
    if (dateFilter.startDate && dateFilter.endDate) {
      resetBillingsSelected();
      getBillings();
    }
  }, [chargeStatusFilter, searchFilter, formOfPaymentFilter, dateFilter, date_type, limit]);

  useEffect(() => {
    return () => {
      setFilter('search', '');
      setFilter('chargeStatus', '');
      setFilter('formOfPayment', '');
      setFilter('date', { startDate: moment().subtract(7, 'days'), endDate: moment() });
    };
  }, []);

  const handleChangeSearchFilter = (text) => {
    changeBillingPage(1, false);
    setFilter('search', text);
  };

  const handleChangeStatusFilter = (status) => {
    setFilter('chargeStatus', status.value);
  };

  const handleChangeFormOfPaymentFilter = (status) => {
    setFilter('formOfPayment', status.value);
  };

  const handleChangeDateRangeFilter = ([startDate, endDate]) => {
    setFilter('date', { startDate, endDate });
  };

  const handleChangeDateTypeFilter = (dateType) => {
    setFilter('date_type', dateType.value);
  };
  return (
    <div>
      <div>
        <PageHeader title="Cobranças" />
        <div className="flex justify-between md:flex-row flex-col md:items-center items-start ">
          <div className="flex gap-4 flex-wrap">
            <SelectFilter
              onChange={handleChangeDateTypeFilter}
              options={DATE_TYPES}
              iconComponent={ChevronDownIcon}
              placeholder="Filtrar por data"
            />
            <DateRangeFilter onChange={handleChangeDateRangeFilter} />
            <SelectFilter
              iconComponent={StatusFilterIcon}
              onChange={handleChangeStatusFilter}
              options={BILLING_STATUSES}
              placeholder="Status"
            />
            <SelectFilter
              iconComponent={DolarIcon}
              height={42}
              onChange={handleChangeFormOfPaymentFilter}
              options={PAYMENT_TYPES}
              placeholder="Forma de pagamento"
            />
            <SearchFilter onChange={handleChangeSearchFilter} className="w-[160px]" />
            {selectedBillings?.length > 1 ? (
              <>
                <FilledButton
                  heightClass="min-h-[42px]"
                  bg={Colors.Red}
                  onClick={openDeleteBillingsModal}
                  className="px-5">
                  <div className="font-semibold">Apagar</div>
                </FilledButton>
                <FilledButton
                  heightClass="min-h-[42px]"
                  bg={Colors.Green}
                  onClick={openConfirmMultipleBillingsModal}
                  className="px-5">
                  <div className="font-semibold">Confirmar recebimento</div>
                </FilledButton>
              </>
            ) : null}
          </div>
          <OutlinedButton onClick={downloadExcel} className="px-5 mt-3 group md:mt-0">
            <div className="flex gap-3 items-center group-hover:w-44 overflow-hidden w-6 duration-200 transition-all h-[40px] relative">
              <div className="font-semibold absolute whitespace-nowrap left-10">
                Exportar relatório
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className="stroke-orange"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="7 10 12 15 17 10" />
                <line x1="12" x2="12" y1="15" y2="3" />
              </svg>{' '}
            </div>
          </OutlinedButton>
        </div>
        <div className="max-w-[100%] mt-5">
          {/* <Selector /> */}
          <BillingsTable data={billings} loading={isBillingsTableLoading} />
        </div>
      </div>
      <DeleteBillingModal />
      <CancelBillingReceptionModal />
      <ConfirmBillingReceptionModal />
      <ConfirmMultipleBillingReceptionModal />
      <DeleteBillingsModal />
      <ConfirmManyBillingsError />
    </div>
  );
}
