import React from 'react';
import { useStore } from '../../../store/useStore';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';

export default function DeleteBillingsModal() {
  const deleteBillings = useStore((state) => state.billings.deleteBillings);
  const showDeleteBillingsModal = useStore((state) => state.billings.showDeleteBillingsModal);
  const closeDeleteBillingsModal = useStore((state) => state.billings.closeDeleteBillingsModal);
  const isDeletingBillingsLoading = useStore((state) => state.billings.isDeletingBillingsLoading);

  const handleDeleteBillings = () => {
    deleteBillings();
  };

  return (
    <Modal
      title="Apagar cobranças"
      onClose={closeDeleteBillingsModal}
      show={showDeleteBillingsModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          Tem certeza que deseja apagar essa(s) cobrança(s)? Depois de feito, você não pode desfazer
          essa ação.
        </div>
      </div>
      <div className="flex p-5 justify-around">
        <FilledButton
          className="px-10"
          onClick={closeDeleteBillingsModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={handleDeleteBillings}
          loading={isDeletingBillingsLoading}
          className="px-10"
          bg={Colors.Red}>
          Apagar
        </FilledButton>
      </div>
    </Modal>
  );
}
