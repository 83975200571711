import React from 'react';
import { LoadingIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';

export default function FilledButton(props) {
  const {
    children,
    bg = Colors.Orange,
    color = Colors.White,
    onClick,
    loading = false,
    className,
    roundedClass = 'rounded-[5px]',
    heightClass = ''
  } = props;

  return (
    <button
      {...props}
      onClick={!loading ? onClick : null}
      type={props.type ?? 'button'}
      style={{ backgroundColor: bg, color: color }}
      className={
        `${roundedClass} min-w-[86px] font-medium ${heightClass ? heightClass : 'min-h-[48px]'} ` +
        className
      }>
      {loading ? <LoadingIcon /> : children}
    </button>
  );
}
