import React from 'react';

export default function PersonIcon({ width = 20, height = 15, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2455 8.29999C15.4909 9.14544 16.3637 10.2909 16.3637 11.8182V14.5454H20V11.8182C20 9.83635 16.7546 8.66362 14.2455 8.29999Z"
        fill={color}
      />
      <path
        d="M7.27272 7.27273C9.28102 7.27273 10.9091 5.64467 10.9091 3.63636C10.9091 1.62806 9.28102 0 7.27272 0C5.26441 0 3.63635 1.62806 3.63635 3.63636C3.63635 5.64467 5.26441 7.27273 7.27272 7.27273Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7273 7.27273C14.7364 7.27273 16.3636 5.64545 16.3636 3.63636C16.3636 1.62727 14.7364 0 12.7273 0C12.3 0 11.9 0.0909089 11.5182 0.218182C12.2727 1.15455 12.7273 2.34545 12.7273 3.63636C12.7273 4.92727 12.2727 6.11818 11.5182 7.05455C11.9 7.18182 12.3 7.27273 12.7273 7.27273Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27273 8.18182C4.84545 8.18182 0 9.40001 0 11.8182V14.5455H14.5455V11.8182C14.5455 9.40001 9.7 8.18182 7.27273 8.18182Z"
        fill={color}
      />
    </svg>
  );
}
