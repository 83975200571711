import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import InputMask from 'react-input-mask';

export default function BorderedMaskedInput(props) {
  const onBlur = props.onBlur;
  const copyProps = { ...props };
  delete copyProps.onBlur;
  delete copyProps.disabled;

  return (
    <div className={`relative ${props.className}`}>
      <span className={`font-medium text-sm`}>
        {props.label}
        <span className="text-gray-3">{props.labelInfo}</span>
      </span>
      <label
        className={`text-black rounded-[5px] bg-white border border-gray-2 placeholder-gray-3 flex`}>
        {props.prefix ? (
          <div className="pointer-events-non px-2 py-2 bg-gray-2 min-w-[44px] text-center">
            {props.prefix}
          </div>
        ) : null}
        {!props.textarea ? (
          <InputMask
            mask={props.mask}
            alwaysShowMask={props.alwaysShowMask}
            maskPlaceholder={props.maskPlaceholder}
            maskChar={props.maskChar}
            value={props.value}
            onChange={props.onChange}
            beforeMaskedStateChange={props.beforeMaskedStateChange}
            disabled={props.disabled}
            onBlur={onBlur}>
            <input
              {...copyProps}
              placeholder={props.placeholder}
              type={props.type}
              className={`form-input p-2 appearance-none w-full block focus:outline-none`}
            />
          </InputMask>
        ) : (
          <textarea className="w-full p-2 block" placeholder={props.placeholder} {...copyProps}>
            {props.value}
          </textarea>
        )}
      </label>
      <FieldErrorMessage>{props.error}</FieldErrorMessage>
    </div>
  );
}
