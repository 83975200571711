import React from 'react';
import { Colors } from '../../utils/colors';

export default function DeleteIcon({ width = 13, height = 16, color = Colors.black }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.1897 5.55173L2.4284 12.984C2.58913 13.9483 3.42351 14.6552 4.40119 14.6552H8.59889C9.57657 14.6552 10.411 13.9483 10.5717 12.984L11.8104 5.55173"
        stroke={color}
        strokeLinecap="round"
      />
      <path
        d="M1 3.46552H3.84483M12 3.46552H9.15517M9.15517 3.46552V3C9.15517 1.89543 8.25974 1 7.15517 1H5.84483C4.74026 1 3.84483 1.89543 3.84483 3V3.46552M9.15517 3.46552H3.84483"
        stroke={color}
        strokeLinecap="round"
      />
      <line
        x1="5.48267"
        y1="8.0863"
        x2="7.51715"
        y2="8.0863"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}
