import { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

const DateInput = forwardRef(({ value, onClick, customPlaceholder, error }, ref) => {
  return (
    <div>
      <button className="w-full text-left p-2 rounded" type="button" onClick={onClick} ref={ref}>
        {value ? value : customPlaceholder}
      </button>
      <FieldErrorMessage>{error}</FieldErrorMessage>
    </div>
  );
});

DateInput.displayName = 'DateInput';

export default function BorderlessDatePicker({
  selected,
  onChange,
  customPlaceholder,
  error,
  onClear
}) {
  return (
    <DatePicker
      selected={selected}
      locale="ptBR"
      dateFormat="dd/MM/yyyy"
      customInput={
        <DateInput customPlaceholder={customPlaceholder} onClear={onClear} error={error} />
      }
      onChange={onChange}
    />
  );
}
