import { products } from '../api/api';

const productService = {
  createProduct: async (productData) => {
    try {
      const response = await products.post('/', {
        name: productData?.item,
        price: Number(productData?.billingValue.replace(/\./g, '').replace(',', '.')),
        description: productData?.description ?? '',
        is_signature: productData?.paymentType === 'signature',
        interval: parseInt(productData?.billingInterval),
        save: true
      });
      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao criar o produto');
    }
  },
  getProducts: async ({ search, page }) => {
    try {
      const response = await products.get('/products', {
        params: { search, page }
      });
      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar produtos');
    }
  },
  getProduct: async (productId) => {
    try {
      const response = await products.get(`/${productId}`);
      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar o produto');
    }
  },
  deleteProduct: async (productId) => {
    try {
      await products.delete(`/${productId}`);
      return;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao apagar o produto');
    }
  },
  getProductOptions: async ({ search }) => {
    try {
      const response = await products.get('/products', { params: { search } });
      return response.data?.message;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  updateProduct: async (productId, productData) => {
    try {
      const response = await products.put(`/update/product/${productId}`, {
        name: productData?.item,
        price: Number(productData?.billingValue.replace(/\./g, '').replace(',', '.')),
        description: productData?.description ?? ''
      });
      return response.data?.message;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao atualizar o produto');
    }
  }
};

export default productService;
