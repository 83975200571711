import React from 'react';

export default function CalendarIcon({ width = 20, height = 20, color = '#fff' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 4.16669H17.5V17.5H2.5V4.16669Z" stroke={color} strokeLinejoin="round" />
      <path d="M17.5 7.50002H2.5" stroke={color} strokeLinecap="round" />
      <path d="M5.83333 4.16667V2.5" stroke={color} strokeLinecap="round" />
      <path d="M14.1667 4.16667V2.5" stroke={color} strokeLinecap="round" />
    </svg>
  );
}
