import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';

export default function BorderedInput(props) {
  return (
    <div className={`relative ${props.className}`}>
      <span className={`font-medium text-sm`}>
        {props.label}
        <span className="text-gray-3">{props.labelInfo}</span>
      </span>
      <label className={`text-black rounded bg-white border border-gray-2 placeholder-gray-3 flex`}>
        {props.prefix ? (
          <div className="pointer-events-non px-2 py-2 bg-gray-2">{props.prefix}</div>
        ) : null}
        {!props.textarea ? (
          <input
            {...props}
            disabled={props.disabled}
            placeholder={props.placeholder}
            type={props.type}
            className={`form-input p-2 appearance-none w-full block focus:outline-none`}
          />
        ) : (
          <textarea className="w-full p-2 block" placeholder={props.placeholder} {...props}>
            {props.field?.value}
          </textarea>
        )}
      </label>
      <FieldErrorMessage>{props.error}</FieldErrorMessage>
    </div>
  );
}
