import React from 'react';

export default function StatusIcon({ width = 16, height = 16, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="6" fill="white" stroke={color} strokeWidth="4" />
    </svg>
  );
}
