import React from 'react';

export default function FileIcon({ width = 24, height = 25, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3334 2.5H10.1667C9.06212 2.5 8.16669 3.39543 8.16669 4.5V7.5M17.3334 2.5L19.8334 5.41667M17.3334 2.5L16.8007 2.92614C15.9529 3.60436 15.8358 4.8501 16.5424 5.67441V5.67441C17.2159 6.46014 18.391 6.57059 19.1991 5.92411L19.8334 5.41667M19.8334 5.41667V15.5C19.8334 16.6046 18.9379 17.5 17.8334 17.5H16"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 7.5H6.16669C5.06212 7.5 4.16669 8.39543 4.16669 9.5V20.5C4.16669 21.6046 5.06212 22.5 6.16669 22.5H13.8334C14.9379 22.5 15.8334 21.6046 15.8334 20.5V10.4167M13.3334 7.5L15.8334 10.4167M13.3334 7.5L12.8007 7.92614C11.9529 8.60436 11.8358 9.8501 12.5424 10.6744V10.6744C13.2159 11.4601 14.391 11.5706 15.1991 10.9241L15.8334 10.4167"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
