import React from 'react';
import InputMask from 'react-input-mask';
import Input from './Input';

export default function MaskedInput(props) {
  const onBlur = props.onBlur;
  const copyProps = { ...props };
  delete copyProps.onBlur;

  return (
    <InputMask
      mask={props.mask}
      alwaysShowMask={props.alwaysShowMask}
      maskPlaceholder={props.maskPlaceholder}
      value={props.value}
      onChange={props.onChange}
      onBlur={onBlur}>
      <Input {...copyProps} />
    </InputMask>
  );
}
