import React, { useEffect } from 'react';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import { useParams } from 'react-router';
import DataTableSection from '../../../components/FormComponents/DataTable/DataTableSection';
import { formatDateTime } from '../../../utils/dateHelpers';

function NotificationsTable({ data, loading = false }) {
  const columns = React.useMemo(() => [
    {
      Header: 'Data e Hora',
      accessor: 'date',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{formatDateTime(new Date(row.original.time))}</div>
      )
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ row }) => <div className="flex gap-3 items-center">{row.original.description}</div>
    },
    {
      Header: 'ID Cobrança',
      accessor: 'billing_id',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{row.original.installment_id}</div>
      )
    }
  ]);

  return (
    <div>
      <DataTableSection data={data} columns={columns} loading={loading} />
    </div>
  );
}

export default function NotificationsSection() {
  const getCustomerNotifications = useStore((state) => state.customers.getCustomerNotifications);
  const notifications = useStore((state) => state.customers.customer?.notifications);
  const isCustomerNotificationsLoading = useStore(
    (state) => state.customers.isCustomerNotificationsLoading
  );
  const params = useParams();

  useEffect(() => {
    getCustomerNotifications(params.id);
  }, []);

  return (
    <DetailSection title="Notificações enviadas">
      <div className="px-5">
        <NotificationsTable data={notifications} loading={isCustomerNotificationsLoading} />
      </div>
    </DetailSection>
  );
}
