import React from 'react';
import { DeleteIcon, PencilIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
// import { useStore } from '../../../store/useStore';
import ButtonSelect from './ButtonSelect';
import DotsIcon from '../../../assets/imgs/DotsIcon';
import { useStore } from '../../../store/useStore';

export default function MoreActionGroup({ group, asDots }) {
  const toggleShowUpdateGroupModal = useStore((state) => state.groups.toggleShowUpdateGroupModal);
  const toggleShowDeleteGroupModal = useStore((state) => state.groups.toggleShowDeleteGroupModal);
  const setGroup = useStore((state) => state.groups.setGroup);

  let options = [
    {
      value: 'edit-group-info',
      icon: <PencilIcon color={Colors.black} />,
      label: 'Editar informações'
    },
    {
      value: 'delete-group',
      icon: <DeleteIcon color={Colors.black} />,
      label: 'Apagar grupo'
    }
  ];
  const onChooseOption = (option) => {
    setGroup(group);
    switch (option) {
      case 'edit-group-info':
        toggleShowUpdateGroupModal();
        break;
      case 'delete-group':
        toggleShowDeleteGroupModal();
        break;
    }
  };
  return (
    <ButtonSelect
      options={options}
      label="Mais ações"
      onChange={onChooseOption}
      component={asDots ? DotsIcon : null}
      menuClassName={asDots ? 'left-[-200px] top-[-72px]' : 'left-[-68px]'}
    />
  );
}
