import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomerDataSection from './components/CustomerDataSection';
import SignatureSection from './components/SignatureSection';
import ItemsSection from './components/ItemsSection';
import NotificationsSection from './components/NotificationsSection';
import { CUSTOMER_STATUSES_LABEL, CUSTOMER_STATUS_COLOR } from '../../utils/constants';
import { useStore } from '../../store/useStore';
import { useParams } from 'react-router';
import { StatusIcon } from '../../assets/imgs';
import Skeleton from 'react-loading-skeleton';
import Pagination from '../../components/Pagination/Pagination';

export default function Customer() {
  const customer = useStore((state) => state.customers.customer);
  const isCustomerLoading = useStore((state) => state.customers.isCustomerLoading);
  const resetCustomerId = useStore((state) => state.customers.resetCustomerId);
  const customerId = useParams()?.id;

  const signaturesCurrentPage = useStore((state) => state.customers.signaturesCurrentPage);
  const signaturesTotalPages = useStore((state) => state.customers.signaturesTotalPages);
  const changeSignaturesPage = useStore((state) => state.customers.changeSignaturesPage);

  const itemsCurrentPage = useStore((state) => state.customers.itemsCurrentPage);
  const itemsTotalPages = useStore((state) => state.customers.itemsTotalPages);
  const changeItemsPage = useStore((state) => state.customers.changeItemsPage);

  const notificationsCurrentPage = useStore((state) => state.customers.notificationsCurrentPage);
  const notificationsTotalPages = useStore((state) => state.customers.notificationsTotalPages);
  const changeNotificationsPage = useStore((state) => state.customers.changeNotificationsPage);

  const handleChangeSignaturesPage = (page, makeRequest) => {
    changeSignaturesPage(customerId, page, makeRequest);
  };

  const handleChangeItemsPage = (page, makeRequest) => {
    changeItemsPage(customerId, page, makeRequest);
  };

  const handleChangeNotificationsPage = (page, makeRequest) => {
    changeNotificationsPage(customerId, page, makeRequest);
  };
  useEffect(() => {
    return () => {
      resetCustomerId();
    };
  }, []);

  return (
    <div>
      <div>
        <PageHeader title="Cliente" />
        <div className="text-xl font-medium pb-5 flex gap-3">
          {isCustomerLoading ? (
            <Skeleton width={120} />
          ) : (
            <div>
              {customer.firstName} {customer.lastName}
            </div>
          )}
          <div>-</div>
          {isCustomerLoading ? (
            <Skeleton width={100} />
          ) : (
            <div className="flex gap-2 items-center">
              <StatusIcon color={CUSTOMER_STATUS_COLOR[customer.status]} />
              <div style={{ color: CUSTOMER_STATUS_COLOR[customer.status] }}>
                {CUSTOMER_STATUSES_LABEL[customer.status]}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        <div className="md:w-[40%]">
          <CustomerDataSection customerId={customerId} />
        </div>
        <div className="flex flex-col gap-5 md:w-[60%]">
          <SignatureSection />
          <div className="flex justify-center">
            <Pagination
              currentPage={signaturesCurrentPage}
              totalPages={signaturesTotalPages}
              changePage={handleChangeSignaturesPage}
            />
          </div>
          <ItemsSection customerId={customerId} />
          <div className="flex justify-center">
            <Pagination
              currentPage={itemsCurrentPage}
              totalPages={itemsTotalPages}
              changePage={handleChangeItemsPage}
            />
          </div>
          <NotificationsSection customerId={customerId} />
          <div className="flex justify-center">
            <Pagination
              currentPage={notificationsCurrentPage}
              totalPages={notificationsTotalPages}
              changePage={handleChangeNotificationsPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
