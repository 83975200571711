import React, { useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '../../assets/imgs';
import { Colors } from '../../utils/colors';

const OutlinePageButton = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white cursor-pointer text-orange border border-orange min-w-[30px]  text-center rounded-[5px]">
      <div className="flex items-center justify-center h-[30px]">{children}</div>
    </div>
  );
};

export default function Pagination({ currentPage, totalPages, changePage }) {
  // when change page then reset zustand page state
  useEffect(() => {
    changePage(1, false);
  }, []);

  const nextPage = () => {
    if (currentPage === totalPages) return;
    changePage(currentPage + 1);
  };

  const previousPage = () => {
    if (currentPage === 1) return;
    changePage(currentPage - 1);
  };

  const handleLastPageClick = () => {
    if (currentPage === totalPages) return;
    changePage(totalPages);
  };

  return (
    totalPages > 0 && (
      <div className="flex items-center gap-2">
        <OutlinePageButton onClick={previousPage}>
          <div className=" flex items-center justify-center">
            <ChevronLeftIcon color={Colors.Orange} />
          </div>
        </OutlinePageButton>
        <div className="bg-orange border cursor-pointer border-orange text-white min-w-[30px] text-center font-bold rounded-[5px]">
          <div className="flex items-center justify-center h-[30px]">{currentPage}</div>
        </div>
        <div className="text-orange">de</div>
        <OutlinePageButton onClick={handleLastPageClick}>{totalPages}</OutlinePageButton>
        <OutlinePageButton onClick={nextPage}>
          <div className=" flex items-center justify-center">
            <ChevronRightIcon color={Colors.Orange} />
          </div>
        </OutlinePageButton>
      </div>
    )
  );
}
