import React from 'react';
import { formatCurrency } from '../../../utils/currencyHelper';
import moment from 'moment';
import { Colors } from '../../../utils/colors';

export default function CustomTooltip({ active, payload, label }) {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-3 border border-gray-2">
        <p className="intro">{moment.utc(label)?.format('DD/MM/YYYY')}</p>
        <p style={{ color: Colors.Green }}>
          Recebido: <span className="text-sm">MZN</span> {formatCurrency(payload[0].value)}
        </p>
        <p style={{ color: Colors.Blue }}>
          A confirmar: <span className="text-sm">MZN</span> {formatCurrency(payload[1].value)}
        </p>
        <p style={{ color: Colors.Yellow }}>
          Previsto: <span className="text-sm">MZN</span> {formatCurrency(payload[2].value)}
        </p>
      </div>
    );
  }

  return null;
}
