import React from 'react';
import DetailSection from '../../../components/DetailSection/DetailSection';
import { useStore } from '../../../store/useStore';
import InstallmentsTable from './InstallmentsTable';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';

export default function InstallmentsSection() {
  // const getInvoiceInstallments = useStore((state) => state.billings.getInvoiceInstallments);
  const installments = useStore((state) => state.billings.invoice?.installments);
  const isInvoiceInstallmentsLoading = useStore(
    (state) => state.billings.isInvoiceInstallmentsLoading
  );
  const selectedBillings = useStore((state) => state.billings.selectedBillings);
  const openConfirmMultipleBillingsModal = useStore(
    (state) => state.billings.openConfirmMultipleBillingsModal
  );
  const openDeleteBillingsModal = useStore((state) => state.billings.openDeleteBillingsModal);
  // const params = useParams();

  // useEffect(() => {
  //   getInvoiceInstallments(params.id);
  // }, []);

  return (
    <DetailSection
      title="Parcelas"
      button={
        <>
          {selectedBillings?.length > 1 ? (
            <div className="flex gap-3">
              <FilledButton
                heightClass="min-h-[42px]"
                bg={Colors.Red}
                onClick={openDeleteBillingsModal}
                className="px-5 text-base font-semibold">
                Apagar
              </FilledButton>
              <FilledButton
                heightClass="min-h-[42px]"
                bg={Colors.Green}
                onClick={openConfirmMultipleBillingsModal}
                className="px-5 text-base font-semibold">
                Confirmar recebimento
              </FilledButton>
            </div>
          ) : null}
        </>
      }>
      <div className="px-5">
        <InstallmentsTable data={installments ?? []} loading={isInvoiceInstallmentsLoading} />
      </div>
    </DetailSection>
  );
}
