import React from 'react';

const colors = {
  error: '#FF3D3C',
  info: '#17E383'
};
const Tooltip = ({ type, text }) => {
  return (
    <div className="relative group z-50 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke={colors[type]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="size-2">
        <circle cx="12" cy="12" r="10" />
        <path d="M12 16v-4" />
        <path d="M12 8h.01" />
      </svg>
      <div className="absolute bottom-[112%] left-1/2 -translate-x-1/2 p-3 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto opacity-0 transition-all duration-300 group-hover:opacity-100 bg-gray-3 rounded w-52">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Tooltip;
