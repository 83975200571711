import React from 'react';
import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import { formatDateToText } from '../../../utils/dateHelpers';
import useResponsivity from '../../../hooks/useResponsivity';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../utils/currencyHelper';

const actions = {
  notification: 'Notificação',
  deposit: 'Depósito',
  credit: 'Crédito',
  debit: 'Débito'
};

const statusLabel = {
  pending: 'Pendente',
  denied: 'Recusado',
  approved: 'Aprovado'
};

export default function CreditsTable({ data, loading = false }) {
  const navigate = useNavigate();
  const { isMobile } = useResponsivity();
  const hiddenColumns = isMobile ? ['billing_id'] : [];

  const onClickRow = (row) => {
    if (row.original.action === 'notification') {
      navigate('/cobrancas/' + row.original.service_ref);
    }
  };

  const dateRow = (row) => {
    return <div>{formatDateToText(new Date(row.original.updated_at))}</div>;
  };

  const descriptionRow = (row) => {
    return <div>{row.original.description}</div>;
  };

  const service_id = (row) => {
    return <div>{row.original.service_ref || row.original.id}</div>;
  };

  const action = (row) => {
    return <div>{actions[row.original.action]}</div>;
  };

  const ammout = (row) => {
    return <div>MZN {formatCurrency(row.original.amount)}</div>;
  };

  const status = (row) => {
    return (
      <div
        className={
          row.original.status === 'approved'
            ? 'text-green'
            : row.original.status === 'denied'
            ? 'text-red'
            : 'text-yellow-400'
        }>
        {statusLabel[row.original.status]}
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'updated_at',
        Cell: ({ row }) => dateRow(row)
      },
      {
        Header: 'ID de Serviço',
        accessor: 'service_ref', // accessor is the "key" in the data
        Cell: ({ row }) => service_id(row)
      },
      {
        Header: 'Ação',
        accessor: 'action',
        Cell: ({ row }) => action(row)
      },
      {
        Header: 'Descrição',
        accessor: 'description',
        Cell: ({ row }) => descriptionRow(row)
      },
      {
        Header: 'Montante',
        accessor: 'ammount',
        Cell: ({ row }) => ammout(row)
      },
      {
        Header: 'Estado',
        accessor: 'status',
        Cell: ({ row }) => status(row)
      }
    ],
    []
  );
  return (
    <DataTable
      loading={loading}
      columns={columns}
      data={data}
      noTableSelection
      hiddenColumns={hiddenColumns}
      onClickRow={onClickRow}
    />
  );
}
