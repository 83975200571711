import React from 'react';
import { LoadingIcon } from '../../../assets/imgs';

export default function OutlinedButton(props) {
  const {
    children,
    bg = 'white',
    color = 'orange',
    onClick,
    loading = false,
    className,
    roundedClass = 'rounded-[5px]'
  } = props;

  return (
    <button
      {...props}
      onClick={!loading ? onClick : null}
      type={props.type ?? 'button'}
      className={
        `bg-${bg} text-${color} border-[1.5px]  border-${color} ${roundedClass} font-medium ` +
        className
      }>
      {loading ? <LoadingIcon color={color} /> : <div>{children}</div>}
    </button>
  );
}
