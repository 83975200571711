import React, { useEffect } from 'react';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';
import { useStore } from '../../../store/useStore';

const DeleteGroupModal = ({ isModalOpen, handleModalOpen }) => {
  const group = useStore((state) => state.groups.group);
  const isLoading = useStore((state) => state.groups.isLoading);
  const setGroup = useStore((state) => state.groups.setGroup);
  const deleteGroup = useStore((state) => state.groups.deleteGroup);
  useEffect(() => {
    return () => {
      setGroup({});
    };
  }, []);
  const handleSubmit = () => {
    deleteGroup(group.id);
  };
  return (
    <Modal title={'Apagar grupo'} show={isModalOpen} onClose={handleModalOpen} noDefaultHeight>
      <div className="p-5">
        <p>
          Você gostaria de apagar o grupo <span className="font-semibold">{group.name}</span>?
        </p>

        <div className="flex justify-around mt-5">
          <FilledButton
            className="px-10"
            onClick={handleModalOpen}
            bg={Colors.Gray1}
            color={Colors.black}>
            Cancelar
          </FilledButton>
          <FilledButton
            loading={isLoading}
            className="px-10"
            bg={Colors.Red}
            onClick={handleSubmit}>
            Apagar grupo
          </FilledButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
