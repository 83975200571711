import { api } from '../api/api';

const dashboardService = {
  getDashboard: async (filters) => {
    try {
      const response = await api.get('/', { params: filters });

      return response.data;
    } catch (error) {
      console.error(error);
      throw Error('Erro ao buscar dados da dashboard');
    }
  }
};

export default dashboardService;
