import React, { useEffect } from 'react';
import { ChevronDownIcon, PlusIcon } from '../../assets/imgs';
import SearchFilter from '../../components/Filters/SearchFilter';
import SelectFilter from '../../components/Filters/SelectFilter';
import OutlinedButton from '../../components/FormComponents/Buttons/OutlinedButton';
import { useStore } from '../../store/useStore';
import { Colors } from '../../utils/colors';
import { CUSTOMER_STATUSES } from '../../utils/constants';
import { StatusFilterIcon } from '../../assets/imgs';

import CreateCustomerModal from './components/CreateCustomerModal';
import CustomersTable from './components/CustomersTable';
import PageHeader from '../../components/PageHeader/PageHeader';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import DeleteCustomersModal from './components/DeleteCustomersModal';
import ImportCustomersModal from './components/ImportCustomersModal';
import CreateBillingsModal from '../../components/CreateBillingsModal/CreateBillingsModal';
import AssociateCustomersToGroups from './components/AssociateCustomersToGroups';
import AddGroupModal from '../Group/components/AddGroupModal';
import CreateManyBillingsError from './components/CreateManyBillingsError';

export default function Customers() {
  const showCreateCustomerModal = useStore((state) => state.customers.showCreateCustomerModal);
  const openCreateCustomerModal = useStore((state) => state.customers.openCreateCustomerModal);
  const openImportCustomersModal = useStore((state) => state.customers.openImportCustomersModal);
  const closeCreateCustomerModal = useStore((state) => state.customers.closeCreateCustomerModal);
  const isCustomersTableLoading = useStore((state) => state.customers.isCustomersTableLoading);
  const resetCustomersSelected = useStore((state) => state.customers.resetCustomersSelected);

  const openCreateBillingsModal = useStore((state) => state.billings.openCreateBillingsModal);

  const getCustomers = useStore((state) => state.customers.getCustomers);
  const customers = useStore((state) => state.customers.customers);

  const setFilter = useStore((state) => state.customers.setFilter);
  const statusFilter = useStore((state) => state.customers.filters.status);
  const searchFilter = useStore((state) => state.customers.filters.search);
  const groupFilter = useStore((state) => state.customers.filters.group);

  const selectedCustomers = useStore((state) => state.customers.selectedCustomers);
  const openDeleteCustomersModal = useStore((state) => state.customers.openDeleteCustomersModal);
  const toggleShowAssociateCustomersToGroupsModal = useStore(
    (state) => state.customers.toggleShowAssociateCustomersToGroupsModal
  );
  const changeCustomerPage = useStore((state) => state.customers.changeCustomerPage);

  const getGroupsOptions = useStore((state) => state.groups.getGroupsOptions);
  const toggleShowCreateGroupModal = useStore((state) => state.groups.toggleShowCreateGroupModal);

  const groupsFormatted = useStore((state) => state.groups.groupsFormatted);
  const resetGroupsFormatted = useStore((state) => state.groups.resetGroupsFormatted);

  const handleAssociateCustomersToGroups = () => {
    if (groupsFormatted.length === 0) {
      toggleShowAssociateCustomersToGroupsModal();
      toggleShowCreateGroupModal();
    } else {
      toggleShowAssociateCustomersToGroupsModal();
    }
  };

  const showCreateGroupModal = useStore((state) => state.groups.showCreateGroupModal);

  useEffect(() => {
    getGroupsOptions();
    return () => {
      setFilter('search', '');
      setFilter('status', '');
      setFilter('group', '');
      resetGroupsFormatted();
    };
  }, []);

  useEffect(() => {
    resetCustomersSelected();
    getCustomers();
  }, [statusFilter, searchFilter, groupFilter]);

  const handleChangeSearchFilter = (text) => {
    changeCustomerPage(1, false);
    setFilter('search', text);
  };

  const handleChangeStatusFilter = (status) => {
    setFilter('status', status.value);
  };

  const handleGroupFilter = (group) => {
    setFilter('group', group.value.id);
  };
  return (
    <div>
      <div>
        <PageHeader title="Clientes" />
        <div className="flex gap-4 flex-wrap">
          <SearchFilter onChange={handleChangeSearchFilter} className="w-[160px]" />
          <SelectFilter
            onChange={handleChangeStatusFilter}
            options={CUSTOMER_STATUSES}
            iconComponent={StatusFilterIcon}
            placeholder="Situação"
          />
          {!selectedCustomers?.length > 0 ? (
            <SelectFilter
              onChange={handleGroupFilter}
              options={[{ value: { id: '', name: 'Todos' }, label: 'Todos' }, ...groupsFormatted]}
              iconComponent={ChevronDownIcon}
              placeholder="Grupo"
            />
          ) : null}
          <OutlinedButton onClick={openCreateCustomerModal} className="px-5">
            <div className="flex gap-3 items-center h-[40px]">
              <div className="font-semibold">Novo Cliente</div>
              <PlusIcon color={Colors.Orange} />
            </div>
          </OutlinedButton>
          <OutlinedButton onClick={openImportCustomersModal} className="px-8">
            <div className="font-semibold h-[42px] flex items-center">Importar Clientes</div>
          </OutlinedButton>
          {selectedCustomers?.length > 0 ? (
            <>
              <FilledButton
                bg={Colors.Orange}
                onClick={handleAssociateCustomersToGroups}
                className="px-5 h-[42px]"
                heightClass="min-h-[42px]">
                <div className="font-semibold">Selecionar grupos</div>
              </FilledButton>
              <FilledButton
                bg={Colors.Orange}
                onClick={openCreateBillingsModal}
                className="px-5 h-[42px]"
                heightClass="min-h-[42px]">
                <div className="font-semibold">Criar Cobranças</div>
              </FilledButton>
              <FilledButton
                bg={Colors.Red}
                onClick={openDeleteCustomersModal}
                className="px-5 h-[42px]"
                heightClass="min-h-[42px]">
                <div className="font-semibold">Apagar</div>
              </FilledButton>
            </>
          ) : null}
        </div>
        <div className="max-w-[100%] mt-5">
          <CustomersTable data={customers} loading={isCustomersTableLoading} />
        </div>
      </div>
      <CreateCustomerModal show={showCreateCustomerModal} onClose={closeCreateCustomerModal} />
      <ImportCustomersModal />
      <DeleteCustomersModal />
      <CreateBillingsModal />
      <AssociateCustomersToGroups />
      <CreateManyBillingsError />
      <AddGroupModal
        isModalOpen={showCreateGroupModal}
        handleModalOpen={toggleShowCreateGroupModal}
      />
    </div>
  );
}
