import React from 'react';
import { useStore } from '../../../store/useStore';
import { useNavigate } from 'react-router-dom';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import Modal from '../../../components/FormComponents/Modal/Modal';

export default function DeleteCustomersModal() {
  const deleteCustomers = useStore((state) => state.customers.deleteCustomers);
  const showDeleteCustomersModal = useStore((state) => state.customers.showDeleteCustomersModal);
  const closeDeleteCustomersModal = useStore((state) => state.customers.closeDeleteCustomersModal);
  const isDeletingCustomersLoading = useStore(
    (state) => state.customers.isDeletingCustomersLoading
  );

  const navigate = useNavigate();

  const handleDeleteCustomers = () => {
    deleteCustomers(navigate);
  };

  return (
    <Modal
      title="Apagar clientes"
      onClose={closeDeleteCustomersModal}
      show={showDeleteCustomersModal}
      noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          Tem certeza que deseja apagar os clientes selecionados? Depois de feito, você não pode
          desfazer essa ação.
        </div>
      </div>
      <div className="flex p-5 justify-around">
        <FilledButton
          className="px-10"
          onClick={closeDeleteCustomersModal}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={handleDeleteCustomers}
          loading={isDeletingCustomersLoading}
          className="px-10"
          bg={Colors.Red}>
          Apagar
        </FilledButton>
      </div>
    </Modal>
  );
}
