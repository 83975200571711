import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import Input from '../../../components/FormComponents/Inputs/Input';
import RadioButton from '../../../components/FormComponents/RadioButton/RadioButton';
import SelectInput from '../../../components/FormComponents/SelectFields/SelectInput';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';

export default function Step2({ show, onPrevious, onNext }) {
  const getRegionsAvaiable = useStore((state) => state.staticData.getRegionsAvaiable);
  const regionsAvaiable = useStore((state) => state.staticData.regionsAvaiable);
  const getCitiesAvaiable = useStore((state) => state.staticData.getCitiesAvaiable);
  const citiesAvaiable = useStore((state) => state.staticData.citiesAvaiable);
  const getCompanyTypesAvaiable = useStore((state) => state.company.getCompanyTypesAvaiable);
  const companyTypesAvaiable = useStore((state) => state.company.companyTypesAvaiable);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';
  const updateSignUpData = useStore((state) => state.auth.updateSignUpData);

  useEffect(() => {
    getRegionsAvaiable();
    getCompanyTypesAvaiable();
  }, []);

  return (
    <Formik
      initialValues={{
        isIndividualCompany: 'yes',
        owner: '',
        company: '',
        street: '',
        complement: '',
        nuit: '',
        companyType: '',
        city: '',
        province: '',
        postalCode: ''
      }}
      validationSchema={Yup.object({
        isIndividualCompany: Yup.string().required('Campo obrigatório'),
        owner: Yup.string().when('isIndividualCompany', {
          is: (val) => val == 'yes',
          then: (schema) =>
            schema
              .required('Campo obrigatório')
              .matches(/^[\p{L}']+([\p{L} ,.'-]*[\p{L}]+)*$/u, 'Nome inválido'),
          otherwise: (schema) => schema
        }),
        company: Yup.string().required('Campo obrigatório').min(2, 'Nome inválido'),
        nuit: Yup.number()
          .typeError('O NUIT contém somente números')
          .positive('O NUIT contém somente número positivos')
          .integer('O NUIT contém somente números inteiros')
          .test('len', 'O NUIT possuí no máximo 9 números', (val) => val.toString().length <= 9)
          .required('Campo obrigatório'),
        street: Yup.string().required('Campo obrigatório'),
        complement: Yup.string(),
        province: Yup.string().required('Campo obrigatório'),
        companyType: Yup.string().required('Campo obrigatório'),
        city: Yup.string().required('Campo obrigatório'),
        postalCode: Yup.number()
          .typeError('O código postal contém somente números')
          .positive('O código postal contém somente número positivos')
          .integer('O código postal contém somente números inteiros')
        // .required('Campo obrigatório')
      })}
      onSubmit={(values) => {
        updateSignUpData(values);
        onNext();
      }}>
      {(formik) => {
        return (
          <form
            onSubmit={formik.handleSubmit}
            className={`grid h-full overflow-hidden z-auto ${show ? 'block' : 'hidden'}`}>
            <div className="px-6 md:px-12 h-full overflow-y-auto py-5 flex flex-col gap-5">
              <div>
                <div className="font-medium mb-2">É uma Empresa Individual(EI)?</div>
                <div className="flex gap-5">
                  <RadioButton
                    label="Sim"
                    checked={formik.values.isIndividualCompany === 'yes'}
                    value="yes"
                    onChange={() => formik.setFieldValue('isIndividualCompany', 'yes')}
                  />
                  <RadioButton
                    label="Não"
                    checked={formik.values.isIndividualCompany === 'no'}
                    value="no"
                    onChange={() => formik.setFieldValue('isIndividualCompany', 'no')}
                  />
                </div>
              </div>
              {formik.values.isIndividualCompany == 'yes' ? (
                <Input
                  placeholder="Digite o nome do proprietário"
                  type="text"
                  label="Nome do proprietário"
                  error={errorMessage(formik, 'owner')}
                  {...formik.getFieldProps('owner')}
                />
              ) : null}
              <Input
                placeholder="Digite o nome do empresa"
                type="text"
                label="Nome da empresa"
                error={errorMessage(formik, 'company')}
                {...formik.getFieldProps('company')}
              />
              <Input
                placeholder="Digite o NUIT"
                type="text"
                label="NUIT"
                error={errorMessage(formik, 'nuit')}
                {...formik.getFieldProps('nuit')}
              />
              <Field
                name={`companyType`}
                component={SelectInput}
                menuPosition="fixed"
                label="Segmento"
                options={companyTypesAvaiable}
                menuPlacement="top"
                placeholder="Digite o Segmento"
                error={errorMessage(formik, 'companyType')}
              />

              <div className="flex gap-10">
                <div className="flex-1">
                  <Input
                    placeholder="Digite o endereço"
                    type="text"
                    label="Endereço"
                    error={errorMessage(formik, 'street')}
                    {...formik.getFieldProps('street')}
                  />
                </div>
                <div className="flex-1">
                  <Input
                    placeholder="Digite o Nº(Apt./Suite)"
                    type="text"
                    label="N°"
                    error={errorMessage(formik, 'complement')}
                    {...formik.getFieldProps('complement')}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="flex-1">
                  <Field
                    name={`province`}
                    onChange={(option) => {
                      formik.setFieldValue('province', option.value);
                      getCitiesAvaiable(option.value);
                      formik.setFieldValue('city', '');
                    }}
                    component={SelectInput}
                    menuPosition="fixed"
                    label="Província"
                    options={regionsAvaiable}
                    menuPlacement="top"
                    placeholder="Digite a província"
                    error={errorMessage(formik, 'province')}
                  />
                </div>
                <div className="flex-1">
                  <Field
                    name={`city`}
                    component={SelectInput}
                    menuPosition="fixed"
                    label="Cidade"
                    options={citiesAvaiable}
                    menuPlacement="top"
                    placeholder="Digite a cidade"
                    error={errorMessage(formik, 'city')}
                  />
                </div>
              </div>
              <div className="flex gap-10">
                <div className="flex-1">
                  <Input
                    placeholder="Digite o código postal"
                    type="text"
                    label="Código postal"
                    error={errorMessage(formik, 'postalCode')}
                    {...formik.getFieldProps('postalCode')}
                  />
                </div>
                <div className="flex-1"></div>
              </div>
            </div>
            <div className="flex justify-between bg-white sticky bottom-0 p-5 self-end">
              <FilledButton
                onClick={onPrevious}
                type="button"
                bg={Colors.Gray1}
                color={Colors.Orange}>
                Voltar
              </FilledButton>
              <FilledButton type="submit">Avançar</FilledButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
