import React from 'react';

export default function PlusIcon({ width = 11, height = 11, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.52557 10.2116V0.154829H7.06818V10.2116H4.52557ZM0.768466 6.45455V3.91193H10.8253V6.45455H0.768466Z"
        fill={color}
      />
    </svg>
  );
}
