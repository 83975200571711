import React from 'react';
import FieldErrorMessage from '../FieldErrorMessage/FieldErrorMessage';
import InputMask from 'react-input-mask';

export default function BorderlessMaskedInput(props) {
  return (
    <div>
      <InputMask
        mask={props.mask}
        alwaysShowMask={props.alwaysShowMask}
        maskPlaceholder={props.maskPlaceholder}
        {...props.field}
        onChange={props.onChange ? props.onChange : props.field.onChange}>
        <input
          style={{
            border: 'none',
            outline: 'none',
            padding: '15px',
            width: '100%',
            paddingBottom: props.error ? '0' : '15px'
          }}
          {...props}
        />
      </InputMask>
      <FieldErrorMessage className="pl-[15px]">{props.error}</FieldErrorMessage>
    </div>
  );
}
