import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import FilledButton from '../../FormComponents/Buttons/FilledButton';
import BorderedMaskedInput from '../../FormComponents/Inputs/BorderedMaskedInput';
import { onlyPositiveNumbers } from '../../../utils/currencyHelper';
import Checkbox from '../../FormComponents/Checkbox/Checkbox';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';
import CustomError from '../../CreateBillingModal/Steps/CustomError';

export default function Step3({ show, onNext, onPrevious }) {
  const updateCreateBillingsData = useStore((state) => state.billings.updateCreateBillingsData);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <Formik
      initialValues={{
        daysBeforeDueDate: '3',
        daysBeforeDueDateNotifications: ['email'],
        dueDateNotifications: ['email'],
        daysAfterDueDate: '3',
        daysAfterDueDateNotifications: ['email']
      }}
      validationSchema={Yup.object({
        daysBeforeDueDate: Yup.string(),
        daysBeforeDueDateNotifications: Yup.array(),
        dueDateNotifications: Yup.array(),
        daysAfterDueDate: Yup.string(),
        daysAfterDueDateNotifications: Yup.array()
      })}
      onSubmit={(values) => {
        if (
          values.daysAfterDueDateNotifications.length === 0 ||
          values.dueDateNotifications.length === 0 ||
          values.daysBeforeDueDateNotifications.length === 0
        ) {
          return;
        }
        updateCreateBillingsData(values);
        onNext();
      }}>
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
          <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
            <div className="font-medium text-lg">Como você irá notificar o cliente?</div>
            <div>
              Com até quantos dias de <span className="font-bold">antecedência</span> e como você
              deseja notificar o cliente?
              <br />
              <span className="text-gray-3">
                (enviamos como padrão a partir de 5 dias antes do vencimento)
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <BorderedMaskedInput
                placeholder="Digite o número de dias"
                type="text"
                mask={[/[1-9]/, /[0-9]/]}
                alwaysShowMask={true}
                beforeMaskedStateChange={onlyPositiveNumbers}
                maskPlaceholder={null}
                error={errorMessage(formik, 'daysBeforeDueDate')}
                {...formik.getFieldProps('daysBeforeDueDate')}
              />
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="sms"
                  label="SMS"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysBeforeDueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`daysBeforeDueDateNotifications`)}
                  value="email"
                  label="Email"
                />
              </div>
            </div>
            <CustomError
              isValid={formik.values.daysBeforeDueDateNotifications.length === 0}
              message={'Selecione pelo menos uma opção de notificação'}
            />
            <div>
              Como deseja notificar o cliente <span className="font-bold">no vencimento</span>?
            </div>
            <div className="flex gap-3 items-center">
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="sms"
                  label="SMS"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.dueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`dueDateNotifications`)}
                  value="email"
                  label="Email"
                />
              </div>
            </div>
            <CustomError
              isValid={formik.values.dueDateNotifications.length === 0}
              message={'Selecione pelo menos uma opção de notificação'}
            />
            <div>
              Com até quantos dias de <span className="font-bold">após o vencimento</span> e como
              você deseja notificar o cliente?
              <br />
              <span className="text-gray-3">
                (enviamos como padrão a partir de 3 dias após o vencimento)
              </span>
            </div>
            <div className="flex gap-3 items-center">
              <BorderedMaskedInput
                placeholder="Digite o número de dias"
                type="text"
                mask={[/[1-9]/, /[0-9]/]}
                alwaysShowMask={true}
                beforeMaskedStateChange={onlyPositiveNumbers}
                maskPlaceholder={null}
                error={errorMessage(formik, 'daysAfterDueDate')}
                {...formik.getFieldProps('daysAfterDueDate')}
              />
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('whatsapp')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="whatsapp"
                  label="Whatsapp"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('sms')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="sms"
                  label="SMS"
                />
              </div>
              <div>
                <Checkbox
                  checked={formik.values.daysAfterDueDateNotifications.includes('email')}
                  {...formik.getFieldProps(`daysAfterDueDateNotifications`)}
                  value="email"
                  label="Email"
                />
              </div>
            </div>
            <CustomError
              isValid={formik.values.daysAfterDueDateNotifications.length === 0}
              message={'Selecione pelo menos uma opção de notificação'}
            />
            <div>
              Obs: Caso o pagamento da cobrança já tenha sido identificado, as futuras notificações
              não serão enviadas e cobradas.
            </div>
          </div>
          <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
            <FilledButton onClick={onPrevious} bg={Colors.Gray1} color={Colors.Orange}>
              Voltar
            </FilledButton>
            <FilledButton type="submit">Avançar</FilledButton>
          </div>
        </form>
      )}
    </Formik>
  );
}
