import React, { useEffect } from 'react';
import { StatusIcon } from '../../../assets/imgs';
import PersonIcon from '../../../assets/imgs/PersonIcon';
import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import { formatDateToText } from '../../../utils/dateHelpers';
import { CUSTOMER_STATUSES_LABEL, CUSTOMER_STATUS_COLOR } from '../../../utils/constants';
import useResponsivity from '../../../hooks/useResponsivity';
import { useNavigate } from 'react-router';
import { useStore } from '../../../store/useStore';
import Pagination from '../../../components/Pagination/Pagination';

export default function CustomersTable({ data, loading = false }) {
  const { isMobile } = useResponsivity();
  const hiddenColumns = isMobile ? ['status', 'created_at', 'last_contact'] : [];
  const navigate = useNavigate();
  const selectCustomers = useStore((state) => state.customers.selectCustomers);
  const selectedCustomers = useStore((state) => state.customers.selectedCustomers);
  const resetCustomersSelected = useStore((state) => state.customers.resetCustomersSelected);

  const customersCurrentPage = useStore((state) => state.customers.customersCurrentPage);
  const customersTotalPages = useStore((state) => state.customers.customersTotalPages);
  const changeCustomerPage = useStore((state) => state.customers.changeCustomerPage);

  const onClickRow = (row) => {
    navigate('/clientes/' + row.original.id);
  };

  const onSelectRow = (customers, checked) => {
    selectCustomers(customers, checked);
  };

  useEffect(() => {
    return () => {
      resetCustomersSelected();
    };
  }, []);

  const columns = [
    {
      Header: 'Nome',
      accessor: 'name', // accessor is the "key" in the data
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <div className="!w-6">
            <PersonIcon
              width={24}
              height={24}
              color={CUSTOMER_STATUS_COLOR[row.original.customer_situation]}
            />
          </div>
          {row.original.first_name} {row.original.last_name}
        </div>
      )
    },
    {
      Header: 'Contato',
      accessor: 'phone',
      Cell: ({ row }) => (
        <div>
          <div>{row.original.phone}</div>
          <div className="text-sm">{row.original.email}</div>
        </div>
      )
    },
    {
      Header: 'Situação',
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <StatusIcon color={CUSTOMER_STATUS_COLOR[row.original.customer_situation]} />
          <div style={{ color: CUSTOMER_STATUS_COLOR[row.original.customer_situation] }}>
            {CUSTOMER_STATUSES_LABEL[row.original.customer_situation]}
          </div>
        </div>
      )
    },
    {
      Header: 'Criado em',
      accessor: 'created_at',
      Cell: ({ row }) => (
        <div>
          <div>{formatDateToText(new Date(row.original.created_at))}</div>
        </div>
      )
    },
    {
      Header: 'Último contato',
      accessor: 'updated_at',
      Cell: ({ row }) => (
        <div>
          <div>{formatDateToText(new Date(row.original.updated_at))}</div>
        </div>
      )
    }
  ];
  return (
    <>
      <DataTable
        loading={loading}
        onClickRow={onClickRow}
        onSelectRow={onSelectRow}
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
        selectedRows={selectedCustomers}
      />
      <div className="mt-5 flex justify-center">
        <Pagination
          currentPage={customersCurrentPage}
          totalPages={customersTotalPages}
          changePage={changeCustomerPage}
        />
      </div>
    </>
  );
}
