import React from 'react';

export default function ResumeIcon({ width = 22, height = 22, color = '#5E5E5E' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 3C1 1.89543 1.89543 1 3 1H6.77778C7.88235 1 8.77778 1.89543 8.77778 3V9C8.77778 10.1046 7.88235 11 6.77778 11H3C1.89543 11 1 10.1046 1 9V3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M1 17.4445C1 16.3399 1.89543 15.4445 3 15.4445H6.77778C7.88235 15.4445 8.77778 16.3399 8.77778 17.4445V19C8.77778 20.1046 7.88235 21 6.77778 21H3C1.89543 21 1 20.1046 1 19V17.4445Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.2222 3C13.2222 1.89543 14.1177 1 15.2222 1H19C20.1046 1 21 1.89543 21 3V4.55556C21 5.66013 20.1046 6.55556 19 6.55556H15.2222C14.1177 6.55556 13.2222 5.66012 13.2222 4.55555V3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.2222 13C13.2222 11.8954 14.1177 11 15.2222 11H19C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H15.2222C14.1177 21 13.2222 20.1046 13.2222 19V13Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}
