import React from 'react';

export default function EyeClosedIcon({ width = 22, height = 16, color }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 8C20 8 15.9706 14 11 14C6.02944 14 2 8 2 8C2 8 6.02944 2 11 2C15.9706 2 20 8 20 8Z"
        stroke={color}
        strokeWidth="2"
      />
      <circle cx="11" cy="8" r="3" stroke={color} strokeWidth="2" />
      <path
        d="M18 1L4 15"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
