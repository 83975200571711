import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { Colors } from '../../../utils/colors';
import moment from 'moment';
import { abrevNumber } from '../../../utils/currencyHelper';
import CustomTooltip from './CustomTooltip';
import useResponsivity from '../../../hooks/useResponsivity';

export default function BillingChart({ data }) {
  const { isMobile } = useResponsivity();

  const tickFormatter = (number) =>
    number === 0 ? '' : (isMobile ? '' : 'MZN ') + abrevNumber(number);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 30,
          right: 0,
          left: 0,
          bottom: 0
        }}>
        <CartesianGrid fillDasharray="3 3" />
        <defs>
          <linearGradient id="received" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={Colors.Green} stopOpacity={0.3} />
            <stop offset="95%" stopColor={Colors.White} stopOpacity={0.3} />
          </linearGradient>
          <linearGradient id="to_confirm" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={Colors.Blue} stopOpacity={0.3} />
            <stop offset="95%" stopColor={Colors.White} stopOpacity={0.3} />
          </linearGradient>
          <linearGradient id="planned" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={Colors.Yellow} stopOpacity={0.3} />
            <stop offset="95%" stopColor={Colors.White} stopOpacity={0.3} />
          </linearGradient>
        </defs>
        <XAxis
          stroke={Colors.black}
          dataKey="due_date"
          style={{
            fontSize: '0.875rem'
          }}
          tickFormatter={(label) => moment.utc(label)?.format('MMM DD')}
        />
        <YAxis
          stroke={Colors.black}
          orientation="left"
          width={isMobile ? 55 : 100}
          label={isMobile ? { value: 'MZN', position: 'top', fill: Colors.black, offset: 15 } : {}}
          style={{
            fontSize: '0.875rem'
          }}
          tickFormatter={tickFormatter}
        />
        <Tooltip content={<CustomTooltip />} />
        <Area
          strokeWidth={2}
          type="monotone"
          dataKey="received"
          stroke={Colors.Green}
          fillOpacity={1}
          fill="url(#received)"
          // fill={Colors.Green}
        />
        <Area
          strokeWidth={2}
          type="monotone"
          dataKey="to_confirm"
          stroke={Colors.Blue}
          fillOpacity={1}
          fill="url(#to_confirm)"
          // fill={Colors.Blue}
        />
        <Area
          strokeWidth={2}
          type="monotone"
          dataKey="planned"
          stroke={Colors.Yellow}
          fillOpacity={1}
          fill="url(#planned)"
          // fill={Colors.Yellow}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
