import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import LabelWrapper from '../../../components/FormComponents/LabelWrapper/LabelWrapper';
import Modal from '../../../components/FormComponents/Modal/Modal';
import BorderlessSelect from '../../../components/FormComponents/SelectFields/BorderlessSelect';
import { DAYS, MONTHS, YEARS } from '../../../utils/dateHelpers';
import * as Yup from 'yup';
import { useStore } from '../../../store/useStore';
import BorderlessInput from '../../../components/FormComponents/Inputs/BorderlessInput';
import BorderlessMaskedInput from '../../../components/FormComponents/Inputs/BorderlessMaskedInput';
import BorderlessStaticText from '../../../components/FormComponents/Inputs/BorderlessStaticText';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import { toast } from 'react-toastify';
import BorderlessMultiSelect from '../../../components/FormComponents/SelectFields/BorderlessMultiSelect';

export default function CreateCustomerModal({ show, onClose }) {
  const createCustomer = useStore((state) => state.customers.createCustomer);
  const isCreateCustomerLoading = useStore((state) => state.customers.isCreateCustomerLoading);
  const getCitiesAvaiable = useStore((state) => state.staticData.getCitiesAvaiable);
  const getRegionsAvaiable = useStore((state) => state.staticData.getRegionsAvaiable);
  const citiesAvaiable = useStore((state) => state.staticData.citiesAvaiable);
  const regionsAvaiable = useStore((state) => state.staticData.regionsAvaiable);
  const getGroups = useStore((state) => state.groups.getGroups);
  const groups = useStore((state) => state.groups.groups);
  useEffect(() => {
    getRegionsAvaiable();
    getGroups();
  }, []);

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const errorMessageObject = (formik, topic, field) =>
    formik.touched[topic] &&
    formik.errors[topic] &&
    formik.touched[topic][field] &&
    formik.errors[topic][field] ? (
      <div>{formik.errors[topic][field]}</div>
    ) : (
      ''
    );
  return (
    <Modal title="Criar Cliente" onClose={onClose} show={show}>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          phone: '+258',
          email: '',
          bi: '',
          address: {
            street: '',
            apartment: '',
            city: '',
            province: '',
            postalCode: ''
          },
          groups: [],
          company: '',
          referenceId: '',
          birthday: {
            month: '',
            day: '',
            year: ''
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('Campo obrigatório')
            .matches(/^[\p{L}']+([\p{L} ,.'-]*[\p{L}]+)*$/u, 'Nome inválido'),
          lastName: Yup.string()
            .required('Campo obrigatório')
            .matches(/^[\p{L}']+([\p{L} ,.'-]*[\p{L}]+)*$/u, 'Nome inválido'),
          phone: Yup.string(),
          // .matches(
          //   /^\+258 (86|87|84|82|83|85) \d\d\d \d\d\d\d$/,
          //   'O telefone deve ter o formato +258 XX XXX XXXX'
          // )
          // .required('Campo obrigatório'),
          bi: Yup.string(),
          // .matches(
          //   /^\d{12}[A-Z]{1}$/,
          //   'O BI deve possuir 12 números e 1 letra maiúscula ao final'
          // )
          // .required('Campo obrigatório'),
          email: Yup.string().email('Endereço de email inválido'),
          // .required('Campo obrigatório'),
          address: Yup.object({
            street: Yup.string(),
            // .required('Campo obrigatório'),
            apartment: Yup.string(),
            city: Yup.string(),
            // .required('Campo obrigatório'),
            province: Yup.string()
            // .required('Campo obrigatório')
            // postalCode: Yup.string().required('Campo obrigatório')
          }),
          groups: Yup.array(),
          company: Yup.string().min(2, 'Nome da empresa muito curto'),
          referenceId: Yup.string(),
          birthday: Yup.object({
            month: Yup.string(),
            // .required('Campo obrigatório'),
            day: Yup.string(),
            // .required('Campo obrigatório'),
            year: Yup.string()
            // .required('Campo obrigatório')
          })
        })}
        onSubmit={(values) => {
          if (!values.email && values.phone.length < 16) {
            toast.error('Preencha pelo menos o email ou telefone');
            return;
          }
          createCustomer(values);
        }}>
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit} className="grid h-full overflow-hidden">
              <div className="h-full overflow-y-auto p-5">
                <LabelWrapper label="Primeiro Nome">
                  <BorderlessInput
                    name="firstName"
                    placeholder="Primeiro Nome"
                    error={errorMessage(formik, 'firstName')}
                    {...formik.getFieldProps('firstName')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Último Nome">
                  <BorderlessInput
                    name="lastName"
                    placeholder="Último Nome"
                    error={errorMessage(formik, 'lastName')}
                    {...formik.getFieldProps('lastName')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Identidade(BI/NUIT)">
                  <BorderlessInput
                    name="bi"
                    placeholder="Identidade(BI/NUIT)"
                    error={errorMessage(formik, 'bi')}
                    {...formik.getFieldProps('bi')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Telefone">
                  <BorderlessStaticText color={Colors.Orange}>Moçambique</BorderlessStaticText>
                  <Field
                    component={BorderlessMaskedInput}
                    name="phone"
                    mask="+258 99 999 9999"
                    alwaysShowMask={true}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'phone')}
                    {...formik.getFieldProps('phone')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Email">
                  <BorderlessInput
                    name="email"
                    placeholder="Email"
                    error={errorMessage(formik, 'email')}
                    {...formik.getFieldProps('email')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Endereço">
                  <BorderlessStaticText color={Colors.Orange}>Moçambique</BorderlessStaticText>
                  <BorderlessInput
                    name="address.street"
                    placeholder="Endereço de Rua"
                    borderBottom
                    error={errorMessageObject(formik, 'address', 'street')}
                    {...formik.getFieldProps('address.street')}
                  />
                  <BorderlessInput
                    name="address.apartment"
                    placeholder="Apt./Suite"
                    borderBottom
                    error={errorMessageObject(formik, 'address', 'apartment')}
                    {...formik.getFieldProps('address.apartment')}
                  />
                  <div className="pt-2 pb-2.5 px-3.5 border-b border-gray-2">
                    <Field
                      component={BorderlessSelect}
                      name="address.province"
                      onChange={(option) => {
                        formik.setFieldValue('address.province', option.value);
                        getCitiesAvaiable(option.value);
                        formik.setFieldValue('address.city', '');
                      }}
                      menuPosition="fixed"
                      placeholder="Província"
                      options={regionsAvaiable}
                      error={errorMessageObject(formik, 'address', 'province')}
                    />
                  </div>
                  <div className="pt-2 pb-2.5 px-3.5 border-b border-gray-2">
                    <Field
                      component={BorderlessSelect}
                      menuPosition="fixed"
                      name="address.city"
                      placeholder="Cidade"
                      options={citiesAvaiable}
                      error={errorMessageObject(formik, 'address', 'city')}
                    />
                  </div>
                  <BorderlessInput
                    name="address.postalCode"
                    placeholder="Código Postal"
                    error={errorMessageObject(formik, 'address', 'postalCode')}
                    {...formik.getFieldProps('address.postalCode')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Grupos">
                  <BorderlessMultiSelect
                    name="groups"
                    options={groups.map((g) => ({ value: g.id, label: g.name }))}
                    placeholder="Grupos"
                    {...formik.getFieldProps('groups')}
                    onChange={(value) => {
                      formik.setFieldValue(
                        'groups',
                        value.map((v) => v.value)
                      );
                    }}
                  />
                </LabelWrapper>
                <LabelWrapper label="Empresa">
                  <BorderlessInput
                    name="company"
                    placeholder="Empresa"
                    error={errorMessage(formik, 'company')}
                    {...formik.getFieldProps('company')}
                  />
                </LabelWrapper>
                <LabelWrapper label="ID de Referência">
                  <BorderlessInput
                    name="referenceId"
                    placeholder="ID de Referência"
                    error={errorMessage(formik, 'referenceId')}
                    {...formik.getFieldProps('referenceId')}
                  />
                </LabelWrapper>
                <LabelWrapper label="Aniversário" borderBottom>
                  <div className="flex flex-col md:flex-row justify-between gap-5 pt-2 px-3.5">
                    <Field
                      component={BorderlessSelect}
                      menuPosition="fixed"
                      color={Colors.Orange}
                      placeholder="Dia"
                      menuPlacement="top"
                      name="birthday.day"
                      options={DAYS}
                      error={errorMessageObject(formik, 'birthday', 'day')}
                    />
                    <Field
                      component={BorderlessSelect}
                      menuPosition="fixed"
                      color={Colors.Orange}
                      placeholder="Mês"
                      menuPlacement="top"
                      name="birthday.month"
                      options={MONTHS}
                      error={errorMessageObject(formik, 'birthday', 'month')}
                    />
                    <Field
                      component={BorderlessSelect}
                      menuPosition="fixed"
                      color={Colors.Orange}
                      placeholder="Ano"
                      menuPlacement="top"
                      name="birthday.year"
                      options={YEARS}
                      error={errorMessageObject(formik, 'birthday', 'year')}
                    />
                  </div>
                </LabelWrapper>
              </div>
              <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
                <FilledButton onClick={onClose} bg={Colors.Gray1} color={Colors.Orange}>
                  Cancelar
                </FilledButton>
                <FilledButton loading={isCreateCustomerLoading} type="submit">
                  Criar
                </FilledButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
