var MockAdapter = require('axios-mock-adapter');

export function initAPIPublicMock(apiPublic) {
  var mockAPIPublic = new MockAdapter(apiPublic, { delayResponse: 2000 });

  mockAPIPublic.onPost('/session', { email: 'contato@piminder.com', password: '123' }).reply(200, {
    token: 'token'
  });

  mockAPIPublic.onPost('/signup').reply(200);

  mockAPIPublic.onGet('/banks').reply(200, [
    { value: 'banco_1', label: 'Banco 1' },
    { value: 'banco_2', label: 'Banco 2' },
    { value: 'banco_3', label: 'Banco 3' }
  ]);

  mockAPIPublic.onGet('/wallets').reply(200, [
    { value: 'carteira_1', label: 'Carteira 1' },
    { value: 'carteira_2', label: 'Carteira 2' },
    { value: 'carteira_3', label: 'Carteira 3' }
  ]);

  mockAPIPublic.onGet('/cities').reply(200, [
    { value: 'cidade_1', label: 'Cidade 1' },
    { value: 'cidade_2', label: 'Cidade 2' },
    { value: 'cidade_3', label: 'Cidade 3' }
  ]);

  mockAPIPublic.onGet(/\/links\/\d+/).reply(200, {
    company: {
      name: 'Academia Vivabem',
      nuit: '123 456 789',
      email: 'contacto@vivabem.com',
      phone: '+258 88 999 9999',
      city: 'Maputo',
      country: 'Moçambique'
    },
    status: 'wait_payment',
    invoice: {
      id: 123,
      value: 100,
      due_date: '2023-02-03',
      description: 'Mensalidade do Plano Gold da Academia'
    },
    customer: {
      name: 'Ussene Indobe',
      email: 'ussene12@gmail.com'
    },
    paymentMethods: [
      {
        type: 'bank',
        account: '1234 1234 8888 9999 888 99'
      },
      {
        type: 'mpesa',
        account: '+258 84 073 2418'
      }
    ]
  });

  mockAPIPublic.onPost(/\/confirm-link\/\d+/).reply(200);
}

export function initAPIMock(api) {
  var mockApi = new MockAdapter(api, { delayResponse: 2000 });

  mockApi.onPost('/customer').reply(200);
  mockApi.onPost('/customer/upload').reply(200);
  mockApi.onPost('/customer/delete').reply(200);
  mockApi.onPost('/billing').reply(200);
  mockApi.onPost('/billing/delete').reply(200);

  mockApi.onPut(/^\/customer\/(\d+)/).reply(200);
  mockApi.onPut(/^\/company\/(\d+)/).reply(200);

  mockApi
    .onGet(/^\/customer\/(\d+)(\/signatures)?(\/items)?(\/notifications)?$/)
    .reply((config) => {
      const id = config.url.match(/^\/customer\/(\d+)/)[1];
      const hasSignaturesPath = config.url.includes('/signatures');
      const hasItemsPath = config.url.includes('/items');
      const hasNotificationsPath = config.url.includes('/notifications');

      let data = {};
      if (hasSignaturesPath) {
        data = {
          page: 1,
          total_pages: 10,
          data: [
            {
              first_month: '09-09-2023',
              signature: 'Plano Silver',
              interval: 1,
              value: 3000,
              situation: 'finished'
            },
            {
              first_month: '09-09-2023',
              signature: 'Plano Silver',
              interval: 1,
              value: 3000,
              situation: 'finished'
            }
          ]
        };
      } else if (hasItemsPath) {
        data = {
          page: 1,
          total_pages: 10,
          data: [
            {
              date: '09-09-2023',
              item: 'Plano Silver',
              installments: 12,
              situation: 'in_day',
              installment_value: 2500,
              total_value: 30000
            },
            {
              date: '09-09-2023',
              item: 'Plano Silver',
              installments: 12,
              situation: 'in_day',
              installment_value: 2500,
              total_value: 30000
            }
          ]
        };
      } else if (hasNotificationsPath) {
        data = {
          page: 1,
          total_pages: 10,
          data: [
            {
              date: '09-09-2023 09:12:00',
              description: 'Enviado SMS de vencimento no número +258 94 999 999',
              billing_id: 123
            },
            {
              date: '09-09-2023 09:12:00',
              description: 'Enviado E-mail de vencimento para ussene@gmail.com',
              billing_id: 124
            }
          ]
        };
      } else {
        data = {
          id: parseInt(id),
          status: 'em dia',
          firstName: 'Ussene',
          lastName: 'Indobe',
          phone: '+258 84 073 2418',
          email: 'test@gmail.com',
          address: {
            street: 'Rua 1',
            apartment: 'Apart 2',
            city: 'City 1',
            province: 'Province 1',
            postalCode: '123'
          },
          groups: '',
          company: 'Academi Vivabem',
          referenceId: '123',
          birthday: {
            month: 1,
            day: 1,
            year: 2023
          }
        };
      }

      return [200, data];
    });

  mockApi.onGet(/\/company\/\d+/).reply((config) => {
    const id = config.url.match(/^\/company\/(\d+)/)[1];

    let data = {};

    data = {
      id,
      name: 'Academia Viva Bem',
      phone: '+258 84 073 2418',
      email: 'contacto@vivabem.com',
      address: {
        street: 'Rua 1',
        apartment: 'Apart 2',
        city: 'City 1',
        province: 'Province 1',
        postalCode: '123'
      },
      nuit: '1111111',
      owner: 'Carlos Alberto',
      isIndividualCompany: true,
      plan: 'starter',
      banks: [{ bank: 'BCI', account: '22222', nib: '1111' }],
      mobileWallets: [{ wallet: 'M-Pesa', number: '1111' }],
      receivePaymentMethods: ['bank_deposit', 'mobile_wallet', 'numberary']
    };

    return [200, data];
  });

  mockApi.onGet('/company/resume').reply(() => {
    let data = {};

    data = {
      id: 1,
      name: 'Academia Viva Bem',
      email: 'contacto@vivabem.com',
      packages: 10,
      whatsapp_credits: 6
    };

    return [200, data];
  });

  mockApi.onGet('/customer').reply(200, {
    page: 1,
    total_pages: 10,
    data: [
      {
        id: 1,
        name: 'Sebastião Nacoro',
        phone: '+258 84 999 8888',
        email: 'asdasdaa@asd',
        status: 'em dia',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 2,
        name: 'Ussene Indobe',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inativo',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 3,
        name: 'Luane Alaga',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inadimplente',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 4,
        name: 'Sebastião Nacoro',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'em dia',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 5,
        name: 'Ussene Indobe',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inativo',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 6,
        name: 'Luane Alaga',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inadimplente',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 7,
        name: 'Sebastião Nacoro',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'em dia',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 8,
        name: 'Ussene Indobe',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inativo',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      },
      {
        id: 9,
        name: 'Luane Alaga',
        phone: '+258 84 999 8888',
        email: 'test@gmail.com',
        status: 'inadimplente',
        created_at: '2023-02-03',
        last_contact: '2023-02-03'
      }
    ]
  });

  mockApi.onGet(/^\/billing\/(\d+)(\/notifications)?$/).reply((config) => {
    const id = config.url.match(/^\/billing\/(\d+)/)[1];
    const hasNotificationsPath = config.url.includes('/notifications');

    let data = {};
    if (hasNotificationsPath) {
      data = {
        page: 1,
        total_pages: 10,
        data: [
          {
            date: '09-09-2023 09:12:00',
            description: 'Enviado SMS de vencimento no número +258 94 999 999'
          },
          {
            date: '09-09-2023 09:12:00',
            description: 'Enviado E-mail de vencimento para ussene@gmail.com'
          }
        ]
      };
    } else {
      data = {
        id: parseInt(id),
        invoiceId: 1,
        status: 'to_confirm',
        value: 4000,
        link: 'https://piminder-frontend.vercel.app/cobrancas/link/123',
        created_at: '2023-02-03',
        due_date: '2023-02-03',
        customer_name: 'Sebastião Nacoro',
        customer_id: 1,
        customer_status: 'em dia',
        payment_type: 'numerary',
        type: 'installments',
        description: 'compra simples.',
        paid_at: 'MPESA',
        payment_date: '2023-02-03'
      };
    }

    return [200, data];
  });

  mockApi.onGet(/^\/invoice\/(\d+)(\/installments)?$/).reply((config) => {
    const id = config.url.match(/^\/invoice\/(\d+)/)[1];
    const hasInstallmentsPath = config.url.includes('/installments');

    let data = {};

    if (hasInstallmentsPath) {
      data = {
        page: 1,
        total_pages: 10,
        data: [
          {
            value: 1000,
            description: 'Parcela 1 de 12. Plano anual. ',
            due_date: '2023-09-09',
            status: 'paid'
          },
          {
            value: 1000,
            description: 'Parcela 2 de 12. Plano anual. ',
            due_date: '2023-10-09',
            status: 'to_be_paid'
          }
        ]
      };
    } else {
      data = {
        id: parseInt(id),
        status: 'to_confirm',
        installment_value: 1000,
        customer_id: 1,
        total_value: 12000,
        link: 'https://piminder-frontend.vercel.app/cobrancas/link/123',
        due_day: '8',
        number_installments: '12',
        customer_name: 'Sebastião Nacoro',
        customer_status: 'em dia',
        product: 'Plano Gold',
        description: 'compra simples.'
      };
    }
    return [200, data];
  });

  mockApi.onDelete(/^\/billing\/(\d+)$/).reply(() => {
    return [200];
  });

  mockApi.onPut(/^\/billing\/(\d+)\/status$/).reply(() => {
    return [200];
  });

  mockApi.onGet('/billing').reply(200, {
    page: 1,
    total_pages: 10,
    data: [
      {
        id: 1,
        customer_name: 'Sebastião Nacoro',
        customer_status: 'em dia',
        link: 'https://piminder-frontend.vercel.app/cobrancas/link/123',
        value: 4860,
        total_value: null,
        type: 'in_cash',
        current_installment: null,
        total_installments: null,
        status: 'paid',
        due_date: '2023-02-03',
        payment_type: 'Numerário',
        paid_at: 'MPESA',
        payment_date: '2023-02-03'
      },
      {
        id: 2,
        customer_name: 'Ussene Indobe',
        customer_status: 'inadimplente',
        link: 'https://piminder-frontend.vercel.app/cobrancas/link/123',
        value: 4860,
        total_value: 5000,
        type: 'installments',
        current_installment: 3,
        total_installments: 4,
        status: 'to_confirm',
        due_date: '2023-02-03',
        payment_type: 'MPESA',
        paid_at: 'MPESA',
        payment_date: '2023-02-03'
      },
      {
        id: 3,
        customer_name: 'Luane Alaga',
        customer_status: 'em dia',
        link: 'https://piminder-frontend.vercel.app/cobrancas/link/123',
        value: 4860,
        total_value: null,
        current_installment: null,
        total_installments: null,
        type: 'monthly_payment',
        status: 'pending',
        due_date: '2023-02-03',
        payment_type: 'Transferência bancária',
        paid_at: 'MPESA',
        payment_date: '2023-02-03'
      }
    ]
  });

  mockApi.onGet('/credit').reply(200, {
    before_credits: {
      whatsapp: 100,
      packages: 100
    },
    after_credits: {
      whatsapp: 99,
      packages: 99
    },
    data: [
      {
        date: '2023-02-03',
        customer_name: 'Sebastião Nacoro',
        customer_status: 'em dia',
        billing_id: '111111',
        deduction: 'whatsapp'
      },
      {
        date: '2023-02-03',
        customer_name: 'Ussene Indobe',
        customer_status: 'inadimplente',
        billing_id: '22222',
        deduction: 'package'
      }
    ]
  });

  mockApi.onGet('/product/options').reply(200, [
    { value: { id: 'item_1', value: 20 }, label: 'Item 1' },
    { value: { id: 'item_2', value: 20 }, label: 'Item 2' },
    { value: { id: 'item_3', value: 20 }, label: 'Item 3' }
  ]);

  mockApi.onGet('/customer/options').reply(200, [
    {
      value: {
        id: 'customer_1',
        name: 'Customer 1',
        email: 'customer_1@customer.com',
        phone: '+258 84 999 9999'
      },
      label: 'Customer 1'
    },
    {
      value: {
        id: 'customer_2',
        name: 'Customer 2',
        email: 'customer_2@customer.com',
        phone: '+258 84 999 9999'
      },
      label: 'Customer 2'
    },
    {
      value: {
        id: 'customer_3',
        name: 'Customer 3',
        email: 'customer_3@customer.com',
        phone: '+258 84 999 9999'
      },
      label: 'Customer 3'
    }
  ]);

  mockApi.onGet('/dashboard').reply(200, {
    customers: {
      in_day: 120,
      overdue: 10
    },
    charges: {
      foreseen: 120,
      due: 10,
      received: 126
    },
    billing: {
      to_confirm: 30000,
      foreseen: 13980,
      received: 22100
    },
    graph: [
      {
        date: '2023-04-07',
        to_confirm: 3000,
        foreseen: 4400,
        received: 2400
      },
      {
        date: '2023-04-08',
        to_confirm: 3000,
        foreseen: 4398,
        received: 2210
      },
      {
        date: '2023-04-09',
        to_confirm: 3000,
        foreseen: 4000,
        received: 2090
      },
      {
        date: '2023-04-10',
        to_confirm: 3780,
        foreseen: 4080,
        received: 2000
      },
      {
        date: '2023-04-11',
        to_confirm: 3890,
        foreseen: 4800,
        received: 2181
      },
      {
        date: '2023-04-12',
        to_confirm: 3390,
        foreseen: 4800,
        received: 2500
      },
      {
        date: '2023-04-13',
        to_confirm: 3490,
        foreseen: 4300,
        received: 2100
      }
    ]
  });
}
