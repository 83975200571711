import React from 'react';
import Select from 'react-select';
import { Colors } from '../../../utils/colors';

export default function BorderlessMultiSelect(props) {
  const { options, field, placeholder, form, onChange } = props;

  let colorAttrs = {
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: Colors.Gray4
    })
  };
  if (props.color) {
    colorAttrs = {
      dropdownIndicator: () => ({
        color: props.color
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        color: props.color,
        fontWeight: 500
      }),
      singleValue: (provided) => ({
        ...provided,
        color: props.color,
        fontWeight: 500
      })
    };
  }

  return (
    <div>
      <Select
        className="p-4"
        isMulti
        styles={{
          menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%'
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            border: 'none',
            borderRadius: 'none',
            outline: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0
          }),
          ...colorAttrs,
          input: (baseStyles) => ({
            ...baseStyles,
            margin: 0
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: '#fff',
            color: '#333',
            '&:hover': {
              backgroundColor: '#f8f9fa'
            }
          }),
          indicatorSeparator: () => {}
        }}
        options={options}
        placeholder={placeholder}
        name={field?.name}
        onChange={onChange ? onChange : (option) => form.setFieldValue(field.name, option.value)}
        onBlur={field?.onBlur}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
      />
    </div>
  );
}
