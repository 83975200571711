import { api, apiPublic, authenticationPublic } from '../api/api';
import { formatCompanySignup } from './serviceFormatters';

const authService = {
  recoveryAccount: async (email) => {
    try {
      await apiPublic.post('/me/recovery-code', { email });
    } catch (error) {
      throw Error(error);
    }
  },
  confirmRecoveryCode: async (data) => {
    try {
      await apiPublic.post('/me/recovery-code/confirm', data);
    } catch (error) {
      throw Error(error);
    }
  },
  confirmOtp: async (confirmationData, isEmailVerified) => {
    try {
      const response = await apiPublic.post(
        `${isEmailVerified ? '/phone/confirm' : '/email/confirm-code'}`,
        confirmationData
      );
      if (response.status !== 200) throw new Error('Erro ao confirmar OTP');
      const data = response.data;
      return data.message;
    } catch (error) {
      if (!isEmailVerified) throw new Error('Erro ao confirmar OTP');
    }
  },
  getOtp: async (confirmationData, isEmailVerified) => {
    try {
      const response = await apiPublic.post(
        `${isEmailVerified ? '/phone/otp' : '/email/verification-code'}`,
        confirmationData
      );
      const data = response.data;
      return data;
    } catch (error) {
      throw Error(error);
    }
  },
  signIn: async (signInData) => {
    try {
      const response = await authenticationPublic.post('/login', signInData);
      const data = response.data;
      return data?.message?.token;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },

  signUp: async (signUpData) => {
    try {
      const response = await authenticationPublic.post('/signup', formatCompanySignup(signUpData));
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  },

  forgotPassword: async (email) => {
    try {
      await apiPublic.post('/forgot-password', { company_email: email });
    } catch (error) {
      throw Error(error);
    }
  },

  resetPassword: async ({ email, resetToken, newPassword }) => {
    try {
      await apiPublic.post('/reset-password', {
        email,
        token: resetToken,
        new_password: newPassword
      });
    } catch (error) {
      throw Error(error);
    }
  },

  deleteAccount: async (reasons) => {
    try {
      const response = await api.put('/company/disable', reasons);
      return response.data;
    } catch (error) {
      throw Error(error);
    }
  }
};

export default authService;
