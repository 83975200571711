import React from 'react';

export default function StatusFilterIcon({ width = 16, height = 16, color = 'white' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41971 14.6753C8.96156 14.7725 8.48663 14.8239 8 14.8239C4.23063 14.8239 1.17073 11.75 1.17073 7.96333C1.17073 7.63873 0.908488 7.37529 0.585366 7.37529C0.262244 7.37529 0 7.63873 0 7.96333C0 12.3988 3.58478 16 8 16C8.56976 16 9.12585 15.94 9.66205 15.8259C9.97815 15.7585 10.1807 15.4465 10.1136 15.1289C10.0468 14.8114 9.7358 14.6079 9.41971 14.6753V14.6753ZM8.96195 1.1702C12.2763 1.64024 14.8293 4.50482 14.8293 7.96333C14.8293 10.4096 13.5516 12.5587 11.6312 13.7737C11.3577 13.9465 11.2757 14.31 11.4478 14.5844C11.6199 14.8592 11.9817 14.9415 12.2548 14.7686C14.5046 13.3459 16 10.8287 16 7.96333C16 3.91168 13.0084 0.556272 9.12546 0.00585864C8.80546 -0.0396171 8.50927 0.184623 8.464 0.505698C8.41873 0.827164 8.64195 1.12511 8.96195 1.1702V1.1702ZM1.66244 5.40414C2.39414 3.57923 3.88449 2.14204 5.7401 1.48774C6.04527 1.38032 6.20566 1.04436 6.09873 0.73818C5.99181 0.432002 5.65737 0.270483 5.35259 0.377899C3.17893 1.14432 1.43337 2.82771 0.576392 4.96468C0.455807 5.26576 0.600975 5.60879 0.900682 5.72993C1.20039 5.85146 1.54146 5.70522 1.66244 5.40414Z"
        fill={color}
      />
    </svg>
  );
}
