import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useStore } from '../../../store/useStore';
import DetailSection from '../../../components/DetailSection/DetailSection';
import OutlinedButton from '../../../components/FormComponents/Buttons/OutlinedButton';
import { formatDate } from '../../../utils/dateHelpers';
import { formatCurrency } from '../../../utils/currencyHelper';
import {
  BILLING_STATUSES_LABELS,
  BILLING_STATUS_COLOR,
  BILLING_TYPES_LABELS,
  PAYMENT_METHODS_LABELS,
  SIGNATURE_INTERVAL_LABELS
} from '../../../utils/constants';
import ShareIcon from '../../../assets/imgs/ShareIcon';
import { StatusIcon } from '../../../assets/imgs';
import InfoLabeled from '../../../components/InfoLabeled/InfoLabeled';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import MoreActionBilling from './MoreActionBilling';
import SimpleLink from '../../../components/SimpleLink/SimpleLink';

export default function BillingDataSection() {
  const params = useParams();
  const getBilling = useStore((state) => state.billings.getBilling);
  const billing = useStore((state) => state.billings.billing);
  const isBillingLoading = useStore((state) => state.billings.isBillingLoading);

  useEffect(() => {
    getBilling(params.id);
  }, []);

  const onClickLink = () => {
    const billingLink = new URL(window.location.origin);
    billingLink.pathname = `cobrancas/link/${billing.id}`;
    navigator.clipboard.writeText(billingLink.href);
    toast.info('Link de cobrança copiado');
  };

  return (
    <DetailSection
      title="Dados da cobrança"
      button={
        <div className="flex flex-col md:flex-row gap-5">
          {isBillingLoading ? (
            <Skeleton width={105} height={46} />
          ) : (
            <OutlinedButton onClick={onClickLink} type="button" className="text-base px-3">
              <div className="flex gap-2 items-center">
                <div>Copiar link</div>
                <ShareIcon />
              </div>
            </OutlinedButton>
          )}
          {isBillingLoading ? (
            <Skeleton width={105} height={46} />
          ) : (
            <MoreActionBilling billing={billing} />
          )}
        </div>
      }>
      <div>
        <div className="pt-5 px-5">
          <div className="flex gap-2">
            <InfoLabeled
              loading={isBillingLoading}
              label="Status"
              info={
                <div className="flex gap-2 items-center">
                  <StatusIcon color={BILLING_STATUS_COLOR[billing?.status]} />
                  <div style={{ color: BILLING_STATUS_COLOR[billing?.status] }}>
                    {BILLING_STATUSES_LABELS[billing?.status]}
                  </div>
                </div>
              }
            />
            <InfoLabeled
              loading={isBillingLoading}
              label="Valor"
              info={'MZN ' + formatCurrency(billing?.value)}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isBillingLoading}
              label="Criada em"
              info={formatDate(new Date(billing?.created_at))}
            />
            <InfoLabeled
              loading={isBillingLoading}
              label="Vencimento"
              info={formatDate(new Date(billing?.due_date))}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isBillingLoading}
              label="Cliente"
              info={
                <SimpleLink to={`/clientes/${billing?.customer?.id}`}>
                  {billing?.customer?.first_name} {billing?.customer?.last_name}
                </SimpleLink>
              }
            />
            <InfoLabeled
              loading={isBillingLoading}
              label="Forma de pagamento"
              info={billing?.paidWhere ? PAYMENT_METHODS_LABELS[billing?.paidWhere] : '-'}
              withBackground
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isBillingLoading}
              label="Tipo da cobrança"
              info={
                billing?.type === 'INSTALLMENT' ? (
                  <SimpleLink to={`/parcelamentos/${billing?.invoice?.id}`}>
                    {BILLING_TYPES_LABELS[billing?.type]}
                  </SimpleLink>
                ) : (
                  BILLING_TYPES_LABELS[billing?.type]
                )
              }
            />
            <InfoLabeled
              loading={isBillingLoading}
              label="Descrição"
              info={billing?.description}
              withBackground
              big
            />
          </div>
          <div className="flex gap-2">
            <InfoLabeled
              loading={isBillingLoading}
              label="Que dia foi pago?"
              info={billing?.paidWhen ? formatDate(new Date(billing?.paidWhen)) : '-'}
            />
            <InfoLabeled
              loading={isBillingLoading}
              label="Comprovante de pagamento"
              info={
                billing.payment_voucher_destination ? (
                  <SimpleLink
                    className="cursor-pointer underline text-orange"
                    to={billing.payment_voucher_destination}>
                    Baixar comprovante
                  </SimpleLink>
                ) : (
                  '-'
                )
              }
            />
          </div>
          <div className="flex gap-2">
            {billing?.type === 'SIGNATURE' ? (
              <InfoLabeled
                loading={isBillingLoading}
                label="Intervalo"
                info={SIGNATURE_INTERVAL_LABELS[billing?.product.company_plan?.interval] ?? '-'}
              />
            ) : null}
          </div>
        </div>
      </div>
    </DetailSection>
  );
}
