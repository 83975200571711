import React from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  EmailIcon,
  IllustrationImage,
  LockIcon,
  PiminderLargeLogo,
  PiminderLargeLogoBlack
} from '../../assets/imgs';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import Input from '../../components/FormComponents/Inputs/Input';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useStore } from '../../store/useStore';
import PasswordInput from '../../components/FormComponents/Inputs/PasswordInput';
import { LOGIN_PAGE_FORMS } from '../../utils/constants';
import AccountReactivationModal from './components/AccountReactivationModal';
import StrengthValidator from '../Register/StrengthValidator/StrengthValidator';

export function LoginForm() {
  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const signIn = useStore((state) => state.auth.signIn);
  const isLoading = useStore((state) => state.auth.isLoading);
  const navigate = useNavigate();
  const setCurrentLoginForm = useStore((state) => state.auth.setCurrentLoginForm);
  if (localStorage.getItem('token')) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <div className="w-[100%] md:w-[35%] text-white p-8 md:p-16">
        <p className="text-3xl">
          <span className="align-middle">Bem-vindo à </span>
          <img className="align-middle ml-1 inline" width={120} src={PiminderLargeLogo} />
        </p>
        <p className="font-bold text-[40px] mb-8">Sign in</p>
        <p className="mb-12">
          Não possui uma conta?{' '}
          <Link className="text-orange" to="/cadastro">
            Registre-se aqui
          </Link>
        </p>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string().email('Endereço de email inválido').required('Campo obrigatório'),
            password: Yup.string().required('Campo obrigatório')
          })}
          onSubmit={(values) => {
            signIn(values, navigate);
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-10">
              <Input
                name="email"
                placeholder="Digite seu Email"
                type="email"
                label="Email"
                preIcon={EmailIcon}
                blackTheme
                error={errorMessage(formik, 'email')}
                {...formik.getFieldProps('email')}
              />
              <div>
                <PasswordInput
                  name="password"
                  placeholder="Digite sua Senha"
                  type="password"
                  label="Senha"
                  preIcon={LockIcon}
                  blackTheme
                  error={errorMessage(formik, 'password')}
                  {...formik.getFieldProps('password')}
                />
                <div className="text-right mt-3">
                  <span
                    className="font-thin text-base cursor-pointer"
                    onClick={() => setCurrentLoginForm(LOGIN_PAGE_FORMS.FORGOT_PASSWORD)}>
                    Esqueceu a senha?
                  </span>
                </div>
              </div>
              <FilledButton roundedClass={'rounded-[10px]'} type="submit" loading={isLoading}>
                Entrar
              </FilledButton>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export function ForgotPasswordForm() {
  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const forgotPassword = useStore((state) => state.auth.forgotPassword);
  const isLoading = useStore((state) => state.auth.isLoading);
  const setCurrentLoginForm = useStore((state) => state.auth.setCurrentLoginForm);

  return (
    <>
      <div className="w-[100%] md:w-[35%] text-white p-8 md:p-16">
        <p className="text-3xl">
          <span className="align-middle">Bem-vindo à </span>
          <img className="align-middle ml-1 inline" width={120} src={PiminderLargeLogo} />
        </p>
        <p className="font-bold text-[40px] mb-8">Esqueceu a senha</p>
        <p className="mb-12 text-gray-3">
          Digite o email cadastrado abaixo para receber as instruções de redefinição de senha
        </p>
        <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={Yup.object({
            email: Yup.string().email('Endereço de email inválido').required('Campo obrigatório')
          })}
          onSubmit={({ email }) => {
            forgotPassword(email);
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-10">
              <Input
                name="email"
                placeholder="Digite o email cadastrado"
                type="email"
                label="Email Cadastrado"
                preIcon={EmailIcon}
                blackTheme
                error={errorMessage(formik, 'email')}
                {...formik.getFieldProps('email')}
              />

              <FilledButton roundedClass={'rounded-[10px]'} type="submit" loading={isLoading}>
                Enviar
              </FilledButton>
            </form>
          )}
        </Formik>
        <p className="mt-8 text-right">
          Lembrou a senha?{' '}
          <span
            className="text-orange cursor-pointer"
            onClick={() => setCurrentLoginForm(LOGIN_PAGE_FORMS.LOGIN)}>
            Voltar ao login
          </span>
        </p>
      </div>
    </>
  );
}

export function ResetPasswordForm({ resetToken, email }) {
  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  const resetPassword = useStore((state) => state.auth.resetPassword);
  const isLoading = useStore((state) => state.auth.isLoading);
  const navigate = useNavigate();

  return (
    <>
      <div className="w-[100%] md:w-[35%] text-white p-8 md:p-16">
        <p className="text-3xl">
          <span className="align-middle">Bem-vindo à </span>
          <img className="align-middle ml-1 inline" width={120} src={PiminderLargeLogo} />
        </p>
        <p className="font-bold text-[40px] mb-8">Gerar nova senha</p>
        <Formik
          initialValues={{
            newPassword: '',
            confirmNewPassword: ''
          }}
          validationSchema={Yup.object({
            newPassword: Yup.string().required('Campo obrigatório'),
            confirmNewPassword: Yup.string()
              .oneOf([Yup.ref('newPassword')], 'Senha incorreta')
              .required('Campo obrigatório')
          })}
          onSubmit={({ newPassword }) => {
            resetPassword({ newPassword, resetToken, email }, navigate);
          }}>
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-10">
              <Input
                name="newPassword"
                placeholder="Digite a nova senha"
                type="password"
                label="Nova senha"
                preIcon={LockIcon}
                blackTheme
                error={errorMessage(formik, 'newPassword')}
                {...formik.getFieldProps('newPassword')}
              />
              <Input
                name="confirmNewPassword"
                placeholder="Digite a nova senha novamente"
                type="password"
                label="Confirme a nova senha"
                preIcon={LockIcon}
                blackTheme
                error={errorMessage(formik, 'confirmNewPassword')}
                {...formik.getFieldProps('confirmNewPassword')}
              />
              <StrengthValidator mode="dark" password={formik.values.newPassword} />
              <FilledButton roundedClass={'rounded-[10px]'} type="submit" loading={isLoading}>
                Refedinir senha
              </FilledButton>
            </form>
          )}
        </Formik>
        <p className="mt-8">
          Lembrou a senha?{' '}
          <span className="text-orange cursor-pointer" onClick={() => navigate('/')}>
            Voltar ao login
          </span>
        </p>
      </div>
    </>
  );
}

function FormHandler() {
  const currentLoginForm = useStore((state) => state.auth.currentLoginForm);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const resetToken = queryParams.get('resetToken');
  const email = queryParams.get('email');

  if (resetToken && email) {
    return <ResetPasswordForm resetToken={resetToken} email={email} />;
  } else if (currentLoginForm === LOGIN_PAGE_FORMS.FORGOT_PASSWORD) {
    return <ForgotPasswordForm />;
  } else {
    return <LoginForm />;
  }
}

export default function Login() {
  const showAccountReactivationModal = useStore((state) => state.auth.showAccountReactivationModal);
  const toggleshowAccountReactivationModal = useStore(
    (state) => state.auth.toggleShowAccountReactivationModal
  );
  return (
    <div className="bg-black flex min-h-screen">
      <div className="md:flex flex-col bg-white w-[100%] md:w-[65%] rounded-r-3xl p-10 hidden ">
        <div className="flex gap-5 ml-16 mt-5 mb-5">
          <h1 className="font-semibold">
            <img width={200} src={PiminderLargeLogoBlack} />
          </h1>
        </div>
        <div className="ml-16 text-2xl">
          <p>
            Realize suas <span className="text-orange">cobranças</span> de uma forma inteligente.
          </p>
          <p>
            Gerencie todos os seus os <span className="text-orange">pagamentos</span> em um único
            lugar.
          </p>
        </div>
        <div className="flex justify-center items-center h-[100%]">
          <img className="max-w-[500px]" src={IllustrationImage} />
        </div>
      </div>
      <FormHandler />
      <AccountReactivationModal
        isModalOpen={showAccountReactivationModal}
        handleModalOpen={toggleshowAccountReactivationModal}
      />
    </div>
  );
}
