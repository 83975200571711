import { Field, Formik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { useStore } from '../../../store/useStore';
import RadioButton from '../../FormComponents/RadioButton/RadioButton';
import BorderedSelect from '../../FormComponents/SelectFields/BorderedSelect';
import RadioCardOption from '../../FormComponents/RadioCardOption/RadioCardOption';
import BorderedDatePicker from '../../FormComponents/BorderedDatePicker/BorderedDatePicker';
import BorderedInput from '../../FormComponents/Inputs/BorderedInput';
import BorderedAsyncSelect from '../../FormComponents/SelectFields/BorderedAsyncSelect';
import BorderedMaskedInput from '../../FormComponents/Inputs/BorderedMaskedInput';
import {
  maskToCurrency,
  numberToStringMasked,
  onlyPositiveNumbers,
  stringMaskedToNumber
} from '../../../utils/currencyHelper';
import { Colors } from '../../../utils/colors';
import { BILLING_INTERVALS } from '../../../utils/constants';

export default function Step1({ show, onNext }) {
  const updateCreateBillingData = useStore((state) => state.billings.updateCreateBillingData);
  const getProductOptions = useStore((state) => state.products.getProductOptions);
  const createBillingModalPaymentType = useStore(
    (state) => state.billings.createBillingModalPaymentType
  );
  const setShowCancelCreationConfirmationModal = useStore(
    (state) => state.billings.setShowCancelCreationConfirmationModal
  );
  const getProductOptionsCallback = async (inputValue) => {
    const options = await getProductOptions({ search: inputValue });
    return options;
  };

  const calculateInstallmentValue = (value, numberOfInstallments) => {
    let installmentValue = value / numberOfInstallments;
    installmentValue = numberToStringMasked(installmentValue);
    return installmentValue;
  };

  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <Formik
      initialValues={{
        isItemRegistered: 'no',
        productId: null,
        item: '',
        billingValue: '',
        description: '',
        paymentType: 'signature',
        billingInterval: '1',
        dueDate: '',
        finishSignatureDate: '',
        saveItem: 'no',
        installmentValue: '',
        numberOfInstallments: '1'
      }}
      validationSchema={Yup.object({
        isItemRegistered: Yup.string().required('Campo obrigatório'),
        item: Yup.string().required('Campo obrigatório').min(2, 'Mínimo 2 caracteres'),
        billingValue: Yup.string().required('Campo obrigatório'),
        description: Yup.string(),
        paymentType: Yup.string().required('Campo obrigatório'),
        billingInterval: Yup.string().when('paymentType', {
          is: (val) => val == 'signature',
          then: (schema) => schema.required('Campo obrigatório'),
          otherwise: (schema) => schema
        }),
        dueDate: Yup.date().required('Campo obrigatório'),
        finishSignatureDate: Yup.string().nullable(true),
        saveItem: Yup.string().required('Campo obrigatório'),
        installmentValue: Yup.string(),
        numberOfInstallments: Yup.string().when('paymentType', {
          is: (val) => val == 'in_cash_or_installments',
          then: (schema) => schema.required('Campo obrigatório'),
          otherwise: (schema) => schema
        })
      })}
      onSubmit={(values) => {
        updateCreateBillingData(values);
        onNext();
      }}>
      {(formik) => {
        useEffect(() => {
          formik.setValues({ ...formik.values, paymentType: createBillingModalPaymentType });
        }, [createBillingModalPaymentType]);

        return (
          <form
            onSubmit={formik.handleSubmit}
            className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
            <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5 text-sm">
              <div className="font-medium text-lg">O que você está vendendo?</div>
              <div className="flex gap-3">
                <div className="font-medium">É um item ou plano já cadastrado?</div>
                <RadioButton
                  label="Sim"
                  checked={formik.values.isItemRegistered === 'yes'}
                  value="yes"
                  onClick={() => {
                    formik.setFieldValue('isItemRegistered', 'yes');
                    formik.setFieldValue('item', '');
                    formik.setFieldValue('billingValue', '');
                    formik.setFieldValue('description', '');
                    formik.setFieldValue('billingInterval', '1');
                    formik.setFieldValue('saveItem', 'no');
                  }}
                />
                <RadioButton
                  label="Não"
                  checked={formik.values.isItemRegistered === 'no'}
                  value="no"
                  onClick={() => {
                    formik.setFieldValue('isItemRegistered', 'no');
                    formik.setFieldValue('item', '');
                    formik.setFieldValue('billingValue', '');
                    formik.setFieldValue('description', '');
                    formik.setFieldValue('billingInterval', '1');
                  }}
                />
              </div>
              <div className="flex gap-3">
                <div className="flex-1">
                  {formik.values.isItemRegistered === 'no' ? (
                    <BorderedInput
                      placeholder="Digite um item"
                      type="text"
                      label="Item ou Plano"
                      error={errorMessage(formik, 'item')}
                      {...formik.getFieldProps('item')}
                    />
                  ) : (
                    <Field
                      name={`item`}
                      component={BorderedAsyncSelect}
                      menuPosition="fixed"
                      callback={getProductOptionsCallback}
                      label="Item ou Plano"
                      placeholder="Digite um item"
                      onChange={(option) => {
                        formik.setFieldValue('item', option.label);
                        formik.setFieldError('item', null, false);
                        formik.setFieldTouched('item', null, false);
                        formik.setFieldError('billingValue', null, false);
                        formik.setFieldTouched('billingValue', null, false);
                        formik.setFieldValue(
                          'billingValue',
                          numberToStringMasked(option.value?.price)
                        );
                        formik.setFieldTouched('description', null, false);
                        formik.setFieldValue('description', option.value?.description);
                        if (option.value?.company_plan) {
                          formik.setFieldValue('productId', option.value?.company_plan?.product_id);
                          formik.setFieldValue('paymentType', 'signature');
                          formik.setFieldValue(
                            'billingInterval',
                            option.value?.company_plan.interval
                          );
                        } else if (option.value?.item) {
                          formik.setFieldValue('productId', option.value?.item?.product_id);
                          formik.setFieldValue('paymentType', 'in_cash_or_installments');
                          const installmentValue = calculateInstallmentValue(
                            option.value?.price,
                            Number(formik.values.numberOfInstallments)
                          );
                          formik.setFieldValue('installmentValue', installmentValue);
                        }
                      }}
                      error={errorMessage(formik, 'item')}
                    />
                  )}
                </div>
                <div className="flex-1">
                  <BorderedMaskedInput
                    placeholder="0,00"
                    disabled={formik.values.isItemRegistered === 'yes'}
                    prefix="MZN"
                    type="text"
                    label="Valor"
                    mask="99999999999999"
                    beforeMaskedStateChange={maskToCurrency}
                    alwaysShowMask={false}
                    maskPlaceholder={null}
                    error={errorMessage(formik, 'billingValue')}
                    {...formik.getFieldProps('billingValue')}
                    onChange={(e) => {
                      let billingValue = e.target.value;
                      formik.setFieldValue('billingValue', billingValue);
                      formik.setFieldError('billingValue', null, false);
                      formik.setFieldTouched('billingValue', null, false);

                      billingValue = stringMaskedToNumber(billingValue);
                      let installmentValue = calculateInstallmentValue(
                        billingValue,
                        stringMaskedToNumber(formik.values.numberOfInstallments)
                      );

                      formik.setFieldValue('installmentValue', installmentValue);
                    }}
                  />
                </div>
              </div>
              <BorderedInput
                placeholder="O que você vai cobrar?"
                disabled={formik.values.isItemRegistered === 'yes'}
                textarea
                labelInfo=" (opcional)"
                type="text"
                label="Descrição"
                error={errorMessage(formik, 'description')}
                {...formik.getFieldProps('description')}
              />
              <div className="font-medium text-lg">Qual será a forma de pagamento?</div>
              <div className="flex gap-3">
                <RadioCardOption
                  label="Assinatura"
                  disabled={formik.values.isItemRegistered === 'yes'}
                  checked={formik.values.paymentType === 'signature'}
                  info="Cobranças recorrentes em um intervalo de tempo definido por você."
                  value="signature"
                  onClick={() => {
                    if (formik.values.isItemRegistered === 'no') {
                      formik.setFieldValue('paymentType', 'signature');
                    }
                  }}
                />
                <RadioCardOption
                  label="À vista ou parcelado"
                  disabled={formik.values.isItemRegistered === 'yes'}
                  checked={formik.values.paymentType === 'in_cash_or_installments'}
                  info="Cobranças únicas podem ser pagas à vista ou de forma parcelada."
                  value="in_cash_or_installments"
                  onClick={() => {
                    if (formik.values.isItemRegistered === 'no') {
                      formik.setFieldValue('paymentType', 'in_cash_or_installments');
                    }
                  }}
                />
              </div>
              <div className="flex gap-3">
                <div className="flex-1">
                  {formik.values.paymentType === 'signature' ? (
                    <Field
                      component={BorderedSelect}
                      menuPosition="fixed"
                      isSearchable={false}
                      menuPlacement="top"
                      name="billingInterval"
                      label="Intervalo entre as cobranças"
                      options={BILLING_INTERVALS}
                      error={errorMessage(formik, 'billingInterval')}
                    />
                  ) : (
                    <BorderedMaskedInput
                      placeholder="0"
                      type="text"
                      mask="99999999999999"
                      alwaysShowMask={false}
                      maskPlaceholder={null}
                      label="Número de parcelas"
                      beforeMaskedStateChange={onlyPositiveNumbers}
                      error={errorMessage(formik, 'numberOfInstallments')}
                      {...formik.getFieldProps('numberOfInstallments')}
                      onChange={(e) => {
                        let numberOfInstallments = e.target.value;
                        formik.setFieldValue('numberOfInstallments', numberOfInstallments);
                        formik.setFieldError('numberOfInstallments', null, false);
                        formik.setFieldTouched('numberOfInstallments', null, false);

                        let installmentValue = calculateInstallmentValue(
                          stringMaskedToNumber(formik.values.billingValue),
                          Number(numberOfInstallments)
                        );
                        formik.setFieldValue('installmentValue', installmentValue);
                      }}
                    />
                  )}
                </div>
                <div className="flex-1 flex space-x-1 group">
                  <BorderedDatePicker
                    selected={formik.values.dueDate}
                    customPlaceholder="__/__/____"
                    label="Vencimento da 1ª cobrança"
                    error={errorMessage(formik, 'dueDate')}
                    onChange={(date) => formik.setFieldValue('dueDate', date)}
                  />
                  {formik.values.paymentType === 'signature' && (
                    <div className="relative">
                      <div className="absolute bottom-full right-0 p-3 invisible group-hover:visible pointer-events-none group-hover:pointer-events-auto opacity-0 transition-all duration-300 group-hover:opacity-100 bg-gray-3 rounded w-52">
                        <p>
                          Em assinatura a primeira cobrança é criada como paga, cobraremos seu
                          cliente no próximo vencimento.
                        </p>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        strokeWidth="2"
                        className="relative top-[2px] stroke-red"
                        strokeLinecap="round"
                        strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" x2="12" y1="8" y2="12" />
                        <line x1="12" x2="12.01" y1="16" y2="16" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <div className="flex-1">
                  {formik.values.paymentType !== 'signature' && (
                    <BorderedInput
                      placeholder="0,00"
                      prefix="MZN"
                      type="text"
                      label="Valor da parcela"
                      disabled
                      {...formik.getFieldProps('installmentValue')}
                      error={errorMessage(formik, 'installmentValue')}
                    />
                  )}
                </div>

                <div className="flex-1">
                  {formik.values.isItemRegistered === 'no' ? (
                    <>
                      <div className="font-medium text-right">
                        Deseja salvar esse Item ou Plano para futuras cobranças?
                      </div>
                      <div className="flex justify-end gap-4">
                        <RadioButton
                          label="Sim"
                          checked={formik.values.saveItem === 'yes'}
                          value="yes"
                          onClick={() => formik.setFieldValue('saveItem', 'yes')}
                        />
                        <RadioButton
                          label="Não"
                          checked={formik.values.saveItem === 'no'}
                          value="no"
                          onClick={() => formik.setFieldValue('saveItem', 'no')}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
              <FilledButton
                onClick={() => setShowCancelCreationConfirmationModal(true)}
                bg={Colors.Gray1}
                color={Colors.Orange}>
                Cancelar
              </FilledButton>
              <FilledButton type="submit">Avançar</FilledButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
