import { Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import Input from '../../../components/FormComponents/Inputs/Input';
import MaskedInput from '../../../components/FormComponents/Inputs/MaskedInput';
import PasswordInput from '../../../components/FormComponents/Inputs/PasswordInput';
import { useStore } from '../../../store/useStore';
import { Colors } from '../../../utils/colors';
import StrengthValidator from '../StrengthValidator/StrengthValidator';

export default function Step1({ show, onNext }) {
  const navigate = useNavigate();
  const updateSignUpData = useStore((state) => state.auth.updateSignUpData);

  const cancel = () => navigate('/');
  const errorMessage = (formik, field) =>
    formik.touched[field] && formik.errors[field] ? <div>{formik.errors[field]}</div> : '';

  return (
    <Formik
      initialValues={{
        email: '',
        phone: '+258',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Endereço de email inválido').required('Campo obrigatório'),
        phone: Yup.string()
          .matches(
            /^\+258 (86|87|84|82|83|85) \d\d\d \d\d\d\d$/,
            'O telefone deve ter o formato +258 XX XXX XXXX'
          )
          .required('Campo obrigatório'),
        password: Yup.string()
          .required(' ')
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/, ' '),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password')], 'Senha incorreta')
          .required('Campo obrigatório')
      })}
      onSubmit={(values) => {
        updateSignUpData(values);
        onNext();
      }}>
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          className={`grid h-full overflow-hidden ${show ? 'block' : 'hidden'}`}>
          <div className="px-6 md:px-12 h-full py-5 overflow-y-auto flex flex-col gap-5">
            <div>
              <Input
                placeholder="Digite seu Email"
                type="email"
                label="Email"
                error={errorMessage(formik, 'email')}
                {...formik.getFieldProps('email')}
              />
            </div>
            <MaskedInput
              placeholder="Digite seu Telefone"
              label="Telefone"
              mask="+258 99 999 9999"
              alwaysShowMask={true}
              maskPlaceholder={null}
              error={errorMessage(formik, 'phone')}
              {...formik.getFieldProps('phone')}
            />
            <PasswordInput
              placeholder="Digite sua Senha"
              type="password"
              label="Senha"
              error={errorMessage(formik, 'password')}
              {...formik.getFieldProps('password')}
            />
            <StrengthValidator password={formik.values.password} />
            <PasswordInput
              placeholder="Confirme sua Senha"
              type="password"
              label="Confirmar Senha"
              disabled={!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(formik.values.password)}
              error={errorMessage(formik, 'confirmPassword')}
              {...formik.getFieldProps('confirmPassword')}
            />
          </div>
          <div className="flex sticky bottom-0 p-5 bg-white justify-between self-end">
            <FilledButton onClick={cancel} bg={Colors.Gray1} color={Colors.Orange}>
              Cancelar
            </FilledButton>
            <FilledButton type="submit">Avançar</FilledButton>
          </div>
        </form>
      )}
    </Formik>
  );
}
