import React from 'react';
import Modal from '../../../components/FormComponents/Modal/Modal';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';

export default function BillingLinkConfirmationModal({ title, show, onClose }) {
  return (
    <Modal title={title} show={show} onClose={onClose} noDefaultHeight>
      <div className=" h-full overflow-y-auto py-5 px-10 flex flex-col gap-6">
        A confirmação do seu pagamento foi enviada, assim que a empresa confirmar você receberá um
        email com a confirmação.
      </div>
      <div className="flex justify-center pb-5">
        <FilledButton onClick={onClose}>Entendi</FilledButton>
      </div>
    </Modal>
  );
}
