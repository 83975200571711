import React from 'react';
import Modal from '../../../components/FormComponents/Modal/Modal';
import FilledButton from '../../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../../utils/colors';
import { useStore } from '../../../store/useStore';

const DeleteProductModal = ({ show, onClose }) => {
  const isLoading = useStore((state) => state.products.isLoading);
  const deleteProduct = useStore((state) => state.products.deleteProduct);
  const selectedProduct = useStore((state) => state.products.selectedProduct);
  return (
    <Modal title="Apagar produto" onClose={onClose} show={show} noDefaultHeight>
      <div className="overflow-hidden">
        <div className="overflow-y-auto p-5">
          <p>
            Gostaria de apagar o seguinte item{' '}
            <span className="font-bold">{selectedProduct?.name}</span>?
          </p>
        </div>
      </div>
      <div className="p-5">
        <FilledButton
          className="px-10 w-full"
          onClick={onClose}
          bg={Colors.Gray1}
          color={Colors.black}>
          Cancelar
        </FilledButton>
        <FilledButton
          onClick={deleteProduct}
          loading={isLoading}
          className="px-10 w-full"
          bg={Colors.Red}>
          Apagar
        </FilledButton>
      </div>
    </Modal>
  );
};

export default DeleteProductModal;
