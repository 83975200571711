export const maskToCurrency = ({ nextState }) => {
  const { value } = nextState || {};

  let amountFormatted = value?.replace?.(/\D/g, '');
  amountFormatted = amountFormatted?.replace?.(/^0+/g, '');

  if (amountFormatted?.length === 2) {
    return {
      ...nextState,
      value: `${amountFormatted}`,
      selection: {
        start: amountFormatted.length + 3,
        end: amountFormatted.length + 3
      }
    };
  }

  const amountFormattedWithComma = amountFormatted?.replace?.(/(?=\d{2})(\d{2})$/, ',$1');
  const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
    /(\d)(?=(\d{3})+(?!\d))/g,
    '$1.'
  );

  if (amountFormattedWithDot) {
    return {
      ...nextState,
      value: `${amountFormattedWithDot}`,
      selection: {
        start: amountFormattedWithDot.length + 3,
        end: amountFormattedWithDot.length + 3
      }
    };
  }

  return nextState;
};

export const onlyPositiveNumbers = ({ nextState }) => {
  const { value } = nextState || {};

  let amountFormatted = value?.replace?.(/\D/g, '');

  return {
    ...nextState,
    value: `${amountFormatted}`
  };
};

export const stringMaskedToNumber = (value) =>
  Number(value?.replaceAll('.', '')?.replace(',', '.') || 0);

export const numberToStringMasked = (value) => {
  if (!isFinite(value) || isNaN(value)) return '0,00';

  return value?.toFixed(2)?.replace('.', ',') || '0,00';
};

export const formatCurrency = (number) => {
  if (isNaN(number)) return '-';
  // const formatter = new Intl.NumberFormat('pt-MZ');
  return number.toLocaleString('pt', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const abrevNumber = (num) => {
  num = num.toString().replace(/[^0-9.]/g, '');
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: ' mil' },
    { v: 1e6, s: ' M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
};
