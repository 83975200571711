import React from 'react';
import { DeleteIcon, PencilIcon } from '../../../assets/imgs';
import { Colors } from '../../../utils/colors';
import ButtonSelect from '../../Billing/components/ButtonSelect';
import DotsIcon from '../../../assets/imgs/DotsIcon';
import { useStore } from '../../../store/useStore';

const getOptions = () => {
  let options = [];

  options = [
    {
      value: 'edit-product',
      icon: <PencilIcon color={Colors.black} />,
      label: 'Editar produto'
    },
    {
      value: 'delete-product',
      icon: <DeleteIcon color={Colors.black} />,
      label: 'Apagar produto'
    }
  ];

  return options;
};

export default function MoreActionProduct({ product, asDots }) {
  const toggleUpdateProductModal = useStore((state) => state.products.toggleUpdateProductModal);
  const toggleDeleteProductModal = useStore((state) => state.products.toggleDeleteProductModal);
  const options = getOptions();
  const onChooseOption = (option) => {
    switch (option) {
      case 'edit-product':
        toggleUpdateProductModal(product);
        break;
      case 'delete-product':
        toggleDeleteProductModal(product);
        break;
    }
  };

  return (
    <ButtonSelect
      options={options}
      label="Mais ações"
      onChange={onChooseOption}
      component={asDots ? DotsIcon : null}
      menuClassName={asDots ? 'left-[-200px] top-[-72px]' : 'left-[-68px]'}
    />
  );
}
