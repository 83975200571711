import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function InfoLabeled({ label, info, withBackground = false, big, loading = false }) {
  return (
    <div className="pb-5 flex-1">
      <div className="font-medium pb-2">{label}</div>
      {loading ? (
        <Skeleton height={20} width={120} />
      ) : (
        <div
          className={`${withBackground ? 'bg-gray-1 p-2 min-w-fit rounded ' : ''} ${
            big ? 'min-h-[70px]' : ''
          }`}>
          {info}
        </div>
      )}
    </div>
  );
}
