import React, { useEffect } from 'react';
import { StatusIcon } from '../../../assets/imgs';
import { INVOICE_STATUSES } from '../../../utils/constants';
import { formatCurrency } from '../../../utils/currencyHelper';
import { formatDate } from '../../../utils/dateHelpers';
import SimpleLink from '../../../components/SimpleLink/SimpleLink';
import DataTable from '../../../components/FormComponents/DataTable/DataTable';
import { useStore } from '../../../store/useStore';

export default function InstallmentsTable({ data, loading = false, isInBillinkLink = false }) {
  const selectBillings = useStore((state) => state.billings.selectBillings);
  const selectedBillings = useStore((state) => state.billings.selectedBillings);
  const resetBillingsSelected = useStore((state) => state.billings.resetBillingsSelected);
  const installmentLink = (installment) =>
    isInBillinkLink ? `/cobrancas/link/${installment.id}` : `/cobrancas/${installment.id}`;

  const onSelectRow = (billings, checked) => {
    selectBillings(billings, checked);
  };
  useEffect(() => {
    return () => {
      resetBillingsSelected();
    };
  }, []);
  const columns = React.useMemo(() => [
    {
      Header: 'Valor',
      accessor: 'value',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">MZN {formatCurrency(row.original.value)}</div>
      )
    },
    {
      Header: 'Descrição',
      accessor: 'description',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">
          <SimpleLink to={installmentLink(row.original)}>{row.original.description}</SimpleLink>
        </div>
      )
    },
    {
      Header: 'Vencimento',
      accessor: 'due_date',
      Cell: ({ row }) => (
        <div className="flex gap-3 items-center">{formatDate(new Date(row.original.due_date))}</div>
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => (
        <div className="flex gap-2 items-center min-w-[90px]">
          <StatusIcon color={INVOICE_STATUSES[row.original?.status]?.color} />
          <div style={{ color: INVOICE_STATUSES[row.original?.status]?.color }}>
            {INVOICE_STATUSES[row.original?.status]?.label}
          </div>
        </div>
      )
    }
  ]);

  return (
    <div className="mt-7">
      <DataTable
        data={data}
        columns={columns}
        loading={loading}
        selectedRows={selectedBillings}
        onSelectRow={onSelectRow}
        onClickRow={() => {}}
      />
    </div>
  );
}
