import React from 'react';
import { PiminderLargeLogo } from '../../assets/imgs';

const NotFound = () => {
  return (
    <div className="flex flex-col justify-between w-full min-h-[100svh] pb-10">
      <header className="py-4 px-4 md:px-0 bg-black/10">
        <div className="container mx-auto">
          <img src={PiminderLargeLogo} alt="Piminder" width={120} />
        </div>
      </header>
      <main className="px-4 md:px-0">
        <div className="container mx-auto">
          <h3 className="text-orange hover:text-orange/80 duration-200 transition-all tracking-wide font-semibold">
            ERRO 404
          </h3>
          <h1 className="text-black/95 text-3xl md:text-[45px] md:mt-5 font-bold">
            Oops... Página não encontrada
          </h1>
          <p className="text-black/80 md:max-w-[400px] break-words">
            Pedimos desculpas, parece que a página que procura não pode ser encontrada.
          </p>
          <section className="mt-10">
            <h3 className="font-bold">O que posso fazer?</h3>
            <div className="flex gap-5 mt-7 overflow-x-auto">
              <div className="min-w-[300px] flex-1 bg-black/5 p-4 rounded-md">
                <div className="flex gap-4 mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-orange">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
                    <path d="M2 12h20" />
                  </svg>
                  <h3 className="font-medium">Verifique o link</h3>
                </div>
                <p className="text-black/80">
                  Verifique se o link que tentou acessar parece correto. As vezes erros minúsculos
                  no link podem levar a esta página
                </p>
              </div>

              <div className="min-w-[300px] flex-1 bg-black/5 p-4 rounded-md">
                <div className="flex gap-4 mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-orange">
                    <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
                    <polyline points="9 22 9 12 15 12 15 22" />
                  </svg>
                  <h3 className="font-medium">Retornar para página principal</h3>
                </div>
                <p className="text-black/80">
                  Se você está incerto sobre a localização do recurso desejado, você pode voltar
                  para{' '}
                  <a
                    href="/"
                    className="text-orange hover:text-orange/80 duration-200 transition-all">
                    app.piminder.com
                  </a>{' '}
                  e procurar pela informação desejada.
                </p>
              </div>

              <div className="min-w-[300px] flex-1 bg-black/5 p-4 rounded-md">
                <div className="flex gap-4 mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-orange">
                    <rect width="20" height="16" x="2" y="4" rx="2" />
                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                  </svg>
                  <h3 className="font-medium">Contacte-nos:</h3>
                </div>
                <p className="text-black/80">
                  Se você acredita que esta página deveria existir ou precisa de alguma assistência,
                  por favor contacte-nos atráves de{' '}
                  <a
                    href="mailto:contacto@piminder.com"
                    className="text-orange hover:text-orange/80 duration-200 transition-all">
                    contacto@piminder.com
                  </a>
                </p>
              </div>

              <div className="min-w-[300px] flex-1 bg-black/5 p-4 rounded-md">
                <div className="flex gap-4 mb-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="stroke-orange">
                    <circle cx="11" cy="11" r="8" />
                    <path d="m21 21-4.3-4.3" />
                  </svg>
                  <h3 className="font-medium">Verifique recursos similares</h3>
                </div>
                <p className="text-black/80">
                  Pode existir outra página ou website que contenha a informação que busca. Tente
                  pesquisar por conteúdo relacionado a nossa solução
                </p>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default NotFound;
