import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { createAuthSlice } from './modules/auth';
import { createCustomersSlice } from './modules/customers';
import { createBillingsSlice } from './modules/billings';
import { createProductsSlice } from './modules/products';
import { createStepModalSlice } from './modules/stepModal';
import { createNavbarSlice } from './modules/navbar';
import { createCreditsSlice } from './modules/credits';
import { createDashboardSlice } from './modules/dashboard';
import { createCompanySlice } from './modules/company';
import { createGroupsSlice } from './modules/groups';
import { staticDataSlice } from './modules/staticData';

export const useStore = create(
  devtools((...a) => ({
    ...createAuthSlice(...a),
    ...createCustomersSlice(...a),
    ...createBillingsSlice(...a),
    ...createProductsSlice(...a),
    ...createStepModalSlice(...a),
    ...createNavbarSlice(...a),
    ...createCreditsSlice(...a),
    ...createDashboardSlice(...a),
    ...createCompanySlice(...a),
    ...createGroupsSlice(...a),
    ...staticDataSlice(...a)
  }))
);
