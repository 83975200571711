import React, { useEffect } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import { useStore } from '../../store/useStore';
import Skeleton from 'react-loading-skeleton';
import InvoiceDataSection from './components/InvoiceDataSection';
import InstallmentsSection from './components/InstallmentsSection';
import FilledButton from '../../components/FormComponents/Buttons/FilledButton';
import { Colors } from '../../utils/colors';
import DeleteInvoiceModal from '../Billing/components/DeleteInvoiceModal';
import ConfirmMultipleBillingReceptionModal from '../Billing/components/ConfirmMultipleBillingReceptionModal';
import DeleteBillingsModal from '../Billing/components/DeleteBillingsModal';
// import Pagination from '../../components/Pagination/Pagination';
// import { useParams } from 'react-router-dom';

export default function BillingInstallments() {
  const invoice = useStore((state) => state.billings.invoice);
  const isInvoiceLoading = useStore((state) => state.billings.isInvoiceLoading);
  const openDeleteInvoiceModal = useStore((state) => state.billings.openDeleteInvoiceModal);
  const resetInvoice = useStore((state) => state.billings.resetInvoice);
  // const installmentsCurrentPage = useStore((state) => state.billings.installmentsCurrentPage);
  // const installmentsTotalPages = useStore((state) => state.billings.installmentsTotalPages);
  // const changeInstallmentsPage = useStore((state) => state.billings.changeInstallmentsPage);
  // const params = useParams();

  // const handleChangeInstallmentsPage = (page, makeRequest) => {
  //   changeInstallmentsPage(params.id, page, makeRequest);
  // };
  useEffect(() => {
    return () => {
      resetInvoice();
    };
  }, []);

  return (
    <div>
      <div>
        <PageHeader title="Parcelamentos" />
        <div className="text-xl font-medium pb-5 flex justify-between gap-3">
          <div>
            <span>Cobrança</span>{' '}
            {isInvoiceLoading ? <Skeleton width={50} /> : <span>{invoice?.id}</span>}
          </div>
          {isInvoiceLoading ? (
            <Skeleton width={86} height={42} />
          ) : (
            <FilledButton
              heightClass="min-h-[32px] h-[42px]"
              bg={Colors.Red}
              onClick={() => openDeleteInvoiceModal(invoice)}
              className="px-5 text-base">
              <div className="font-semibold">Apagar Cobrança</div>
            </FilledButton>
          )}
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5">
        <div className="md:flex-1">
          <InvoiceDataSection />
        </div>
        <div className="flex flex-col gap-5 md:flex-1">
          <InstallmentsSection />
          {/* <div className="flex justify-center">
            <Pagination
              currentPage={installmentsCurrentPage}
              totalPages={installmentsTotalPages}
              changePage={handleChangeInstallmentsPage}
            />
          </div> */}
        </div>
      </div>
      <DeleteInvoiceModal />
      <ConfirmMultipleBillingReceptionModal />
      <DeleteBillingsModal />
    </div>
  );
}
