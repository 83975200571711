import React from 'react';

export default function StepProgressBar({ steps, currentStepNumber, stepProgressBarClassName }) {
  const renderStep = () => {
    return steps.map((step, index) => {
      const isPassedStep = currentStepNumber > index + 1;
      const isPassedOrCurrentStep = isPassedStep || currentStepNumber === index + 1;
      const isLastStep = index === steps.length - 1;

      return (
        <>
          <div
            className={`flex text-center font-medium ${
              stepProgressBarClassName ? stepProgressBarClassName : ''
            }`}>
            <div className="w-[80px] md:w-[150px] flex flex-col items-center justify-between">
              <div className={` ${isPassedOrCurrentStep ? 'text-black' : 'text-gray-3'}`}>
                {step}
              </div>
              <div
                className={`${
                  isPassedOrCurrentStep ? 'bg-orange' : 'bg-gray-1'
                } rounded-[100%] w-[28px] h-[28px] flex justify-center items-center ${
                  isPassedOrCurrentStep ? 'text-white' : 'text-gray-3'
                }`}>
                {index + 1}
              </div>
            </div>
          </div>
          {!isLastStep ? (
            <div
              className={`grow-0 relative bottom-2 ml-[-40px] mr-[-40px] mt-auto w-[12%] md:w-[90%] ${
                isPassedStep ? 'bg-orange' : 'bg-gray-1'
              } h-2 rounded-md`}></div>
          ) : null}
        </>
      );
    });
  };

  return (
    <div>
      <div className="flex justify-between px-[20px]">{renderStep()}</div>
    </div>
  );
}
